import { useCallback, useEffect, useState, useContext, React } from "react";
import { getDashCounts, getSettings } from "../hooks/useFirestore";
import { useParams } from "react-router-dom";
import { dashObjectModel } from "./models";
import "bootstrap/dist/css/bootstrap.css";
import "./stylesheets/ewizcomp.css";
import { useFirestore } from "../hooks/useFirestore";
import {
  Calendar3,
  Calendar3Event,
  CalendarPlus,
  GenderFemale,
  GenderMale,
  Icon7SquareFill,
  PeopleFill,
} from "react-bootstrap-icons";
import AppContextProvider, { AppContext } from "../context/AppContext";
import { useAppContext } from "../hooks/useAppContext";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  CCol,
  CRow,
  CWidgetStatsC,
  CWidgetStatsD,
  CWidgetStatsF,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cibFacebook,
  cibTwitter,
  cilChartPie,
  cilPeople,
  cilUser,
  cilUserFemale,
} from "@coreui/icons";
import { Row } from "react-bootstrap";
import { CChart, CChartLine } from "@coreui/react-chartjs";
import "@coreui/coreui/dist/css/coreui.min.css";

export function Dashboard() {
  const { getDashCounts, dashObject } = useFirestore();
  const [dash, setDash] = useState(dashObjectModel);
  //const { show } = useParams();
  const [isActive, setActive] = useState(false);
  const { show, cellNumber, setObject, settings } = useAppContext();
  let dashData = undefined;
  const [logo, setLogo] = useState("");

  const getData = useCallback(
    async (show) => {
      if (dashData == undefined) {
        dashData = await getDashCounts(show);
      } else {
        setDash({ ...dashObject });
        setActive(false);
      }
    },
    [dashData]
  );

  useEffect(() => {
    document.body.style.background = setObject.color;

    getData(show);
  }, [getData]);

  return (
    <div>
      {isActive && (
        <LoadingOverlay active={isActive} spinner text="">
          <div>
            <h1 id={"loading"}>
              <span className="let1">l</span>
              <span className="let2">o</span>
              <span className="let3">a</span>
              <span className="let4">d</span>
              <span className="let5">i</span>
              <span className="let6">n</span>
              <span className="let7">g</span>
            </h1>
          </div>
        </LoadingOverlay>
      )}

      {!isActive && (
        <div className={"container"}>
          <img
            style={{ padding: "20px", width: "800px" }}
            // src={"../images/" + show + ".jpeg"}
            src={setObject.showLogo}
            className="img-fluid rounded mx-auto d-block"
            alt=""
          />
          <br />

          <CRow>
            <CCol xs={4}>
              <CWidgetStatsF
                className="mb-3"
                color="primary"
                icon={<CIcon icon={cilChartPie} height={24} />}
                title="Today"
                value={dash.today}
              />
            </CCol>
            <CCol xs={4}>
              <CWidgetStatsF
                className="mb-3"
                color="warning"
                icon={<CIcon icon={cilChartPie} height={24} />}
                title="Yesterday"
                value={dash.yesterday}
              />
            </CCol>
            <CCol xs={4}>
              <CWidgetStatsF
                className="mb-3"
                color="warning"
                icon={<CIcon icon={cilChartPie} height={24} />}
                title="Seven Days"
                value={dash.sevendays}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol xs={4}>
              <CWidgetStatsF
                className="mb-3"
                color="primary"
                icon={<CIcon icon={cilChartPie} height={24} />}
                title="Singles"
                value={dash.single}
              />
            </CCol>
            <CCol xs={4}>
              <CWidgetStatsF
                className="mb-3"
                color="warning"
                icon={<CIcon icon={cilChartPie} height={24} />}
                title="Couples"
                value={dash.couples}
              />
            </CCol>
            <CCol xs={4}>
              <CWidgetStatsF
                className="mb-3"
                color="warning"
                icon={<CIcon icon={cilChartPie} height={24} />}
                title="Total"
                value={dash.total}
              />
            </CCol>
          </CRow>

          {/*<CRow>*/}
          {/*  <CChart*/}
          {/*    type="bar"*/}
          {/*    data={{*/}
          {/*      // labels: [*/}
          {/*      //   "January",*/}
          {/*      //   "February",*/}
          {/*      //   "March",*/}
          {/*      //   "April",*/}
          {/*      //   "May",*/}
          {/*      //   "June",*/}
          {/*      //   "July",*/}
          {/*      // ],*/}
          {/*      labels: Object.keys(dash.dashGraph),*/}
          {/*      datasets: [*/}
          {/*        {*/}
          {/*          label: "Entries",*/}
          {/*          backgroundColor: "#008651",*/}
          {/*          // data: [40, 20, 12, 39, 10, 40, 39, 80, 40],*/}
          {/*          data: Object.values(dash.dashGraph),*/}
          {/*        },*/}
          {/*      ],*/}
          {/*    }}*/}
          {/*    // labels="months"*/}
          {/*    labels="days"*/}
          {/*  />*/}
          {/*</CRow>*/}
          <br />
          <CRow>
            <CCol xs={4}>
              <CWidgetStatsD
                className="mb-3"
                icon={
                  <CIcon
                    className="my-4 text-white"
                    icon={cilUser}
                    height={52}
                  />
                }
                chart={
                  <CChartLine
                    className="position-absolute w-100 h-100"
                    data={{
                      labels: [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                      ],
                      datasets: [
                        {
                          backgroundColor: "rgba(255,255,255,.1)",
                          borderColor: "rgba(255,255,255,.55)",
                          pointHoverBackgroundColor: "#fff",
                          borderWidth: 2,
                          data: [65, 59, 84, 84, 51, 55, 40],
                          fill: true,
                        },
                      ],
                    }}
                    options={{
                      elements: {
                        line: {
                          tension: 0.4,
                        },
                        point: {
                          radius: 0,
                          hitRadius: 10,
                          hoverRadius: 4,
                          hoverBorderWidth: 3,
                        },
                      },
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          display: false,
                        },
                        y: {
                          display: false,
                        },
                      },
                    }}
                  />
                }
                style={{ "--cui-card-cap-bg": "#00aced" }}
                values={[
                  { title: "This Week", value: "0" },
                  { title: "This Month", value: "0" },
                ]}
              />
            </CCol>
            <CCol xs={4}>
              <CWidgetStatsD
                className="mb-3"
                icon={
                  <CIcon
                    className="my-4 text-white"
                    icon={cilUserFemale}
                    height={52}
                  />
                }
                chart={
                  <CChartLine
                    className="position-absolute w-100 h-100"
                    data={{
                      labels: [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                      ],
                      datasets: [
                        {
                          backgroundColor: "rgba(255,255,255,.1)",
                          borderColor: "rgba(255,255,255,.55)",
                          pointHoverBackgroundColor: "#fff",
                          borderWidth: 2,
                          data: [1, 13, 9, 17, 34, 41, 38],
                          fill: true,
                        },
                      ],
                    }}
                    options={{
                      elements: {
                        line: {
                          tension: 0.4,
                        },
                        point: {
                          radius: 0,
                          hitRadius: 10,
                          hoverRadius: 4,
                          hoverBorderWidth: 3,
                        },
                      },
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          display: false,
                        },
                        y: { display: false },
                      },
                    }}
                  />
                }
                style={{ "--cui-card-cap-bg": "#ed00e1" }}
                values={[
                  { title: "This Week", value: "0" },
                  { title: "This Month", value: "0" },
                ]}
              />
            </CCol>
            <CCol xs={4}>
              <CWidgetStatsD
                className="mb-3"
                icon={
                  <CIcon
                    className="my-4 text-white"
                    icon={cilPeople}
                    height={52}
                  />
                }
                chart={
                  <CChartLine
                    className="position-absolute w-100 h-100"
                    data={{
                      labels: [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                      ],
                      datasets: [
                        {
                          backgroundColor: "rgba(255,255,255,.1)",
                          borderColor: "rgba(255,255,255,.55)",
                          pointHoverBackgroundColor: "#fff",
                          borderWidth: 2,
                          data: [1, 13, 9, 17, 34, 41, 38],
                          fill: true,
                        },
                      ],
                    }}
                    options={{
                      elements: {
                        line: {
                          tension: 0.4,
                        },
                        point: {
                          radius: 0,
                          hitRadius: 10,
                          hoverRadius: 4,
                          hoverBorderWidth: 3,
                        },
                      },
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          display: false,
                        },
                        y: { display: false },
                      },
                    }}
                  />
                }
                style={{ "--cui-card-cap-bg": "#e3e000" }}
                values={[
                  { title: "Yesterday", value: dash.yesterday },
                  { title: "Today", value: dash.today },
                ]}
              />
            </CCol>
          </CRow>
        </div>
        // <CRow>
        //   <CCol xs={6}>
        //     <CWidgetStatsC
        //       className="mb-3"
        //       icon={<CIcon icon={cilChartPie} height={36} />}
        //       progress={{ color: "success", value: 75 }}
        //       text="Widget helper text"
        //       title="Widget title"
        //       value="89.9%"
        //     />
        //   </CCol>
        //   <CCol xs={6}>
        //     <CWidgetStatsC
        //       className="mb-3"
        //       icon={<CIcon icon={cilChartPie} height={36} />}
        //       color="primary"
        //       inverse
        //       progress={{ value: 75 }}
        //       text="Widget helper text"
        //       title="Widget title"
        //       value="89.9%"
        //     />
        //   </CCol>
        // </CRow>
        // <div className={"container"}>
        //   <div className={"row"}>
        //     <div className={"col-md-4"}>
        //       <div className="dbox dbox--color-1">
        //         <div className={"dbox__icon"}>
        //           <i>
        //             <GenderMale />
        //           </i>
        //         </div>
        //         <div className={"dbox__body"}>
        //           <span className={"dbox__count"} id="male">
        //             {dash.male}
        //           </span>
        //           <span className={"dbox__title"}>Male</span>
        //         </div>
        //       </div>
        //     </div>
        //     <div className={"col-md-4"}>
        //       <div className="dbox dbox--color-2">
        //         <div className={"dbox__icon"}>
        //           <i>
        //             <GenderFemale />
        //           </i>
        //         </div>
        //         <div className={"dbox__body"}>
        //           <span className={"dbox__count"} id="female">
        //             {dash.female}
        //           </span>
        //           <span className={"dbox__title"}>Female</span>
        //         </div>
        //       </div>
        //     </div>
        //     <div className={"col-md-4"}>
        //       <div className="dbox dbox--color-3">
        //         <div className={"dbox__icon"}>
        //           <i>
        //             <PeopleFill />
        //           </i>
        //         </div>
        //         <div className={"dbox__body"}>
        //           <span className={"dbox__count"} id="total">
        //             {dash.total}
        //           </span>
        //           <span className={"dbox__title"}>Total</span>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        //   <div className={"row"}>
        //     <div className={"col-md-4"}>
        //       <div className={"dbox dbox--color-4"}>
        //         <div className={"dbox__icon"}>
        //           <i>
        //             <Calendar3 />
        //           </i>
        //         </div>
        //         <div className={"dbox__body"}>
        //           <span className={"dbox__count"} id="tcount">
        //             {dash.sevendays}
        //           </span>
        //           <span className={"dbox__title"}>Last 7 Days</span>
        //         </div>
        //       </div>
        //     </div>
        //     <div className={"col-md-4"}>
        //       <div className={"dbox dbox--color-4"}>
        //         <div className={"dbox__icon"}>
        //           <i>
        //             <Calendar3Event />
        //           </i>
        //         </div>
        //         <div className={"dbox__body"}>
        //           <span className={"dbox__count"} id="ycount">
        //             {dash.yesterday}
        //           </span>
        //           <span className={"dbox__title"}>Yesterday's Entries </span>
        //         </div>
        //       </div>
        //     </div>
        //     <div className={"col-md-4"}>
        //       <div className={"dbox dbox--color-4"}>
        //         <div className={"dbox__icon"}>
        //           <i>
        //             <CalendarPlus />
        //           </i>
        //         </div>
        //         <div className={"dbox__body"}>
        //           <span className={"dbox__count"} id="wcount">
        //             {dash.today}
        //           </span>
        //           <span className={"dbox__title"}>Today's Entries </span>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      )}
    </div>
  );
}
