import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import SignatureCanvas from "react-signature-canvas";
import "../../stylesheets/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { getEntry, getProfile, useFirestore } from "../../hooks/useFirestore";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import "react-rating";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  Checkbox,
  FormGroup,
  Box,
  FormControlLabel,
  ToggleButton,
  Divider,
  Chip,
} from "@mui/material";
import { Facebook, Instagram, Twitter } from "react-bootstrap-icons";
import { dmfEntry, dmfEntryCouple, entrantProfile } from "./models";
import { projectStorage } from "../../firebase/config";
import DeleteDialog from "../alerts";
import { useAppContext } from "../../hooks/useAppContext";
import { AddressAuto } from "../../hooks/useAddressAutoComplete";

import { useMediaUpload } from "../../hooks/useMediaUpload";
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CFormCheck,
  CRow,
} from "@coreui/react";
import { useMailServer } from "../../hooks/useMailServer";
import { NavButtons } from "../wizardComponents";
import renderTermsAndConditions from "../TermsAndConditions";
import termsAndConditions from "../TermsAndConditions";
import showTermsAndConditions from "./showtermsandconditions";
import { usePlacesWidget } from "react-google-autocomplete";
export function EntryFormCouple() {
  //Params
  // const { show, cellNumber } = useParams();
  // let settings = [];

  let currentProfile = {};
  let currentProfile2 = {};
  let currentEntry = {};

  const { show, cellNumber, setObject, settings } = useAppContext();
  const { createDocumentFirestore } = useFirestore();
  const [isActive, setActive] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [error, setError] = useState("");
  const [terms, setTerms] = useState({
    checked: false,
  });
  const { user } = useAuthContext();
  const [exists, setExists] = useState("false");
  const [profile, setProfile] = useState(entrantProfile);
  const [showEntry, setShowEntry] = useState(dmfEntryCouple);
  // const [setObject, setSetObject] = useState();
  const [showPic, setEntrantPic] = useState(setObject.showLogo);
  const [showVid, setEntrantVid] = useState(setObject.showLogo);
  const form = useRef();
  const navigate = useNavigate();
  const { processEmail } = useMailServer();
  const [activeKey, setActiveKey] = useState(1);
  const sigCanvas = useRef();
  const [signatureURL, setSignatureURL] = useState(null);

  const { ref: materialRef } = usePlacesWidget({
    apiKey:
      "AIzaSyAe3hFOUMAWvtPsUdKkYeofkg_Gscn5pUA&callback=Function.prototype",
    onPlaceSelected: (place) => {
      console.log(place.formatted_address);
      setProfile({
        ...profile,
        ["fullAddress"]: {
          ...profile["fullAddress"],
          value: place.formatted_address,
          error: false,
        },
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  //Navigation for form
  const [step, setStep] = useState({
    activeStep: 1,
  });

  const handleNextClick = () => {
    let errorFound = 0;

    if (step.activeStep === 1) {
      const formFields = Object.keys(profile);
      let newFormValues = { ...profile };

      for (let index = 0; index < formFields.length; index++) {
        const currentField = formFields[index];
        const currentValue = profile[currentField].value;

        if (currentValue === "") {
          if (errorFound === 0) {
            errorFound = 1;
          }
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],
              error: true,
            },
          };
        }
      }

      setProfile({ ...newFormValues });
      window.scrollTo(0, 0);
    }

    if (step.activeStep === 2) {
      const formFields = Object.keys(showEntry);
      let newEntryValues = { ...showEntry };

      for (let index = 0; index < formFields.length; index++) {
        const currentField = formFields[index];
        const currentValue = showEntry[currentField].value;

        if (currentValue === "") {
          if (errorFound === 0) {
            errorFound = 1;
          }
          newEntryValues = {
            ...newEntryValues,
            [currentField]: {
              ...newEntryValues[currentField],
              error: true,
            },
          };
        }
      }

      setShowEntry({ ...newEntryValues });
      window.scrollTo(0, 0);
    }

    if (errorFound === 0) {
      setStep((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
      window.scrollTo(0, 0);
    }
  };

  const handlePrevClick = () => {
    setStep((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
    window.scrollTo(0, 0);
  };

  //Media
  const uploadImage = async (e, profile) => {
    let response;
    setActive(true);
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;

    const storage = projectStorage;
    const storageRef = storage.ref();
    let picLink;
    try {
      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name.value +
            profile.surname.value +
            cellNumber +
            e.target.id
        )
        .put(uploadedFile)
        .then((response) => {
          response.ref.getDownloadURL().then((link) => {
            // showEntry.pic = link;
            const id = e.target.id;
            if (id === "pic1") {
              showEntry.pic = link;
            } else if (id === "pic2") {
              showEntry.pic2 = link;
            } else if (id === "pic3") {
              showEntry.pic3 = link;
            }
            console.log(link);
            setActive(false);
          });
        });
      // / alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }
  };

  const uploadVideo = async (event, profile) => {
    setActive(true);

    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const storage = projectStorage;
    const storageRef = storage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);

      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name +
            profile.surname +
            profile.cellphone
        )
        .put(uploadedFile)
        .then(async (response) => {
          let downloadURL = await response.ref.getDownloadURL();
          // showEntry.video = downloadURL;
          // await setVidLink(downloadURL);
          showEntry.video = downloadURL;
          setEntrantVid(downloadURL);
          console.log(downloadURL);
          setActive(false);
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }

    setActive(false);
  };

  const uploadSignature = async (URL, profile) => {
    setActive(true);

    const uploadedFile = URL;
    if (!uploadedFile) return;

    const storageRef = projectStorage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);

      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name +
            profile.surname +
            profile.cellphone
        )
        .put(uploadedFile)
        .then(async (response) => {
          let downloadURL = await response.ref.getDownloadURL();
          // showEntry.video = downloadURL;
          // setEntrantVid(downloadURL);
          console.log(downloadURL);
          showEntry.signature.value = downloadURL;
          setSignatureURL(downloadURL);
          setActive(false);
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }

    setActive(false);
  };

  const handleTermsChange = (event) => {
    setTerms({ checked: event.target.checked });
    handleSignature();
  };

  // const updateLinks = useCallback(() => {
  //   showEntry.video = imgLink;
  //   setEntrantPic(imgLink);
  //
  //   showEntry.video = vidLink;
  //   setEntrantVid(vidLink);
  // }, [imgLink]);

  const handleImage = (e) => {
    uploadImage(e, profile);
  };

  const handleVideo = (e) => {
    uploadVideo(e, profile);
  };

  const handleSignature = async () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    uploadSignature(URL, profile);
  };

  const setCoupleEntry = () => {
    // setShowEntry({ ...showEntry, coupleEntry: "true" });
    showEntry.coupleEntry = "true";
  };

  const handleSubmit = (e) => {
    setCoupleEntry();

    try {
      createDocumentFirestore(
        e,
        exists,
        profile,
        showEntry,
        settings,
        user,
        show
      );
      // sendEmail(profile, showEntry, exists, settings);
      // processEmail(profile, showEntry, exists);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleChangeProfile = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: {
        ...profile[name],
        value,
        error: false,
      },
    });
  };
  const handleDelete = () => {
    setOpenClose(true);
  };

  const handleChangeEntry = (e) => {
    const { name, value } = e.target;
    setShowEntry({
      ...showEntry,
      [name]: {
        ...showEntry[name],
        value,
        error: false,
      },
    });
  };

  const handleChangePartnerEntry = (e) => {
    const { name, value } = e.target;

    setShowEntry({
      ...showEntry,

      partner: {
        ...showEntry.partner,
        [name]: { value: value },
      },
    });
    // setShowEntry({
    //   ...showEntry,
    //   partner: {
    //     ...showEntry.partner,
    //     surname: e.target.value,
    //   },
    // });

    // setShowEntry({
    //   ...showEntry,
    //   [name]: {
    //     ...showEntry[name],
    //     value,
    //   },
    // });
  };

  const fetchProfile = useCallback(async (userNumber) => {
    currentProfile = await getProfile(userNumber);
    if (currentProfile !== undefined) {
      // setProfile({ ...currentProfile });

      profile.uid = currentProfile.uid;
      profile.name.value = currentProfile.name.value;
      profile.surname.value = currentProfile.surname.value;
      profile.cellphone.value = cellNumber;
      profile.email.value = currentProfile.email.value;
      profile.gender.value = currentProfile.gender.value;
      profile.age.value = currentProfile.age.value;
      // profile.city.value = currentProfile.city;
      // profile.province.value = currentProfile.province;
      profile.fullAddress.value = currentProfile.fullAddress.value;
      profile.DOB.value = currentProfile.DOB.value;
      profile.IDNo.value = currentProfile.IDNo.value;
      profile.SexualOrientation.value = currentProfile.SexualOrientation.value;
      profile.SexualPreference.value = currentProfile.SexualPreference.value;

      console.log(profile);
      setActive(false);
    } else {
      setActive(false);
      profile.cellphone.value = cellNumber;
    }
  }, []);

  const fetchEntry = async (show, userNumber) => {
    currentEntry = await getEntry(show, userNumber);
    if (currentEntry !== undefined && currentEntry.coupleEntry === "true") {
      // setShowEntry({ ...currentEntry });

      showEntry.uid = currentEntry.uid;
      showEntry.dealbreakers.value = currentEntry.dealbreakers.value;
      showEntry.occupation.value = currentEntry.occupation.value;

      showEntry.describeYourself.value = currentEntry.describeYourself.value;
      showEntry.relationshipStatus.value =
        currentEntry.relationshipStatus.value;
      showEntry.relationshipsInterestedIn.value =
        currentEntry.relationshipsInterestedIn.value;
      showEntry.idealMatch.value = currentEntry.idealMatch.value;
      showEntry.qualitiesMatch.value = currentEntry.qualitiesMatch.value;
      // showEntry.availability.value = currentEntry.availability.value;
      showEntry.signature.value = currentEntry.signature.value;
      showEntry.pic.value = currentEntry.pic.value;
      showEntry.pic2.value = currentEntry.pic2.value;
      showEntry.pic3.value = currentEntry.pic3.value;

      showEntry.yearsTogether.value = currentEntry.yearsTogether.value;
      showEntry.polyamoryStatus.value = currentEntry.polyamoryStatus.value;
      showEntry.peopleInRelationship.value =
        currentEntry.peopleInRelationship.value;

      showEntry.partner.name.value = currentEntry.partner.name.value;
      showEntry.partner.surname.value = currentEntry.partner.surname.value;
      showEntry.partner.cellphone.value = currentEntry.partner.cellphone.value;
      showEntry.partner.email.value = currentEntry.partner.email.value;
      showEntry.partner.gender.value = currentEntry.partner.gender.value;
      showEntry.partner.age.value = currentEntry.partner.age.value;
      showEntry.partner.city.value = currentEntry.partner.city.value;
      showEntry.partner.province.value = currentEntry.partner.province.value;
      showEntry.partner.fullAddress.value =
        currentEntry.partner.fullAddress.value;
      showEntry.partner.DOB.value = currentEntry.partner.DOB.value;
      showEntry.partner.IDNo.value = currentEntry.partner.IDNo.value;
      showEntry.partner.occupation.value =
        currentEntry.partner.occupation.value;
      showEntry.partner.SexualOrientation.value =
        currentEntry.partner.SexualOrientation.value;
      showEntry.partner.SexualPreference.value =
        currentEntry.partner.SexualPreference.value;

      if (currentEntry.pic) {
        showEntry.pic = currentEntry.pic;
        // setEntrantPic(currentEntry.pic.value);
      }

      if (currentEntry.pic2) {
        showEntry.pic2 = currentEntry.pic2;
        // setEntrantPic(currentEntry.pic2.value);
      }

      if (currentEntry.pic3) {
        showEntry.pic3 = currentEntry.pic3;
        // setEntrantPic(currentEntry.pic3.value);
      }

      if (currentEntry.signature) {
        showEntry.signature.value = currentEntry.signature.value;
        // setSignatureURL(currentEntry.signature.value);
      }

      console.log(showEntry);
      setExists(true);
    } else {
      setActive(false);
      profile.cellphone.value = cellNumber;
      showEntry.pic.value = "No Picture";
      showEntry.pic2.value = "No Picture";
      showEntry.pic3.value = "No Picture";
    }
  };

  useEffect(() => {
    //console.log(user);
    setActive(true);
    fetchProfile(cellNumber);
    fetchEntry(show, cellNumber);
    // profile.cellphone.value = cellNumber;

    // setProfile({...profile, cellphone: cellNumber});
    profile.cellphone.value = cellNumber;
  }, []);

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Busy Processing, Please Wait..."
    >
      <Container>
        {error && <Alert varimaant="danger">{error}</Alert>}
        <DeleteDialog show={show} cellNumber={cellNumber} open={openClose} />
        <fieldset disabled={false}>
          <br />

          <Box
            ref={form}
            id="enterDMF"
            name="theform"
            onSubmit={handleSubmit}
            disabled
            component="form"
            // sx={{
            //   // "& .MuiTextField-root": { m: 2, width: "50ch" },
            //   "& > :not(style)": { m: 2, width: "45ch" },
            // }}
            className="testbox text-center"
            // style={{ "box-shadow": "0 20px 0 #a82877" }}
            style={{ padding: 20 }}
          >
            <div>
              {step.activeStep === 1 && (
                <div>
                  <div>
                    <h1>Personal Profile</h1>
                    {/* Names */}
                    <div>
                      <TextField
                        required
                        sx={{ m: 2, width: "40%" }}
                        id="ename"
                        label="Name"
                        name="name"
                        variant="outlined"
                        value={profile.name.value}
                        onChange={handleChangeProfile}
                        error={profile.name.error}
                        // helperText={profile.name.errorMessage}
                      ></TextField>
                      <TextField
                        required
                        sx={{ m: 2, width: "40%" }}
                        id="esurname"
                        label="Surname"
                        name="surname"
                        variant="outlined"
                        value={profile.surname.value}
                        onChange={handleChangeProfile}
                        className={"form-control"}
                        error={profile.surname.error}
                        // helperText={profile.surname.errorMessage}
                      />
                    </div>
                    {/*Contacts*/}
                    <div>
                      <TextField
                        sx={{ m: 2, width: "40%" }}
                        id="ecellphone"
                        label="Cellphone"
                        name="cellphone"
                        variant="outlined"
                        className={"form-control"}
                        value={profile.cellphone.value}
                        onChange={handleChangeProfile}
                        // error={profile.cellphone.error}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <TextField
                        sx={{ m: 2, width: "40%" }}
                        id="email"
                        label="E-Mail"
                        variant="outlined"
                        name="email"
                        className={"form-control"}
                        value={profile.email.value}
                        onChange={handleChangeProfile}
                        required
                        error={profile.email.error}
                        // helperText={profile.email.errorMessage}
                      />
                    </div>
                    {/*Age and gender*/}
                    <div>
                      <TextField
                        sx={{ m: 2, width: "40%" }}
                        type={"number"}
                        id="age"
                        name="age"
                        InputProps={{ inputProps: { min: 21, max: 35 } }}
                        label="Age"
                        placeholder="Enter your age"
                        variant="outlined"
                        value={profile.age.value}
                        onChange={handleChangeProfile}
                        requried
                        error={profile.age.error}
                        // helperText={profile.age.errorMessage}
                      />
                      <FormControl sx={{ m: 2, width: "40%" }}>
                        <InputLabel id="genderSelect">Gender</InputLabel>
                        <Select
                          name="gender"
                          variant="outlined"
                          label="genderSelect"
                          value={profile.gender.value}
                          onChange={handleChangeProfile}
                          required
                          error={profile.gender.error}
                          // helperText={profile.gender.errorMessage}
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Non-Binary">Non-Binary</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    {/*Address*/}
                    <div>
                      <TextField
                        sx={{ m: 2, width: "83%" }}
                        // inputRef={materialRef}
                        label="Full Address"
                        variant="outlined"
                        name="fullAddress"
                        className={"form-control"}
                        value={profile.fullAddress.value}
                        onChange={handleChangeProfile}
                        error={profile.fullAddress.error}
                        // helperText={profile.fullAddress.errorMessage}
                        // onBlur={(e) => {
                        //   // profile.fullAddress = e.target.value;
                        //   setProfile({
                        //     ...profile,
                        //     fullAddress: address,
                        //   });
                        // }}
                      />
                    </div>
                    {/* DOB & IDNo */}
                    <div>
                      <TextField
                        sx={{ m: 2, width: "40%" }}
                        type={"date"}
                        id="DOB"
                        name="DOB"
                        label="Date Of Birth"
                        variant="outlined"
                        value={profile.DOB.value}
                        onChange={handleChangeProfile}
                        className={"form-control"}
                        error={profile.DOB.error}
                        // helperText={profile.DOB.errorMessage}
                      />

                      <TextField
                        sx={{ m: 2, width: "40%" }}
                        id="idNo"
                        name="IDNo"
                        inputProps={{
                          maxLength: 13,
                        }}
                        label="ID Number"
                        variant="outlined"
                        value={profile.IDNo.value}
                        onChange={handleChangeProfile}
                        className={"form-control"}
                        error={profile.IDNo.error}
                        // helperText={profile.IDNo.errorMessage}
                      />
                    </div>
                    {/*Orientation and Preference*/}
                    <div>
                      <FormControl sx={{ m: 2, width: "40%" }}>
                        <InputLabel id="orientationSelect">
                          Sexual Orientation
                        </InputLabel>
                        <Select
                          name="SexualOrientation"
                          variant="outlined"
                          label="orientationSelect"
                          value={profile.SexualOrientation.value}
                          onChange={handleChangeProfile}
                          error={profile.SexualOrientation.error}
                          // helperText={profile.SexualOrientation.errorMessage}
                        >
                          <MenuItem value="Asexual">Asexual</MenuItem>
                          <MenuItem value="Bisexual">Bisexual</MenuItem>
                          <MenuItem value="Heterosexual">
                            Heterosexual(Straight)
                          </MenuItem>
                          <MenuItem value="Homosexual(Gay)">
                            Homosexual(Gay)
                          </MenuItem>
                          <MenuItem value="Homosexual(Lesbian)">
                            Homosexual(Lesbian)
                          </MenuItem>
                          <MenuItem value="Pansexual">Pansexual</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ m: 2, width: "40%" }}>
                        <InputLabel id="preferenceSelect">
                          Sexual Preference
                        </InputLabel>
                        <Select
                          name="SexualPreference"
                          variant="outlined"
                          label="preferenceSelect"
                          value={profile.SexualPreference.value}
                          onChange={handleChangeProfile}
                          error={profile.SexualPreference.error}
                          // helperText={profile.SexualPreference.errorMessage}
                        >
                          <MenuItem value="Asexual">Asexual</MenuItem>
                          <MenuItem value="Bisexual">Bisexual</MenuItem>
                          <MenuItem value="Heterosexual">
                            Heterosexual(Straight)
                          </MenuItem>
                          <MenuItem value="Homosexual(Gay)">
                            Homosexual(Gay)
                          </MenuItem>
                          <MenuItem value="Homosexual(Lesbian)">
                            Homosexual(Lesbian)
                          </MenuItem>
                          <MenuItem value="Pansexual">Pansexual</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <Divider></Divider>
                  {/*Address*/}

                  <div>
                    <h1>Your Partner's Details</h1>
                    {/* Names */}
                    <div>
                      <TextField
                        sx={{ m: 2, width: "40%" }}
                        id="pname"
                        label="Partner's Name"
                        variant="outlined"
                        name="name"
                        value={showEntry.partner.name.value}
                        onChange={handleChangePartnerEntry}
                        className={"form-control"}
                      />
                      <TextField
                        sx={{ m: 2, width: "40%" }}
                        id="esurname"
                        label="Partner's Surname"
                        variant="outlined"
                        name="surname"
                        value={showEntry.partner.surname.value}
                        onChange={handleChangePartnerEntry}
                        className={"form-control"}
                      />
                    </div>
                    {/*Contacts*/}
                    <div>
                      <TextField
                        sx={{ m: 2, width: "40%" }}
                        id="ecellp"
                        name="cellphone"
                        label="Partner's Cellphone"
                        variant="outlined"
                        className={"form-control"}
                        value={showEntry.partner.cellphone.value}
                        onChange={handleChangePartnerEntry}
                      />
                      <TextField
                        sx={{ m: 2, width: "40%" }}
                        id="email"
                        name="email"
                        label="Partner's E-Mail"
                        variant="outlined"
                        className={"form-control"}
                        value={showEntry.partner.email.value}
                        onChange={handleChangePartnerEntry}
                      />
                    </div>
                    {/*Age and gender*/}
                    <div>
                      <TextField
                        sx={{ m: 2, width: "40%" }}
                        type={"number"}
                        id="age"
                        name="age"
                        InputProps={{ inputProps: { min: 21, max: 35 } }}
                        label="Partner's Age"
                        placeholder="Enter your partner's age"
                        variant="outlined"
                        value={showEntry.partner.age.value}
                        onChange={handleChangePartnerEntry}
                      />
                      <FormControl sx={{ m: 2, width: "40%" }}>
                        <InputLabel id="genderSelect">
                          Partner's Gender
                        </InputLabel>
                        <Select
                          variant="outlined"
                          name="gender"
                          label="genderSelect"
                          value={showEntry.partner.gender.value}
                          onChange={handleChangePartnerEntry}
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Non-Binary">Non-Binary</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div>
                      <TextField
                        sx={{ m: 2, width: "83%" }}
                        // inputRef={materialRef}
                        label="Full Address"
                        variant="outlined"
                        name="fullAddress"
                        className={"form-control"}
                        value={showEntry.partner.fullAddress.value}
                        onChange={handleChangePartnerEntry}
                        // onBlur={(e) => {
                        //   // profile.fullAddress = e.target.value;
                        //   // setShowEntry({ ...showEntry, fullAddress: address });
                        //   setShowEntry({
                        //     ...showEntry,
                        //     partner: {
                        //       ...showEntry.partner,
                        //       fullAddress: address,
                        //     },
                        //   });
                        // }}
                      />
                    </div>
                    {/* DOB & IDNo */}
                    <div>
                      <TextField
                        sx={{ m: 2, width: "40%" }}
                        type={"date"}
                        id="DOB"
                        name="DOB"
                        label="Partner's DOB"
                        variant="outlined"
                        value={showEntry.partner.DOB.value}
                        onChange={handleChangePartnerEntry}
                        className={"form-control"}
                      />

                      <TextField
                        sx={{ m: 2, width: "40%" }}
                        id="idNo"
                        inputProps={{
                          maxLength: 13,
                        }}
                        label=" Partner ID No."
                        variant="outlined"
                        name="IDNo"
                        value={showEntry.partner.IDNo.value}
                        onChange={handleChangePartnerEntry}
                        className={"form-control"}
                      />
                    </div>
                    {/*Orientation and Preference*/}
                    <div>
                      <FormControl sx={{ m: 2, width: "40%" }}>
                        <InputLabel id="orientationSelect">
                          Partner's Orientation
                        </InputLabel>
                        <Select
                          variant="outlined"
                          label="orientationSelect"
                          name="SexualOrientation"
                          value={showEntry.partner.SexualOrientation.value}
                          onChange={handleChangePartnerEntry}
                        >
                          <MenuItem value="Asexual">Asexual</MenuItem>
                          <MenuItem value="Bisexual">Bisexual</MenuItem>
                          <MenuItem value="Heterosexual">
                            Heterosexual(Straight)
                          </MenuItem>
                          <MenuItem value="Homosexual(Gay)">
                            Homosexual(Gay)
                          </MenuItem>
                          <MenuItem value="Homosexual(Lesbian)">
                            Homosexual(Lesbian)
                          </MenuItem>
                          <MenuItem value="Pansexual">Pansexual</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ m: 2, width: "40%" }}>
                        <InputLabel id="preferenceSelect">
                          Partner's Preference
                        </InputLabel>
                        <Select
                          variant="outlined"
                          label="preferenceSelect"
                          name="SexualPreference"
                          value={showEntry.partner.SexualPreference.value}
                          onChange={handleChangePartnerEntry}
                        >
                          <MenuItem value="Asexual">Asexual</MenuItem>
                          <MenuItem value="Bisexual">Bisexual</MenuItem>
                          <MenuItem value="Heterosexual">
                            Heterosexual(Straight)
                          </MenuItem>
                          <MenuItem value="Homosexual(Gay)">
                            Homosexual(Gay)
                          </MenuItem>
                          <MenuItem value="Homosexual(Lesbian)">
                            Homosexual(Lesbian)
                          </MenuItem>
                          <MenuItem value="Pansexual">Pansexual</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}
              {step.activeStep === 2 && (
                <div>
                  <h1>Entry</h1>

                  {/* Occupation?  SingleWhy?"*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "40%" }}
                      multiline
                      rows={4}
                      name="occupation"
                      label="Occupations"
                      placeholder="Your Occupations"
                      variant="outlined"
                      className={"form-control"}
                      value={showEntry.occupation.value}
                      onChange={handleChangeEntry}
                      error={showEntry.occupation.error}
                    />
                    <TextField
                      sx={{ m: 2, width: "40%" }}
                      multiline
                      rows={4}
                      name="peopleInRelationship"
                      placeholder="Current people in your relationship"
                      label="People In Your Relationship."
                      className={"form-control"}
                      variant="outlined"
                      value={showEntry.peopleInRelationship.value}
                      onChange={handleChangeEntry}
                      error={showEntry.peopleInRelationship.error}
                    ></TextField>
                  </div>
                  {/*Describe Yourself and Ideal Match*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "40%" }}
                      multiline
                      rows={4}
                      name="describeYourself"
                      placeholder="Briefly Describe Yourselves?"
                      label="Describe Yourselves"
                      className={"form-control"}
                      variant="outlined"
                      value={showEntry.describeYourself.value}
                      onChange={handleChangeEntry}
                      error={showEntry.describeYourself.error}
                    ></TextField>
                    <TextField
                      sx={{ m: 2, width: "40%" }}
                      multiline
                      rows={4}
                      name="idealMatch"
                      placeholder="Describe Your Ideal Matches?"
                      label="Ideal Matches?"
                      className={"form-control"}
                      variant="outlined"
                      value={showEntry.idealMatch.value}
                      onChange={handleChangeEntry}
                      error={showEntry.occupation.error}
                    ></TextField>
                  </div>

                  {/*Polyamory Status and Years Together*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "40%" }}
                      placeholder="Polyamory Status"
                      label="Polyamory Status"
                      name="polyamoryStatus"
                      className={"form-control"}
                      variant="outlined"
                      value={showEntry.polyamoryStatus.value}
                      onChange={handleChangeEntry}
                      error={showEntry.polyamoryStatus.error}
                    ></TextField>
                    <TextField
                      sx={{ m: 2, width: "40%" }}
                      type={"number"}
                      id="age"
                      min={0}
                      name="yearsTogether"
                      label="Years Together?"
                      placeholder="Years Together??"
                      variant="outlined"
                      value={showEntry.yearsTogether.value}
                      onChange={handleChangeEntry}
                      error={showEntry.yearsTogether.error}
                    />
                  </div>
                  {/*RelationshipsInterested and Status*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "40%" }}
                      multiline
                      rows={4}
                      name="relationshipsInterestedIn"
                      placeholder="What Kinda Relationships Are You Interested In??"
                      label="Relationships Interested In?"
                      className={"form-control"}
                      variant="outlined"
                      value={showEntry.relationshipsInterestedIn.value}
                      onChange={handleChangeEntry}
                      error={showEntry.relationshipsInterestedIn.error}
                    ></TextField>
                    <TextField
                      sx={{ m: 2, width: "40%" }}
                      multiline
                      rows={4}
                      name="relationshipStatus"
                      placeholder="Describe Your Current Relationship Status?"
                      label="Relationship Status"
                      className={"form-control"}
                      variant="outlined"
                      value={showEntry.relationshipStatus.value}
                      onChange={handleChangeEntry}
                      error={showEntry.relationshipStatus.error}
                    ></TextField>
                  </div>

                  {/*qualities and dealbreakers*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "40%" }}
                      multiline
                      rows={5}
                      name="qualitiesMatch"
                      placeholder="List 5 Qualities You Seek In A Match/Matches?"
                      label="Qualities You Seek?"
                      className={"form-control"}
                      variant="outlined"
                      value={showEntry.qualitiesMatch.value}
                      onChange={handleChangeEntry}
                      error={showEntry.qualitiesMatch.error}
                    ></TextField>
                    <TextField
                      sx={{ m: 2, width: "40%" }}
                      multiline
                      rows={5}
                      name="dealbreakers"
                      placeholder="List 5 Pet Peeves/ Turn Offs or Dealbreakers (Individual & Shared)?"
                      label="Dealbreakers:"
                      className={"form-control"}
                      variant="outlined"
                      value={showEntry.dealbreakers.value}
                      onChange={handleChangeEntry}
                      error={showEntry.dealbreakers.error}
                    ></TextField>
                  </div>
                </div>
              )}
              {step.activeStep === 3 && (
                <div>
                  <h1>INSERT 2/3X PICS</h1>
                  {/*Media Uploads*/}
                  <CRow>
                    <CCol>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload a pic</Form.Label>
                        <Form.Control
                          className={"form-control"}
                          type="file"
                          id={"pic1"}
                          onChange={handleImage}
                          accept="image/*"
                        />
                      </Form.Group>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload a pic</Form.Label>
                        <Form.Control
                          className={"form-control"}
                          type="file"
                          id={"pic2"}
                          onChange={handleImage}
                          accept="image/*"
                        />
                      </Form.Group>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload a pic</Form.Label>
                        <Form.Control
                          className={"form-control"}
                          type="file"
                          id={"pic3"}
                          onChange={handleImage}
                          accept="image/*"
                        />
                      </Form.Group>
                    </CCol>
                  </CRow>

                  {/*<CRow>*/}
                  {/*  <Col>*/}
                  {/*    <Form.Group controlId="formFile" className="mb-3">*/}
                  {/*      <Form.Label>*/}
                  {/*        Upload a 30 sec video introducing yourself*/}
                  {/*      </Form.Label>*/}
                  {/*      <Form.Control*/}
                  {/*        type="file"*/}
                  {/*        onChange={handleVideo}*/}
                  {/*        accept="video/*"*/}
                  {/*      />*/}
                  {/*    </Form.Group>*/}
                  {/*  </Col>*/}
                  {/*  <Col>*/}
                  {/*    <video*/}
                  {/*      style={{ padding: "20px" }}*/}
                  {/*      src={showVid}*/}
                  {/*      className="img-fluid rounded mx-auto d-block"*/}
                  {/*      alt=""*/}
                  {/*      controls*/}
                  {/*    />*/}
                  {/*  </Col>*/}
                  {/*</CRow>*/}
                </div>
              )}
              {step.activeStep === 4 && (
                <div>
                  <CContainer className="text-center">
                    <FormGroup>
                      <Form.Label>
                        Accept terms and conditions to submit
                      </Form.Label>
                      {showTermsAndConditions()}
                      <div
                        className="sigPadContainer"
                        style={{ border: "double", margin: 1 }}
                      >
                        <label>Please sign</label>
                        <SignatureCanvas
                          penColor="black"
                          canvasProps={{
                            className: "sigCanvas",
                          }}
                          ref={sigCanvas}
                        />
                        <hr />
                        <CButton onClick={() => sigCanvas.current.clear()}>
                          Clear
                        </CButton>
                      </div>
                      <CFormCheck
                        style={{ width: "15%", margin: 3 }}
                        id="btn-check-outlined"
                        label="Accept Terms & Conditions"
                        autoComplete="off"
                        onChange={handleTermsChange}
                        value={terms.checked}
                        style={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    </FormGroup>
                  </CContainer>
                </div>
              )}
            </div>
            {terms.checked === true && step.activeStep === 4 ? (
              <div>
                <CButton
                  style={{ width: "15%", margin: 3 }}
                  color="danger"
                  variant="outline"
                  onClick={handleDelete}
                >
                  <CIcon icon={icon.cilTrash} size="xxl" /> Delete
                </CButton>
                <CButton
                  style={{ width: "15%", margin: 3 }}
                  color="success"
                  variant="outline"
                  value="Submit"
                  type="submit"
                >
                  <CIcon icon={icon.cilSend} size="xxl" /> Submit
                </CButton>
              </div>
            ) : null}
            {terms.checked === false ? (
              <div>
                <CButton
                  variant="outline"
                  color="danger"
                  style={{ width: "15%", margin: 3 }}
                  onClick={handlePrevClick}
                  disabled={step.activeStep === 1}
                >
                  <CIcon icon={icon.cilArrowThickFromRight} size="xxl" /> Back
                </CButton>
                <CButton
                  color="primary"
                  variant="outline"
                  style={{ width: "15%", margin: 3 }}
                  onClick={handleNextClick}
                  disabled={step.activeStep === 4}
                >
                  Next <CIcon icon={icon.cilArrowThickFromLeft} size="xxl" />
                </CButton>
              </div>
            ) : null}
          </Box>
        </fieldset>
      </Container>
    </LoadingOverlay>
  );
}
