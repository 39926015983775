export let entrantProfile = {
  uid: "",
  //Profile
  name: "",
  surname: "",
  cellphone: "",
  email: "",
  twitter: "",
  facebook: "",
  instagram: "",
  gender: "",
  age: 0,
  city: "",
  province: "",
  fullAddress: "",
};

export let opwEntry = {
  uid: "",
  bridename: "",
  bridearea: "",
  brideage: 0,
  brideaddress: "",
  bridecontact: "",
  bridehomelanguage: "",
  bridechildren: "",
  bridechildrenage: "",

  groomname: "",
  groomarea: "",
  groomage: 0,
  groomaddress: "",
  groomhomelanguage: "",
  groomchildren: "",
  groomchildrenage: "",
  groomcontact: "",
  weddingtype: "",
  themecolours: "",
  weddingdate: "",
  weddingvanue: "",
  children: "",
  homelanuage: "",
  wishlist: "",
  meet: "",
  challenges: "",
  proposaldate: "",
  proposal: "",
  day: "",
  partner: "",
  whyopw: "",
  bridesmaids: "",
  groomsmen: "",
  choicesbridesmaids: "",
  choicesgroomsmen: "",
  pic: "",
  video: "",
};

export let listColumns = [
  "name",
  "surname",
  "weddingtype",
  "weddingdate",
  "brideage",
  "groomage",
];
