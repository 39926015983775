// export let entrantProfile = {
//   uid: "",
//   //Profile
//   name: "",
//   surname: "",
//   cellphone: "",
//   email: "",
//   twitter: "",
//   facebook: "",
//   instagram: "",
//   gender: "",
//   age: 0,
//   city: "",
//   province: "",
//   fullAddress: "",
// };

export let entrantProfile = {
  uid: "",
  //Profile
  name: { value: "", error: false },
  surname: { value: "", error: false },
  cellphone: { value: "", error: false },
  email: { value: "", error: false },
  twitter: { value: "", error: false },
  facebook: { value: "", error: false },
  instagram: { value: "", error: false },
  gender: { value: "", error: false },
  age: { value: "", error: false },
  city: { value: "", error: false },
  province: { value: "", error: false },
  fullAddress: { value: "", error: false },
};

// export let dmfEntry = {
//   uid: "",
//   // ideal: "",
//   // describe: "",
//   // rate: "",
//   // dealbreakers: "",
//   date: "",
//
//   pic: "",
//   video: "",
//   height: 0,
//   occupation: "",
//   company: "",
//   fulladdress: "",
//   shootaddress: "",
//   shootprovince: "",
//   shootcity: "",
//   criminalrecord: false,
//   crime: "",
//   lifestory: "",
//   singlewhy: "",
//   lastrelationshipdate: "",
//   wishtosharewhat: "",
//   partner: "",
//   schooling: "",
//   alive: "",
//   difficultexperiance: "",
//   peopleyouattract: "",
//   commonreasonsbreakup: "",
//   dealbreaker: "",
//   idealphysic: "",
//   employed: false,
//   religion: "",
//   car: false,
//   partnerkids: false,
//   partnerkidsnumber: 0,
//   kids: false,
//   kidsnumber: 0,
//   breakup: "",
//   relationshipstatusx: "",
// };

export let dmfEntry = {
  uid: "",
  // ideal: "",
  // describe: "",
  // rate: "",
  // dealbreakers: "",
  date: { value: "", error: false },

  pic: { value: "", error: false },
  video: { value: "", error: false },
  height: { value: "", error: false },
  occupation: { value: "", error: false },
  company: { value: "", error: false },
  fulladdress: { value: "", error: false },
  shootaddress: { value: "", error: false },
  shootprovince: { value: "", error: false },
  shootcity: { value: "", error: false },
  criminalrecord: { value: "", error: false },
  crime: { value: "", error: false },
  lifestory: { value: "", error: false },
  singlewhy: { value: "", error: false },
  lastrelationshipdate: { value: "", error: false },
  wishtosharewhat: { value: "", error: false },
  partner: { value: "", error: false },
  schooling: { value: "", error: false },
  alive: { value: "", error: false },
  difficultexperiance: { value: "", error: false },
  peopleyouattract: { value: "", error: false },
  commonreasonsbreakup: { value: "", error: false },
  dealbreaker: { value: "", error: false },
  idealphysic: { value: "", error: false },
  employed: { value: "", error: false },
  religion: { value: "", error: false },
  car: { value: "", error: false },
  partnerkids: { value: "", error: false },
  partnerkidsnumber: { value: "", error: false },
  kids: { value: "", error: false },
  kidsnumber: { value: "", error: false },
  breakup: { value: "", error: false },
  relationshipstatusx: { value: "", error: false },
};

export let listColumns = [
  "name",
  "surname",
  "gender",
  "date",
  "kids",
  "religion",
  "car",
  "employed",
];
