import React, { useState } from "react";
import { CButton, CButtonGroup } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
export function NavButtons() {
  const [step, setStep] = useState({
    activeStep: 1,
  });
  const [formValues, setFormValues] = useState({
    name: {
      value: "",
      error: true,
      errorMessage: "You must enter a name",
    },
    surname: {
      value: "",
      error: false,
      errorMessage: "You must enter a surname",
    },
    cellphone: {
      value: "",
      error: false,
      errorMessage: "You must enter a cell no.",
    },
    email: {
      value: "",
      error: false,
      errorMessage: "You must enter an email",
    },
    age: {
      value: "",
      error: false,
      errorMessage: "You must enter an age",
    },
    gender: {
      value: "",
      error: false,
      errorMessage: "You must select a gender",
    },
    fulladdress: {
      value: "",
      error: false,
      errorMessage: "You must ender an address",
    },
    so: {
      value: "",
      error: false,
      errorMessage: "You must select an orientation",
    },
    sp: {
      value: "",
      error: false,
      errorMessage: "You must select a preference",
    },
  });
  const handleNextClick = () => {
    // let myForm = document.getElementById("enterDMF");
    //
    // console.log(myForm);
    //
    // for (const property in myForm) {
    //   console.log(`${property}: ${myForm[property]}`);
    // }
    // // myForm?.map((field) => {
    //   field.checkValidity();
    //   field.reportValidity();
    // });
    //
    // if (!myForm[0].checkValidity()) {
    //   // If the form is invalid, submit it. The form won't actually submit;
    //   // this will just cause the browser to display the native HTML5 error messages.
    //   myForm[0].reportValidity();
    //   return;
    // }
    // if (step.activeStep === 1) {
    //   setFormValues(prof);
    // }
    // if (step.activeStep === 2) {
    //   setFormValues(entry);
    // }
    //
    // let errorFound = 0;
    // const formFields = Object.keys(formValues);
    // let newFormValues = { ...formValues };
    //
    // for (let index = 0; index < formFields.length; index++) {
    //   const currentField = formFields[index];
    //   const currentValue = formValues[currentField].value;
    //
    //   if (currentValue === "") {
    //     if (errorFound === 0) {
    //       errorFound = 1;
    //     }
    //
    //     newFormValues = {
    //       ...newFormValues,
    //       [currentField]: {
    //         ...newFormValues[currentField],
    //         error: true,
    //       },
    //     };
    //   }
    // }
    //
    // setFormValues(newFormValues);
    // if (errorFound === 0) {
    //   setStep((prevState) => ({
    //     activeStep: prevState.activeStep + 1,
    //   }));
    //   window.scrollTo(0, 0);
    // }

    setStep((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
    window.scrollTo(0, 0);
  };

  const handlePrevClick = () => {
    setStep((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
    window.scrollTo(0, 0);
  };

  function Buttons() {
    return (
      <div>
        <CButton
          variant="outline"
          color="danger"
          style={{ width: "15%", margin: 3 }}
          onClick={handlePrevClick}
          disabled={step.activeStep === 1}
        >
          <CIcon icon={icon.cilArrowThickFromRight} size="xxl" /> Back
        </CButton>
        <CButton
          color="primary"
          variant="outline"
          style={{ width: "15%", margin: 3 }}
          onClick={handleNextClick}
          disabled={step.activeStep === 4}
        >
          Next <CIcon icon={icon.cilArrowThickFromLeft} size="xxl" />
        </CButton>
      </div>
    );
  }

  function CheckboxLabels() {
    return (
      <FormGroup>
        <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
      </FormGroup>
    );
  }

  return { step, Buttons, handleNextClick, CheckboxLabels, formValues };
}
