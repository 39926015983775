import pwaw from "../../images/pwaw.png";

import { Col, Container, Form, Image, Row } from "react-bootstrap";
import { Button, ImageList, ImageListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

export function IndexPage() {
  const { user } = useAuthContext();

  let navigate = useNavigate();
  return (
    <Container>
      <img
        style={{ padding: "20px", width: "800px" }}
        src={pwaw}
        className="img-fluid rounded mx-auto d-block"
        alt=""
      />
      <Row className="justify-content-center">
        <Form.Group className="text-center">
          <br />
          <Button
            style={{ margin: "15px", padding: "10px", width: "10%" }}
            variant="outlined"
            onClick={() => {
              navigate("pwaw-p");
            }}
          >
            Pastor
          </Button>

          <Button
            style={{ margin: "15px", padding: "10px", width: "10%" }}
            variant="contained"
            onClick={() => {
              navigate("pwaw-w");
            }}
          >
            Wife
          </Button>
        </Form.Group>
      </Row>
    </Container>
  );
}
