function renderTermsAndConditions() {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <p>
        By submitting your application to participate in the TV show, you
        consent to the collection, use, and disclosure of your personal data in
        accordance with these terms and conditions.
      </p>
      <ol>
        <li>
          Your personal data will be used for the purposes of evaluating your
          application, selecting participants for the TV show, and for other
          purposes as set out in the TV show's privacy policy.
        </li>
        <li>
          Your personal data may be shared with third parties, such as
          production companies and broadcasters, for the purposes of producing
          and airing the TV show.
        </li>
        <li>
          You have the right to access, correct, or request the deletion of your
          personal data at any time.
        </li>
        <li>
          The TV show and its partners will take reasonable steps to protect
          your personal data from unauthorized access or disclosure. However,
          you acknowledge that the internet is not a secure environment and the
          TV show cannot guarantee the security of your personal data.
        </li>
        <li>
          The TV show reserves the right to change these terms and conditions at
          any time. Any changes will be posted on this website.
        </li>
        <li>
          By submitting your application, you acknowledge that you have read,
          understood, and agreed to these terms and conditions.
        </li>
      </ol>
    </div>
  );
}

export default renderTermsAndConditions;
