import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../stylesheets/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { useAuthContext } from "../../hooks/useAuthContext";

import {
  createDocumentFirestore,
  getEntry,
  getProfile,
} from "../../hooks/useFirestore";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import "react-rating";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Box,
  FormControl,
  FormGroup,
} from "@mui/material";
import {
  Facebook,
  Instagram,
  SendCheckFill,
  Twitter,
} from "react-bootstrap-icons";
import { usePlacesWidget } from "react-google-autocomplete";
import { dmfEntry, entrantProfile } from "./models";
import { projectStorage } from "../../firebase/config";
import DeleteDialog from "../alerts";
import { useAppContext } from "../../hooks/useAppContext";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CFormCheck,
  CRow,
} from "@coreui/react";
import renderTermsAndConditions from "../TermsAndConditions";
import SignatureCanvas from "react-signature-canvas";
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";

export function EntryForm() {
  let currentProfile = {};
  let currentEntry = {};

  const { show, cellNumber, setObject, settings } = useAppContext();
  //States
  const [isActive, setActive] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [error, setError] = useState("");
  const [terms, setTerms] = useState({
    checked: false,
  });
  const { user } = useAuthContext();
  const [exists, setExists] = useState("false");
  const [profile, setProfile] = useState(entrantProfile);
  const [showEntry, setShowEntry] = useState(dmfEntry);
  const [showPic, setEntrantPic] = useState("../images/" + show + ".jpeg");
  const [showVid, setEntrantVid] = useState("../images/" + show + ".jpeg");
  const form = useRef();
  const sigCanvas = useRef();
  const [signatureURL, setSignatureURL] = useState(null);
  //Addresses
  const { ref: addRef } = usePlacesWidget({
    apiKey: "AIzaSyBEEUwF_2FTgUM6E7Dku28uJctPimqWCMw",
    onPlaceSelected: (place) => {
      console.log(place);
      setProfile({
        ...profile,
        ["fullAddress"]: {
          ...profile["fullAddress"],
          value: place.formatted_address,
          error: false,
        },
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  const { ref: shootAddRef } = usePlacesWidget({
    apiKey: "AIzaSyBEEUwF_2FTgUM6E7Dku28uJctPimqWCMw",
    onPlaceSelected: (place) => {
      console.log(place);
      setProfile({
        ...profile,
        ["shootAddress"]: {
          ...profile["shootAddress"],
          value: place.formatted_address,
          error: false,
        },
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  //Navigation for form
  const [step, setStep] = useState({
    activeStep: 1,
  });

  const handleNextClick = () => {
    let errorFound = 0;

    if (errorFound === 0) {
      setStep((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
      window.scrollTo(0, 0);
    }
  };

  const handlePrevClick = () => {
    setStep((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
    window.scrollTo(0, 0);
  };

  const uploadImage = async (e, profile) => {
    let response;
    setActive(true);
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;

    const storage = projectStorage;
    const storageRef = storage.ref();
    let picLink;
    try {
      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name.value +
            profile.surname.value +
            cellNumber +
            e.target.id
        )
        .put(uploadedFile)
        .then((response) => {
          response.ref.getDownloadURL().then((link) => {
            // showEntry.pic = link;
            const id = e.target.id;
            if (id === "pic1") {
              showEntry.pic.value = link;
            } else if (id === "pic2") {
              showEntry.pic2 = link;
            } else if (id === "pic3") {
              showEntry.pic3 = link;
            }
            showEntry.pic.value = link;
            setEntrantPic(link);
            console.log(link);
            setActive(false);
          });
        });
      // / alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }
  };

  const uploadVideo = async (event, profile) => {
    setActive(true);

    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const storage = projectStorage;
    const storageRef = storage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);

      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name.value +
            profile.surname.value +
            cellNumber +
            event.target.id
        )
        .put(uploadedFile)
        .then(async (response) => {
          let downloadURL = await response.ref.getDownloadURL();
          // showEntry.video = downloadURL;
          // await setVidLink(downloadURL);
          showEntry.video.value = downloadURL;
          setEntrantVid(downloadURL);
          console.log(downloadURL);
          setActive(false);
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }

    setActive(false);
  };

  const uploadSignature = async (URL, profile) => {
    setActive(true);

    const uploadedFile = URL;
    if (!uploadedFile) return;

    const storageRef = projectStorage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);

      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name +
            profile.surname +
            profile.cellphone
        )
        .put(uploadedFile)
        .then(async (response) => {
          let downloadURL = await response.ref.getDownloadURL();
          // showEntry.video = downloadURL;
          // setEntrantVid(downloadURL);
          console.log(downloadURL);
          showEntry.signature = downloadURL;
          setSignatureURL(downloadURL);
          setActive(false);
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }

    setActive(false);
  };

  const handleTermsChange = (event) => {
    setTerms({ checked: event.target.checked });
    handleSignature();
  };

  // FUNCTIONS
  const handleImage = (e) => {
    uploadImage(e, profile);
  };

  const handleVideo = (e) => {
    uploadVideo(e, profile);
  };

  const handleSignature = async () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    uploadSignature(URL, profile);
  };

  const setCoupleEntry = () => {
    showEntry.coupleEntry = "true";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      createDocumentFirestore(
        e,
        exists,
        profile,
        showEntry,
        settings,
        user,
        show
      );
    } catch (err) {
      console.log(err.message);
    }
  };
  // function assignExistingValues(now, current) {
  //   now.entries(now).forEach((entry) => {
  //     const [key, value] = entry;
  //     now[key][key].value = current[key][key].value;
  //     console.log(key, value);
  //   });
  // }

  const handleChangeEntry = (e) => {
    const { name, value } = e.target;
    setShowEntry({
      ...showEntry,
      [name]: {
        ...showEntry[name],
        value,
        error: false,
      },
    });
  };

  const handleChangeProfile = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: {
        ...profile[name],
        value,
        error: false,
      },
    });
  };

  const handleDelete = () => {
    setOpenClose(true);
  };

  const fetchProfile = useCallback(async (userNumber) => {
    currentProfile = await getProfile(userNumber);
    if (currentProfile !== undefined) {
      // setProfile({ ...currentProfile });

      profile.uid = currentProfile.uid;
      profile.name.value = currentProfile.name.value;
      profile.surname.value = currentProfile.surname.value;
      profile.cellphone.value = currentProfile.cellphone.value;
      profile.email.value = currentProfile.email.value;
      profile.twitter.value = currentProfile.twitter.value;
      profile.facebook.value = currentProfile.facebook.value;
      profile.instagram.value = currentProfile.instagram.instagram;
      profile.gender.value = currentProfile.gender.value;
      profile.age.value = currentProfile.age.value;
      profile.city.value = currentProfile.city.value;
      profile.province.value = currentProfile.province.value;
      profile.fullAddress.value = currentProfile.fullAddress.value;

      console.log(profile);
      setActive(false);
    } else {
      setActive(false);
    }
  }, []);

  const fetchEntry = async (show, userNumber) => {
    currentEntry = await getEntry(show, userNumber);
    if (currentEntry !== undefined) {
      // setShowEntry({ ...currentEntry });

      showEntry.uid = currentEntry.uid;
      showEntry.date.value = currentEntry.date;
      showEntry.height.value = currentEntry.height;
      showEntry.occupation.value = currentEntry.occupation;
      showEntry.company.value = currentEntry.company;
      showEntry.fulladdress.value = currentEntry.fulladdress;
      showEntry.shootaddress.value = currentEntry.shootaddress;
      showEntry.shootprovince.value = currentEntry.shootprovince;
      showEntry.shootcity.value = currentEntry.shootcity;
      showEntry.criminalrecord.value = currentEntry.criminalrecord;
      showEntry.crime.value = currentEntry.crime;
      showEntry.lifestory.value = currentEntry.lifestory;
      showEntry.singlewhy.value = currentEntry.singlewhy;
      showEntry.lastrelationshipdate.value = currentEntry.lastrelationshipdate;
      showEntry.wishtosharewhat.value = currentEntry.wishtosharewhat;
      showEntry.partner.value = currentEntry.partner;
      showEntry.schooling.value = currentEntry.schooling;
      showEntry.alive.value = currentEntry.alive;
      showEntry.difficultexperiance.value = currentEntry.difficultexperiance;
      showEntry.peopleyouattract.value = currentEntry.peopleyouattract;
      showEntry.commonreasonsbreakup.value = currentEntry.commonreasonsbreakup;
      showEntry.dealbreaker.value = currentEntry.dealbreakers;
      showEntry.idealphysic.value = currentEntry.idealphysic;
      showEntry.employed.value = currentEntry.employed;
      showEntry.religion.value = currentEntry.religion;
      showEntry.car.value = currentEntry.car;
      showEntry.partnerkids.value = currentEntry.partnerkids;
      showEntry.partnerkidsnumber.value = currentEntry.partnerkidsnumber;
      showEntry.kids.value = currentEntry.kids;
      showEntry.kidsnumber.value = currentEntry.kidsnumber;
      showEntry.breakup.value = currentEntry.breakup;
      showEntry.relationshipstatusx.value = currentEntry.relationshipstatusx;
      // showEntry.video = currentEntry.video;
      // showEntry.pic = currentEntry.pic;

      setEntrantPic(currentEntry.pic.value);
      setEntrantVid(currentEntry.video.value);

      if (currentEntry.video.value) {
        showEntry.video.value = currentEntry.video.value;
        setEntrantVid(currentEntry.video.value);
      } else {
        setEntrantVid(setObject.showLogo);
      }

      if (currentEntry.pic.value) {
        showEntry.pic.value = currentEntry.pic.value;
        setEntrantPic(currentEntry.pic.value);
      } else {
        setEntrantPic(setObject.showLogo);
      }

      console.log(showEntry);
      setExists(true);
    } else {
      setEntrantPic(setObject.showLogo);
      setEntrantVid(setObject.showLogo);
    }
  };

  useEffect(() => {
    //console.log(user);
    setActive(true);
    // fetchSettings(show);
    fetchProfile(cellNumber);
    fetchEntry(show, cellNumber);
  }, []);

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Busy Processing, Please Wait..."
    >
      <Container>
        {error && <Alert varimaant="danger">{error}</Alert>}
        <DeleteDialog show={show} cellNumber={cellNumber} open={openClose} />
        <fieldset disabled={false}>
          <br />
          <Box
            ref={form}
            id="enterDMF"
            name="theform"
            // onSubmit={handleSubmit}
            disabled
            component="form"
            // sx={{
            //   // "& .MuiTextField-root": { m: 2, width: "50ch" },
            //   "& > :not(style)": { m: 2, width: "45ch" },
            // }}
            className="testbox text-center"
            // style={{ "box-shadow": "0 20px 0 #a82877" }}
            style={{ padding: 20 }}
          >
            <div>
              {step.activeStep === 1 && (
                <div>
                  {/* Names */}
                  <Row style={{ margin: "2%" }}>
                    <img
                      style={{ padding: "50px", width: "500px" }}
                      // src={"../images/" + show + ".jpeg"}
                      src={setObject.showLogo}
                      className="img-fluid rounded mx-auto d-block"
                      alt=""
                    />
                    <CContainer>
                      <CCard className="text-center">
                        <CCardHeader>About</CCardHeader>{" "}
                        <CCardBody>
                          <CCardTitle>{setObject.showName}</CCardTitle>{" "}
                          <CCardText>{setObject.showDescription}</CCardText>
                          <CButton onClick={handleNextClick}>
                            Enter Show
                          </CButton>
                        </CCardBody>
                        <CCardFooter className="text-medium-emphasis">
                          Entries open until {setObject.closeDate}
                        </CCardFooter>
                      </CCard>
                    </CContainer>
                  </Row>
                </div>
              )}
              {step.activeStep === 2 && (
                <div>
                  <h1>Personal Profile</h1>
                  {/* Names */}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      id="ename"
                      label="Name"
                      name="name"
                      variant="outlined"
                      value={profile.name.value}
                      onChange={handleChangeProfile}
                      className={"form-control"}
                    />

                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      id="esurname"
                      label="Surname"
                      name="surname"
                      variant="outlined"
                      value={profile.surname.value}
                      onChange={handleChangeProfile}
                      className={"form-control"}
                    />
                  </div>
                  {/*Contacts*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      id="ecellphone"
                      name="cellphone"
                      label="Cellphone"
                      variant="outlined"
                      className={"form-control"}
                      value={cellNumber}
                      onChange={handleChangeProfile}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      id="email"
                      name="email"
                      label="E-Mail"
                      variant="outlined"
                      className={"form-control"}
                      value={profile.email.value}
                      onChange={handleChangeProfile}
                    />
                  </div>
                  {/*Age and gender*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      type={"number"}
                      name="age"
                      id="age"
                      min={18}
                      label="Age"
                      placeholder="Enter your age"
                      variant="outlined"
                      value={profile.age.value}
                      onChange={handleChangeProfile}
                    />
                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="genderSelect">Gender</InputLabel>
                      <Select
                        variant="outlined"
                        name="gender"
                        label="genderSelect"
                        value={profile.gender.value}
                        onChange={handleChangeProfile}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Ce">Ce</MenuItem>
                        <MenuItem value="Co">Co</MenuItem>
                        <MenuItem value="Cy">Cy</MenuItem>
                        <MenuItem value="Ey">Ey</MenuItem>
                        <MenuItem value="He">He</MenuItem>
                        <MenuItem value="Hey">Hey</MenuItem>
                        <MenuItem value="Ne">Ne</MenuItem>
                        <MenuItem value="Qui">Qui</MenuItem>
                        <MenuItem value="She">She</MenuItem>
                        <MenuItem value="Sie">Sie</MenuItem>
                        <MenuItem value="Tey">Tey</MenuItem>
                        <MenuItem value="They">They</MenuItem>
                        <MenuItem value="Xe">Xe</MenuItem>
                        <MenuItem value="Xie">Xie</MenuItem>
                        <MenuItem value="Yo">Yo</MenuItem>
                        <MenuItem value="Ze">Ze</MenuItem>
                        <MenuItem value="Ve">Ve</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {/*Social Media*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "29%" }}
                      id="twitter"
                      name="twitter"
                      label="Twitter"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Twitter />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      value={profile.twitter.value}
                      onChange={handleChangeProfile}
                    />

                    <TextField
                      sx={{ m: 2, width: "29%" }}
                      id="instagram"
                      name="instagram"
                      label="Instagram"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Instagram />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      value={profile.instagram.value}
                      onChange={handleChangeProfile}
                    />

                    <TextField
                      sx={{ m: 2, width: "29%" }}
                      id="facebook"
                      name="facebook"
                      label="Facebook"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Facebook />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      value={profile.facebook.value}
                      onChange={handleChangeProfile}
                    />
                  </div>
                  {/*Address*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "92%" }}
                      inputRef={addRef}
                      label="Full Address"
                      variant="outlined"
                      name="fullAddress"
                      className={"form-control"}
                      value={profile.fullAddress.value}
                      onChange={handleChangeProfile}
                    />
                  </div>
                </div>
              )}
              {step.activeStep === 3 && (
                <div>
                  <h1>Entry</h1>
                  {/*Height and Date*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      type={"number"}
                      id="height"
                      min={80}
                      label="Height"
                      placeholder="Enter your height"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">cm</InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      className={"form-control"}
                      value={showEntry.height.value}
                      onChange={handleChangeEntry}
                    />

                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="edateSelect">Date?</InputLabel>
                      <Select
                        variant="outlined"
                        labelId="dateSelect"
                        label="Date"
                        value={showEntry.date.value}
                        defaultValue={"Other"}
                        onChange={handleChangeEntry}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Ce">Ce</MenuItem>
                        <MenuItem value="Co">Co</MenuItem>
                        <MenuItem value="Cy">Cy</MenuItem>
                        <MenuItem value="Ey">Ey</MenuItem>
                        <MenuItem value="He">He</MenuItem>
                        <MenuItem value="Hey">Hey</MenuItem>
                        <MenuItem value="Ne">Ne</MenuItem>
                        <MenuItem value="Qui">Qui</MenuItem>
                        <MenuItem value="She">She</MenuItem>
                        <MenuItem value="Sie">Sie</MenuItem>
                        <MenuItem value="Tey">Tey</MenuItem>
                        <MenuItem value="They">They</MenuItem>
                        <MenuItem value="Xe">Xe</MenuItem>
                        <MenuItem value="Xie">Xie</MenuItem>
                        <MenuItem value="Yo">Yo</MenuItem>
                        <MenuItem value="Ze">Ze</MenuItem>
                        <MenuItem value="Ve">Ve</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  {/*Highest schooling achievement? */}
                  <div>
                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="shoolingSelect">Schooling</InputLabel>
                      <Select
                        className={"form-control"}
                        labelId="shoolingSelect"
                        label="Education"
                        value={showEntry.schooling.value}
                        onChange={handleChangeEntry}
                      >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="High School">High school</MenuItem>
                        <MenuItem value="Technical">
                          Technical Certificate
                        </MenuItem>
                        <MenuItem value="Occupational">
                          Occupational Certificate
                        </MenuItem>
                        <MenuItem value="Professional">Professional</MenuItem>
                        <MenuItem value="Associate">Associate degree</MenuItem>
                        <MenuItem value="Bachelor">Bachelor's degree</MenuItem>
                        <MenuItem value="Master">Master's degree</MenuItem>
                        <MenuItem value="Doctorate">Doctorate</MenuItem>
                      </Select>
                    </FormControl>

                    {/* Date & Criminal Record  */}
                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="genderSelect">
                        Criminal Record?
                      </InputLabel>
                      <Select
                        className={"form-control"}
                        labelId="genderSelect"
                        label="Do you have a Criminal Record?"
                        value={showEntry.criminalrecord.value}
                        onChange={handleChangeEntry}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  {/*  <CCol>*/}
                  {/*    <TextField*/}
                  {/*      id="crime"*/}
                  {/*      label="If so, what crime did you commit?"*/}
                  {/*      variant="outlined"*/}
                  {/*      className={"form-control"}*/}
                  {/*      value={showEntry.crime}*/}
                  {/*      onChange={(e) => {*/}
                  {/*        setShowEntry({*/}
                  {/*          ...showEntry,*/}
                  {/*          crime: e.target.value,*/}
                  {/*        });*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*  </CCol>*/}
                  {/*</CRow>*/}
                  {/* Describe your ideals partners physic?   "Do they have to be employed?*/}

                  <div>
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      multiline
                      rows={4}
                      label="Ideal partner's physic?"
                      placeholder="Describe your ideal partner's physic?"
                      className={"form-control"}
                      variant="outlined"
                      value={showEntry.idealphysic.value}
                      onChange={handleChangeEntry}
                    ></TextField>

                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="employed">
                        Do they have to be employed?
                      </InputLabel>
                      <Select
                        className={"form-control"}
                        labelId="employed"
                        label="Do they have to be employed"
                        value={showEntry.employed.value}
                        onChange={handleChangeEntry}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {/* Religion  Car?"*/}
                  <div>
                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="religionSelect">
                        What religion must they follow?
                      </InputLabel>
                      <Select
                        className={"form-control"}
                        labelId="religionSelect"
                        label="Religion"
                        value={showEntry.religion.value}
                        onChange={handleChangeEntry}
                      >
                        <MenuItem value="Any">Any</MenuItem>
                        <MenuItem value="African Diaspora Religions">
                          African Diaspora Religions
                        </MenuItem>
                        <MenuItem value="Atheism/Agnosticism">
                          Atheism/Agnosticism
                        </MenuItem>
                        <MenuItem value="American Religions">
                          American Religions
                        </MenuItem>
                        <MenuItem value="Bahá’í">Bahá’í</MenuItem>
                        <MenuItem value="Buddhism">Buddhism</MenuItem>
                        <MenuItem value="Christianity">Christianity</MenuItem>
                        <MenuItem value="Confucianism">Confucianism</MenuItem>
                        <MenuItem value="Druze">Druze</MenuItem>
                        <MenuItem value="Gnosticism">Gnosticism</MenuItem>
                        <MenuItem value="Hinduism">Hinduism</MenuItem>
                        <MenuItem value="Indigenous">Indigenous</MenuItem>
                        <MenuItem value="Islam">Islam</MenuItem>
                        <MenuItem value="Jainism">Jainism</MenuItem>
                        <MenuItem value="Judaism">Judaism</MenuItem>
                        <MenuItem value="Rastafarianism">
                          Rastafarianism
                        </MenuItem>
                        <MenuItem value="Shinto">Shinto</MenuItem>
                        <MenuItem value="Sikhism">Sikhism</MenuItem>
                        <MenuItem value="Traditional African Religions">
                          Traditional African Religions
                        </MenuItem>
                        <MenuItem value="Zoroastrianism">
                          Zoroastrianism
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="car">Should they have a car??</InputLabel>
                      <Select
                        className={"form-control"}
                        labelId="car"
                        label="Should they have a car?"
                        value={showEntry.car.value}
                        onChange={handleChangeEntry}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  {/* Do you have kids?  Max Kids?"*/}
                  <div>
                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="pkids">Do you have kids??</InputLabel>
                      <Select
                        className={"form-control"}
                        labelId="pkids"
                        label="Do you have kids??"
                        value={showEntry.kids.value}
                        onChange={handleChangeEntry}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      type={"number"}
                      min={0}
                      label="Kids number"
                      placeholder="Enter your height"
                      variant="outlined"
                      className={"form-control"}
                      value={showEntry.kidsnumber.value}
                      onChange={handleChangeEntry}
                    />
                  </div>

                  {/* What is your deal breaker?*/}
                  <div style={{ margin: "2%" }}>
                    <TextField
                      multiline
                      rows={4}
                      label="What is your deal breaker?"
                      className={"form-control"}
                      variant="outlined"
                      value={showEntry.dealbreakers.value}
                      onChange={handleChangeEntry}
                    ></TextField>
                  </div>
                </div>
              )}
              {step.activeStep === 4 && (
                <div>
                  <h1>Media</h1>
                  {/*Media Uploads*/}
                  <CRow>
                    <CCol>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload a pic of yourself</Form.Label>
                        <Form.Control
                          className={"form-control"}
                          type="file"
                          onChange={handleImage}
                          accept="image/*"
                        />
                      </Form.Group>
                    </CCol>
                    <CCol>
                      <img
                        style={{ padding: "20px" }}
                        src={showPic}
                        className="img-fluid rounded mx-auto d-block"
                        alt=""
                      />
                    </CCol>
                  </CRow>

                  <CRow>
                    <Col>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>
                          Upload a 30 sec video introducing yourself
                        </Form.Label>
                        <Form.Control
                          type="file"
                          onChange={handleVideo}
                          accept="video/*"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <video
                        style={{ padding: "20px" }}
                        src={showVid}
                        className="img-fluid rounded mx-auto d-block"
                        alt=""
                        controls
                      />
                    </Col>
                  </CRow>
                </div>
              )}
              {step.activeStep === 5 && (
                <div>
                  <CContainer className="text-center">
                    <FormGroup>
                      <Form.Label>
                        Accept terms and conditions to submit
                      </Form.Label>
                      {renderTermsAndConditions()}
                      <div
                        className="sigPadContainer"
                        style={{ border: "double", margin: 1 }}
                      >
                        <label>Please sign</label>
                        <SignatureCanvas
                          penColor="black"
                          canvasProps={{
                            className: "sigCanvas",
                          }}
                          ref={sigCanvas}
                        />
                        <hr />
                        <CButton onClick={() => sigCanvas.current.clear()}>
                          Clear
                        </CButton>
                      </div>
                      <CFormCheck
                        style={{ width: "15%", margin: 3 }}
                        id="btn-check-outlined"
                        label="Accept Terms & Conditions"
                        autoComplete="off"
                        onChange={handleTermsChange}
                        value={terms.checked}
                        style={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    </FormGroup>
                  </CContainer>
                </div>
              )}
            </div>
            {terms.checked === true ? (
              <div>
                <CButton
                  style={{ width: "15%", margin: 3 }}
                  color="danger"
                  variant="outline"
                  onClick={handleDelete}
                >
                  <CIcon icon={icon.cilTrash} size="xxl" /> Delete
                </CButton>
                <CButton
                  style={{ width: "15%", margin: 3 }}
                  color="success"
                  variant="outline"
                  type="submit"
                >
                  <CIcon icon={icon.cilSend} size="xxl" /> Submit
                </CButton>
              </div>
            ) : null}
            {terms.checked === false && step.activeStep !== 1 ? (
              // <Buttons></Buttons>
              <div>
                <CButton
                  variant="outline"
                  color="danger"
                  style={{ width: "15%", margin: 3 }}
                  onClick={handlePrevClick}
                  disabled={step.activeStep === 1}
                >
                  <CIcon icon={icon.cilArrowThickFromRight} size="xxl" /> Back
                </CButton>
                <CButton
                  color="primary"
                  variant="outline"
                  style={{ width: "15%", margin: 3 }}
                  onClick={handleNextClick}
                  disabled={step.activeStep === 5}
                >
                  Next <CIcon icon={icon.cilArrowThickFromLeft} size="xxl" />
                </CButton>
              </div>
            ) : null}
          </Box>
        </fieldset>
      </Container>
    </LoadingOverlay>
  );
}
