import React, { useCallback, useEffect, useState } from "react";
import { useCollection } from "../../hooks/useCollection";
// import { DataGrid, SearchPanel } from "devextreme-react";
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
  Selection,
} from "devextreme-react/data-grid";
import { projectFirestore } from "../../firebase/config";
import { Export } from "devextreme-react/chart";
import { useAppContext } from "../../hooks/useAppContext";

export function EntryList() {
  const { show } = useAppContext();
  const [documents, setDocuments] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const [mergedSets, setMergedSets] = useState([]);
  const { setObject } = useAppContext();

  const combineProfileEntries = useCallback(async () => {
    if (profiles !== null && documents !== null) {
      const mapSets = new Map();
      profiles.forEach((item) => mapSets.set(item.uid, item));
      documents.forEach((item) =>
        mapSets.set(item.uid, { ...mapSets.get(item.uid), ...item })
      );
      setMergedSets(Array.from(mapSets.values()));
    }
  }, [documents, profiles]);

  const getAllProfiles = async () => {
    let entry = {};
    const ref = await projectFirestore.collection("Profiles");

    const unsubscibe = ref.onSnapshot(
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        ///update state
        setProfiles(results);

        // setError(null);
      },
      (error) => {
        console.log(error);
        // setError("could not fetch the data");
      }
    );
    //unsubscribe on amount
    return () => unsubscibe();
  };

  const getAllEntries = async () => {
    let entry = {};
    const ref = await projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries");
    const unsubscibe = ref.onSnapshot(
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        ///update state
        setDocuments(results);
        // setError(null);
      },
      (error) => {
        console.log(error);
        // setError("could not fetch the data");
      }
    );

    //unsubscribe on amount
    return () => unsubscibe();
  };

  useEffect(() => {
    //console.log(user);

    if (profiles == null) {
      getAllProfiles();
      getAllEntries();
    }
    combineProfileEntries();
  }, [combineProfileEntries]);

  const renderGridCell = (data) => {
    return (
      <a
        href={"/" + show + "/list/entry/" + show + "/" + data.text}
        target="_blank"
        rel="noopener noreferrer"
      >
        check
      </a>
    );
  };

  const renderDate = (data) => {
    return new Date(data.value.seconds * 1000).toDateString();
    // return data.toString();
  };

  const renderTime = (data) => {
    return new Date(data.value.seconds * 1000).toTimeString();
  };

  const allowedPageSizes = [15, 30, 50];

  return (
    <div className=" container">
      <img
        style={{ padding: "20px", width: "800px" }}
        // src={"/../images/" + show + ".jpeg"}
        src={setObject.showLogo}
        className="img-fluid rounded mx-auto d-block"
        alt=""
      />
      <DataGrid dataSource={mergedSets} showBorders={true}>
        <HeaderFilter visible={true} />
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={false} />
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Export enabled={true} allowExportSelectedData={true} />
        <Selection mode="multiple" />
        <Column
          dataField="CreatedAt"
          caption="Date"
          sortOrder={"desc"}
          cellRender={renderDate}
        />
        <Column dataField="CreatedAt" caption="Time" cellRender={renderTime} />
        <Column dataField="name.value" />
        <Column dataField="surname.value" />
        <Column dataField="gender.value" />
        <Column dataField="cellphone" />
        <Column dataField="email.value" />
        <Column dataField="SexualOrientation.value" />
        <Column dataField="SexualPreference.value" />
        <Column dataField="age.value" />
        <Column dataField="coupleEntry" />
        <Column
          dataField="cellphone"
          caption="Entry"
          cellRender={renderGridCell}
        />

        <Paging defaultPageSize={15} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={allowedPageSizes}
        />
      </DataGrid>
    </div>
  );
}
