import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import SignatureCanvas from "react-signature-canvas";
import "../../stylesheets/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  createDocumentFirestore,
  getEntry,
  getProfile,
} from "../../hooks/useFirestore";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import "react-rating";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  Checkbox,
  FormGroup,
  Box,
  FormControlLabel,
  ToggleButton,
} from "@mui/material";
import { Facebook, Instagram, Twitter } from "react-bootstrap-icons";
import { dmfEntry, entrantProfile } from "./models";
import { projectStorage } from "../../firebase/config";
import DeleteDialog from "../alerts";
import { useAppContext } from "../../hooks/useAppContext";
import { useAddressAuto } from "../../hooks/useAddressAutoComplete";
import { useMediaUpload } from "../../hooks/useMediaUpload";
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CFormCheck,
  CRow,
} from "@coreui/react";
import { useMailServer } from "../../hooks/useMailServer";
import { NavButtons } from "../wizardComponents";
import renderTermsAndConditions from "../TermsAndConditions";
import termsAndConditions from "../TermsAndConditions";
export function EntryForm() {
  //Params
  // const { show, cellNumber } = useParams();
  // let settings = [];

  let currentProfile = {};
  let currentEntry = {};

  const { show, cellNumber, setObject, settings } = useAppContext();
  const [isActive, setActive] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [error, setError] = useState("");
  const [terms, setTerms] = useState({
    checked: false,
  });
  const { user } = useAuthContext();
  const [exists, setExists] = useState("false");
  const [profile, setProfile] = useState(entrantProfile);
  const [showEntry, setShowEntry] = useState(dmfEntry);
  // const [setObject, setSetObject] = useState();
  const [showPic, setEntrantPic] = useState(setObject.showLogo);
  const [showVid, setEntrantVid] = useState(setObject.showLogo);
  const form = useRef();
  const navigate = useNavigate();
  // const { places, address, addressProvince, addressCity } = useAddressAuto();
  const { materialRef, address, addressProvince, addressCity } =
    useAddressAuto();
  // const {
  //   imgLink,
  //   vidLink,
  //   sigLink,
  //   uploadVideo,
  //   uploadImage,
  //   uploadSignature,
  // } = useMediaUpload();
  const { step, Buttons, handleNextClick, CheckboxLabels } = NavButtons();
  const { processEmail } = useMailServer();
  const [activeKey, setActiveKey] = useState(1);
  const sigCanvas = useRef();
  const [signatureURL, setSignatureURL] = useState(null);
  //Addresses
  // const { ref: addRef } = places;

  //Media
  const uploadImage = async (e, profile) => {
    let response;
    setActive(true);
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;

    const storage = projectStorage;
    const storageRef = storage.ref();
    let picLink;
    try {
      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name +
            profile.surname +
            cellNumber
        )
        .put(uploadedFile)
        .then((response) => {
          response.ref.getDownloadURL().then((link) => {
            // showEntry.pic = link;
            showEntry.pic = link;
            setEntrantPic(link);
            console.log(link);
            setActive(false);
          });
        });
      // / alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }
  };

  const uploadVideo = async (event, profile) => {
    setActive(true);

    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const storage = projectStorage;
    const storageRef = storage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);

      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name +
            profile.surname +
            profile.cellphone
        )
        .put(uploadedFile)
        .then(async (response) => {
          let downloadURL = await response.ref.getDownloadURL();
          // showEntry.video = downloadURL;
          // await setVidLink(downloadURL);
          showEntry.video = downloadURL;
          setEntrantVid(downloadURL);
          console.log(downloadURL);
          setActive(false);
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }

    setActive(false);
  };

  const uploadSignature = async (URL, profile) => {
    setActive(true);

    const uploadedFile = URL;
    if (!uploadedFile) return;

    const storageRef = projectStorage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);

      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name +
            profile.surname +
            profile.cellphone
        )
        .put(uploadedFile)
        .then(async (response) => {
          let downloadURL = await response.ref.getDownloadURL();
          // showEntry.video = downloadURL;
          // setEntrantVid(downloadURL);
          console.log(downloadURL);
          showEntry.signature = downloadURL;
          setSignatureURL(downloadURL);
          setActive(false);
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }

    setActive(false);
  };

  const handleTermsChange = (event) => {
    setTerms({ checked: event.target.checked });
    handleSignature();
  };

  // const updateLinks = useCallback(() => {
  //   showEntry.video = imgLink;
  //   setEntrantPic(imgLink);
  //
  //   showEntry.video = vidLink;
  //   setEntrantVid(vidLink);
  // }, [imgLink]);

  const handleImage = (e) => {
    uploadImage(e, profile);
  };

  const handleVideo = (e) => {
    uploadVideo(e, profile);
  };

  const handleSignature = async () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    uploadSignature(URL, profile);
  };

  const handleSubmit = (e) => {
    try {
      createDocumentFirestore(
        e,
        exists,
        profile,
        showEntry,
        settings,
        user,
        show
      );
      // sendEmail(profile, showEntry, exists, settings);

      //processEmail(profile, showEntry, exists);
      navigate("/thankyou/" + show);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleDelete = () => {
    setOpenClose(true);
  };

  const fetchProfile = useCallback(async (userNumber) => {
    currentProfile = await getProfile(userNumber);
    if (currentProfile !== undefined) {
      // setProfile({ ...currentProfile });

      profile.uid = currentProfile.uid;
      profile.name = currentProfile.name;
      profile.surname = currentProfile.surname;
      profile.cellphone = currentProfile.cellphone;
      profile.email = currentProfile.email;
      profile.twitter = currentProfile.twitter;
      profile.facebook = currentProfile.facebook;
      profile.instagram = currentProfile.instagram;
      profile.gender = currentProfile.gender;
      profile.age = currentProfile.age;
      profile.city = currentProfile.city;
      profile.province = currentProfile.province;
      profile.fullAddress = currentProfile.fullAddress;

      console.log(profile);
      setActive(false);
    } else {
      setActive(false);
    }
  }, []);

  const fetchEntry = async (show, userNumber) => {
    currentEntry = await getEntry(show, userNumber);
    if (currentEntry !== undefined) {
      // setShowEntry({ ...currentEntry });

      showEntry.uid = currentEntry.uid;
      showEntry.date = currentEntry.date;
      showEntry.height = currentEntry.height;
      showEntry.occupation = currentEntry.occupation;
      showEntry.company = currentEntry.company;
      showEntry.fulladdress = currentEntry.fulladdress;
      showEntry.shootaddress = currentEntry.shootaddress;
      showEntry.shootprovince = currentEntry.shootprovince;
      showEntry.shootcity = currentEntry.shootcity;
      showEntry.criminalrecord = currentEntry.criminalrecord;
      showEntry.crime = currentEntry.crime;
      showEntry.lifestory = currentEntry.lifestory;
      showEntry.singlewhy = currentEntry.singlewhy;
      showEntry.lastrelationshipdate = currentEntry.lastrelationshipdate;
      showEntry.wishtosharewhat = currentEntry.wishtosharewhat;
      showEntry.partner = currentEntry.partner;
      showEntry.schooling = currentEntry.schooling;
      showEntry.alive = currentEntry.alive;
      showEntry.difficultexperiance = currentEntry.difficultexperiance;
      showEntry.peopleyouattract = currentEntry.peopleyouattract;
      showEntry.commonreasonsbreakup = currentEntry.commonreasonsbreakup;
      showEntry.dealbreaker = currentEntry.dealbreaker;
      showEntry.dealbreakers = currentEntry.dealbreakers;
      showEntry.idealphysic = currentEntry.idealphysic;
      showEntry.employed = currentEntry.employed;
      showEntry.religion = currentEntry.religion;
      showEntry.car = currentEntry.car;
      showEntry.partnerkids = currentEntry.partnerkids;
      showEntry.partnerkidsnumber = currentEntry.partnerkidsnumber;
      showEntry.kids = currentEntry.kids;
      showEntry.kidsnumber = currentEntry.kidsnumber;
      showEntry.breakup = currentEntry.breakup;
      showEntry.relationshipstatusx = currentEntry.relationshipstatusx;
      showEntry.video = "";
      showEntry.pic = "";
      showEntry.signature = "";

      if (currentEntry.video) {
        showEntry.video = currentEntry.video;
        setEntrantVid(currentEntry.video);
      }

      if (currentEntry.pic) {
        showEntry.pic = currentEntry.pic;
        setEntrantPic(currentEntry.pic);
      }

      if (currentEntry.signature) {
        showEntry.signature = currentEntry.signature;
        setSignatureURL(currentEntry.signature);
      }

      console.log(showEntry);
      setExists(true);
    }
  };

  useEffect(() => {
    //console.log(user);
    setActive(true);
    fetchProfile(cellNumber);
    fetchEntry(show, cellNumber);
  }, []);

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Busy Processing, Please Wait..."
    >
      <Container>
        {error && <Alert varimaant="danger">{error}</Alert>}
        <DeleteDialog show={show} cellNumber={cellNumber} open={openClose} />
        <fieldset disabled={false}>
          <br />
          <Box
            ref={form}
            id="enterDMF"
            name="theform"
            // onSubmit={handleSubmit}
            disabled
            component="form"
            // sx={{
            //   // "& .MuiTextField-root": { m: 2, width: "50ch" },
            //   "& > :not(style)": { m: 2, width: "45ch" },
            // }}
            className="testbox text-center"
            // style={{ "box-shadow": "0 20px 0 #a82877" }}
            style={{ padding: 20 }}
          >
            <div>
              {step.activeStep === 1 && (
                <div>
                  {/* Names */}
                  <Row style={{ margin: "2%" }}>
                    <img
                      style={{ padding: "50px", width: "500px" }}
                      // src={"../images/" + show + ".jpeg"}
                      src={setObject.showLogo}
                      className="img-fluid rounded mx-auto d-block"
                      alt=""
                    />
                    <CContainer>
                      <CCard className="text-center">
                        <CCardHeader>About</CCardHeader>{" "}
                        <CCardBody>
                          <CCardTitle>{setObject.showName}</CCardTitle>{" "}
                          <CCardText>{setObject.showDescription}</CCardText>
                          <CButton onClick={handleNextClick}>
                            Enter Show
                          </CButton>
                        </CCardBody>
                        <CCardFooter className="text-medium-emphasis">
                          Entries open until {setObject.closeDate}
                        </CCardFooter>
                      </CCard>
                    </CContainer>
                  </Row>
                </div>
              )}
              {step.activeStep === 2 && (
                <div>
                  <h1>Personal Profile</h1>
                  {/* Names */}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      id="ename"
                      label="Name"
                      variant="outlined"
                      value={profile.name}
                      onChange={(e) => {
                        // profile.name = e.target.value;
                        setProfile({ ...profile, name: e.target.value });
                      }}
                      className={"form-control"}
                    />

                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      id="esurname"
                      label="Surname"
                      variant="outlined"
                      value={profile.surname}
                      onChange={(e) => {
                        setProfile({ ...profile, surname: e.target.value });
                      }}
                      className={"form-control"}
                    />
                  </div>
                  {/*Contacts*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      id="ecellphone"
                      label="Cellphone"
                      variant="outlined"
                      className={"form-control"}
                      value={cellNumber}
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          cellphone: e.target.value,
                        });
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      id="email"
                      label="E-Mail"
                      variant="outlined"
                      className={"form-control"}
                      value={profile.email}
                      onChange={(e) => {
                        setProfile({ ...profile, email: e.target.value });
                      }}
                    />
                  </div>
                  {/*Age and gender*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      type={"number"}
                      id="age"
                      min={18}
                      label="Age"
                      placeholder="Enter your age"
                      variant="outlined"
                      value={profile.age}
                      onChange={(e) => {
                        setProfile({ ...profile, age: e.target.value });
                      }}
                    />
                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="genderSelect">Gender</InputLabel>
                      <Select
                        variant="outlined"
                        label="genderSelect"
                        value={profile.gender}
                        onChange={(e) => {
                          setProfile({
                            ...profile,
                            gender: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Ce">Ce</MenuItem>
                        <MenuItem value="Co">Co</MenuItem>
                        <MenuItem value="Cy">Cy</MenuItem>
                        <MenuItem value="Ey">Ey</MenuItem>
                        <MenuItem value="He">He</MenuItem>
                        <MenuItem value="Hey">Hey</MenuItem>
                        <MenuItem value="Ne">Ne</MenuItem>
                        <MenuItem value="Qui">Qui</MenuItem>
                        <MenuItem value="She">She</MenuItem>
                        <MenuItem value="Sie">Sie</MenuItem>
                        <MenuItem value="Tey">Tey</MenuItem>
                        <MenuItem value="They">They</MenuItem>
                        <MenuItem value="Xe">Xe</MenuItem>
                        <MenuItem value="Xie">Xie</MenuItem>
                        <MenuItem value="Yo">Yo</MenuItem>
                        <MenuItem value="Ze">Ze</MenuItem>
                        <MenuItem value="Ve">Ve</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {/*Social Media*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "29%" }}
                      id="twitter"
                      label="Twitter"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Twitter />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      value={profile.twitter}
                      onChange={(e) => {
                        setProfile({ ...profile, twitter: e.target.value });
                      }}
                    />

                    <TextField
                      sx={{ m: 2, width: "29%" }}
                      id="instagram"
                      label="Instagram"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Instagram />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      value={profile.instagram}
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          instagram: e.target.value,
                        });
                      }}
                    />

                    <TextField
                      sx={{ m: 2, width: "29%" }}
                      id="facebook"
                      label="Facebook"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Facebook />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      value={profile.facebook}
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          facebook: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {/*Address*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "92%" }}
                      inputRef={materialRef}
                      label="Full Address"
                      variant="outlined"
                      className={"form-control"}
                      value={profile.fullAddress}
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          fullAddress: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {step.activeStep === 3 && (
                <div>
                  <h1>Entry</h1>
                  {/*Height and Date*/}
                  <div>
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      type={"number"}
                      id="height"
                      min={80}
                      label="Height"
                      placeholder="Enter your height"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">cm</InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      className={"form-control"}
                      value={showEntry.height}
                      onChange={(e) => {
                        setShowEntry({ ...showEntry, height: e.target.value });
                      }}
                    />

                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="edateSelect">Date?</InputLabel>
                      <Select
                        variant="outlined"
                        labelId="dateSelect"
                        label="Date"
                        value={showEntry.date}
                        defaultValue={"Other"}
                        onChange={(e) => {
                          setShowEntry({
                            ...showEntry,
                            date: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Ce">Ce</MenuItem>
                        <MenuItem value="Co">Co</MenuItem>
                        <MenuItem value="Cy">Cy</MenuItem>
                        <MenuItem value="Ey">Ey</MenuItem>
                        <MenuItem value="He">He</MenuItem>
                        <MenuItem value="Hey">Hey</MenuItem>
                        <MenuItem value="Ne">Ne</MenuItem>
                        <MenuItem value="Qui">Qui</MenuItem>
                        <MenuItem value="She">She</MenuItem>
                        <MenuItem value="Sie">Sie</MenuItem>
                        <MenuItem value="Tey">Tey</MenuItem>
                        <MenuItem value="They">They</MenuItem>
                        <MenuItem value="Xe">Xe</MenuItem>
                        <MenuItem value="Xie">Xie</MenuItem>
                        <MenuItem value="Yo">Yo</MenuItem>
                        <MenuItem value="Ze">Ze</MenuItem>
                        <MenuItem value="Ve">Ve</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  {/*Highest schooling achievement? */}
                  <div>
                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="shoolingSelect">Schooling</InputLabel>
                      <Select
                        className={"form-control"}
                        labelId="shoolingSelect"
                        label="Education"
                        value={showEntry.schooling}
                        onChange={(e) => {
                          setShowEntry({
                            ...showEntry,
                            schooling: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="High School">High school</MenuItem>
                        <MenuItem value="Technical">
                          Technical Certificate
                        </MenuItem>
                        <MenuItem value="Occupational">
                          Occupational Certificate
                        </MenuItem>
                        <MenuItem value="Professional">Professional</MenuItem>
                        <MenuItem value="Associate">Associate degree</MenuItem>
                        <MenuItem value="Bachelor">Bachelor's degree</MenuItem>
                        <MenuItem value="Master">Master's degree</MenuItem>
                        <MenuItem value="Doctorate">Doctorate</MenuItem>
                      </Select>
                    </FormControl>

                    {/* Date & Criminal Record  */}
                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="genderSelect">
                        Criminal Record?
                      </InputLabel>
                      <Select
                        className={"form-control"}
                        labelId="genderSelect"
                        label="Do you have a Criminal Record?"
                        value={showEntry.criminalrecord}
                        onChange={(e) => {
                          setShowEntry({
                            ...showEntry,
                            criminalrecord: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  {/*  <CCol>*/}
                  {/*    <TextField*/}
                  {/*      id="crime"*/}
                  {/*      label="If so, what crime did you commit?"*/}
                  {/*      variant="outlined"*/}
                  {/*      className={"form-control"}*/}
                  {/*      value={showEntry.crime}*/}
                  {/*      onChange={(e) => {*/}
                  {/*        setShowEntry({*/}
                  {/*          ...showEntry,*/}
                  {/*          crime: e.target.value,*/}
                  {/*        });*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*  </CCol>*/}
                  {/*</CRow>*/}
                  {/* Describe your ideals partners physic?   "Do they have to be employed?*/}

                  <div>
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      multiline
                      rows={4}
                      label="Ideal partner's physic?"
                      placeholder="Describe your ideal partner's physic?"
                      className={"form-control"}
                      variant="outlined"
                      value={showEntry.idealphysic}
                      onChange={(e) => {
                        setShowEntry({
                          ...showEntry,
                          idealphysic: e.target.value,
                        });
                      }}
                    ></TextField>

                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="employed">
                        Do they have to be employed?
                      </InputLabel>
                      <Select
                        className={"form-control"}
                        labelId="employed"
                        label="Do they have to be employed"
                        value={showEntry.employed}
                        onChange={(e) => {
                          setShowEntry({
                            ...showEntry,
                            employed: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {/* Religion  Car?"*/}
                  <div>
                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="religionSelect">
                        What religion must they follow?
                      </InputLabel>
                      <Select
                        className={"form-control"}
                        labelId="religionSelect"
                        label="Religion"
                        value={showEntry.religion}
                        onChange={(e) => {
                          setShowEntry({
                            ...showEntry,
                            religion: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="Any">Any</MenuItem>
                        <MenuItem value="African Diaspora Religions">
                          African Diaspora Religions
                        </MenuItem>
                        <MenuItem value="Atheism/Agnosticism">
                          Atheism/Agnosticism
                        </MenuItem>
                        <MenuItem value="American Religions">
                          American Religions
                        </MenuItem>
                        <MenuItem value="Bahá’í">Bahá’í</MenuItem>
                        <MenuItem value="Buddhism">Buddhism</MenuItem>
                        <MenuItem value="Christianity">Christianity</MenuItem>
                        <MenuItem value="Confucianism">Confucianism</MenuItem>
                        <MenuItem value="Druze">Druze</MenuItem>
                        <MenuItem value="Gnosticism">Gnosticism</MenuItem>
                        <MenuItem value="Hinduism">Hinduism</MenuItem>
                        <MenuItem value="Indigenous">Indigenous</MenuItem>
                        <MenuItem value="Islam">Islam</MenuItem>
                        <MenuItem value="Jainism">Jainism</MenuItem>
                        <MenuItem value="Judaism">Judaism</MenuItem>
                        <MenuItem value="Rastafarianism">
                          Rastafarianism
                        </MenuItem>
                        <MenuItem value="Shinto">Shinto</MenuItem>
                        <MenuItem value="Sikhism">Sikhism</MenuItem>
                        <MenuItem value="Traditional African Religions">
                          Traditional African Religions
                        </MenuItem>
                        <MenuItem value="Zoroastrianism">
                          Zoroastrianism
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="car">Should they have a car??</InputLabel>
                      <Select
                        className={"form-control"}
                        labelId="car"
                        label="Should they have a car?"
                        value={showEntry.car}
                        onChange={(e) => {
                          setShowEntry({
                            ...showEntry,
                            car: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  {/* Do you have kids?  Max Kids?"*/}
                  <div>
                    <FormControl sx={{ m: 2, width: "45%" }}>
                      <InputLabel id="pkids">Do you have kids??</InputLabel>
                      <Select
                        className={"form-control"}
                        labelId="pkids"
                        label="Do you have kids??"
                        value={showEntry.kids}
                        onChange={(e) => {
                          setShowEntry({
                            ...showEntry,
                            kids: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      sx={{ m: 2, width: "45%" }}
                      type={"number"}
                      min={0}
                      label="Kids number"
                      placeholder="Enter your height"
                      variant="outlined"
                      className={"form-control"}
                      value={showEntry.kidsnumber}
                      onChange={(e) => {
                        setShowEntry({
                          ...showEntry,
                          kidsnumber: e.target.value,
                        });
                      }}
                    />
                  </div>

                  {/* What is your deal breaker?*/}
                  <div style={{ margin: "2%" }}>
                    <TextField
                      multiline
                      rows={4}
                      label="What is your deal breaker?"
                      className={"form-control"}
                      variant="outlined"
                      value={showEntry.dealbreakers}
                      onChange={(e) => {
                        setShowEntry({
                          ...showEntry,
                          dealbreakers: e.target.value,
                        });
                      }}
                    ></TextField>
                  </div>
                </div>
              )}
              {step.activeStep === 4 && (
                <div>
                  <h1>Media</h1>
                  {/*Media Uploads*/}
                  <CRow>
                    <CCol>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload a pic of yourself</Form.Label>
                        <Form.Control
                          className={"form-control"}
                          type="file"
                          onChange={handleImage}
                          accept="image/*"
                        />
                      </Form.Group>
                    </CCol>
                    <CCol>
                      <img
                        style={{ padding: "20px" }}
                        src={showPic}
                        className="img-fluid rounded mx-auto d-block"
                        alt=""
                      />
                    </CCol>
                  </CRow>

                  <CRow>
                    <Col>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>
                          Upload a 30 sec video introducing yourself
                        </Form.Label>
                        <Form.Control
                          type="file"
                          onChange={handleVideo}
                          accept="video/*"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <video
                        style={{ padding: "20px" }}
                        src={showVid}
                        className="img-fluid rounded mx-auto d-block"
                        alt=""
                        controls
                      />
                    </Col>
                  </CRow>
                </div>
              )}
              {step.activeStep === 5 && (
                <div>
                  <CContainer className="text-center">
                    <FormGroup>
                      <Form.Label>
                        Accept terms and conditions to submit
                      </Form.Label>
                      {renderTermsAndConditions()}
                      <div
                        className="sigPadContainer"
                        style={{ border: "double", margin: 1 }}
                      >
                        <label>Please sign</label>
                        <SignatureCanvas
                          penColor="black"
                          canvasProps={{
                            className: "sigCanvas",
                          }}
                          ref={sigCanvas}
                        />
                        <hr />
                        <CButton onClick={() => sigCanvas.current.clear()}>
                          Clear
                        </CButton>
                      </div>
                      <CFormCheck
                        style={{ width: "15%", margin: 3 }}
                        id="btn-check-outlined"
                        label="Accept Terms & Conditions"
                        autoComplete="off"
                        onChange={handleTermsChange}
                        value={terms.checked}
                        style={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    </FormGroup>
                  </CContainer>
                </div>
              )}
            </div>
            {terms.checked === true && step.activeStep === 5 ? (
              <div>
                <CButton
                  style={{ width: "15%", margin: 3 }}
                  color="danger"
                  variant="outline"
                  onClick={handleDelete}
                >
                  <CIcon icon={icon.cilTrash} size="xxl" /> Delete
                </CButton>
                <CButton
                  style={{ width: "15%", margin: 3 }}
                  color="success"
                  variant="outline"
                  value="Submit"
                  type="submit"
                  onClick={handleSubmit}
                >
                  <CIcon icon={icon.cilSend} size="xxl" /> Submit
                </CButton>
              </div>
            ) : null}
            {step.activeStep !== 1 && terms.checked === false ? (
              <Buttons></Buttons>
            ) : null}
          </Box>
        </fieldset>
      </Container>
    </LoadingOverlay>
  );
}
