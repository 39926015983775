import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { useAuthContext } from "./hooks/useAuthContext";
import {
  BrowserRouter,
  Route,
  Navigate,
  Routes,
  createSearhParams,
} from "react-router-dom";

import { IndexMainPage } from "./components/indexMainPage";

import { EntryForm as EntryFormDMF } from "./components/dmf/entryForm";
import { EntryList as EntryListDMF } from "./components/dmf/entryList";

import { EntryForm as EntryFormDemo } from "./components/demo/entryForm";
import { EntryList as EntryListDemo } from "./components/demo/entryList";

import { EntryForm as EntryFormOPW } from "./components/opw/entryForm";
import { EntryList as EntryListOPW } from "./components/opw/entryList";

import { MainPage as MainOE } from "./components/oe/mainPage";
import { EntryForm as EntryFormOE } from "./components/oe/entryForm";
import { EntryFormCouple as EntryFormCoupleOE } from "./components/oe/entryFormCouple";
import { EntryList as EntryListOE } from "./components/oe/entryList";
import { EntryForm as EntryListView } from "./components/oe/entryFormView";

import { EntryForm as EntryFormPWAWP } from "./components/pwaw/entryFormPastor";
import { EntryForm as EntryFormPWAW } from "./components/pwaw/entryForm";
import { IndexPage as EntryFormIndex } from "./components/pwaw/indexPage";
import { EntryList as EntryListPWAW } from "./components/pwaw/entryList";

import { Settings } from "./components/settings";
import { EntriesClosed } from "./components/entriesClosed";
import { LogIn } from "./components/logIn";
import { LogIn as LogInAdmin } from "./components/logInEmail";
import { ThankYou } from "./components/thankyou";
import { Dashboard } from "./components/Dashboard";
import { DeleteConfirmation } from "./components/deleteConfirmation";
import AppContextProvider from "./context/AppContext";

function App() {
  const { authIsReady, user } = useAuthContext();

  return (
    <div>
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<IndexMainPage />} />

            {/*Admin and show agnostic*/}
            <Route path="/settings">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <Settings />
                  </AppContextProvider>
                }
              />
            </Route>

            <Route path="/dashboard">
              <Route
                path=":show"
                // element={
                //   <AppContextProvider>
                //     <Dashboard />
                //   </AppContextProvider>
                // }
                element={
                  user?.email ? (
                    <AppContextProvider>
                      <Dashboard />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/admin/oe" />
                  )
                }
              />
            </Route>

            <Route path="/thankyou">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <ThankYou />
                  </AppContextProvider>
                }
              />
            </Route>

            <Route path="/deleteconfirm">
              <Route path=":show" element={<DeleteConfirmation />} />
            </Route>

            <Route path="/entriesclosed">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <EntriesClosed />
                  </AppContextProvider>
                }
              />
            </Route>

            <Route path="/login">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <LogIn />
                  </AppContextProvider>
                }
              />
            </Route>

            <Route path="/login/admin">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <LogInAdmin />
                  </AppContextProvider>
                }
              />
            </Route>

            {/*OE*/}

            <Route path="/main">
              <Route
                path=":show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <MainOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/oe" />
                  )
                }
              />
            </Route>
            <Route path="/main">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <MainOE />
                  </AppContextProvider>
                }
              />
            </Route>
            <Route path="/oe/entry">
              <Route
                path=":show"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/oe" />
                  )
                }
              />
              <Route
                path="couple/:show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormCoupleOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/oe" />
                  )
                }
              />
              <Route
                path="single/:show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/oe" />
                  )
                }
              />
            </Route>
            <Route path="/oe/list">
              <Route
                path=":show"
                // element={
                //   <AppContextProvider>
                //     <EntryListOE />
                //   </AppContextProvider>
                // }
                element={
                  user?.email ? (
                    <AppContextProvider>
                      <EntryListOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/admin/oe" />
                  )
                }
              />
            </Route>
            <Route path="/oe/list/entry">
              <Route
                path=":show/:cellNumber"
                element={
                  <AppContextProvider>
                    <EntryListView />
                  </AppContextProvider>
                }
              />
              <Route
                path="couple/:show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormCoupleOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/oe" />
                  )
                }
              />
              <Route
                path="single/:show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormOE />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/oe" />
                  )
                }
              />
            </Route>

            {/*Demo*/}
            <Route path="/demo/entry">
              <Route
                path=":show"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormDemo />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/demo" />
                  )
                }
              />
              <Route
                path=":show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormDemo />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/demo" />
                  )
                }
              />
            </Route>
            <Route path="/demo/list">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <EntryListDemo />
                  </AppContextProvider>
                }
              />
            </Route>

            {/*Tests and Prototypes*/}

            {/*Date My Family*/}
            <Route path="/dmf/entry">
              <Route
                path=":show"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormDMF />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/dmf" />
                  )
                }
              />
              <Route
                path=":show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormDMF />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/dmf" />
                  )
                }
              />
            </Route>
            <Route path="/dmf/list">
              <Route
                path=":show"
                element={
                  <AppContextProvider>
                    <EntryListDMF />
                  </AppContextProvider>
                }
              />
            </Route>

            {/*Our Perfect Wedding*/}
            <Route path="/opw/entry">
              {/*<Route*/}
              {/*  path=":show"*/}
              {/*  element={user ? <EntryFormOPW /> : <Navigate to="/login/opw" />}*/}
              {/*/>*/}
              <Route
                path=":show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormOPW />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/opw" />
                  )
                }
              />
            </Route>

            {/*Pastor Wants A Wife*/}
            <Route path="/pwaw/entry">
              {/*<Route*/}
              {/*  path=":show"*/}
              {/*  element={user ? <EntryFormOPW /> : <Navigate to="/login/opw" />}*/}
              {/*/>*/}
              <Route
                path=":show/:cellNumber"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormIndex />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/pwaw" />
                  )
                }
              />

              <Route
                path=":show/:cellNumber/pwaw-w"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormPWAW />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/pwaw" />
                  )
                }
              />
              <Route
                path=":show/:cellNumber/pwaw-p"
                element={
                  user ? (
                    <AppContextProvider>
                      <EntryFormPWAWP />
                    </AppContextProvider>
                  ) : (
                    <Navigate to="/login/pwaw" />
                  )
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
