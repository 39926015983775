import { sendEntryEmail } from "./useFirestore";
import emailjs from "@emailjs/browser";
import { useAppContext } from "./useAppContext";
import { useNavigate } from "react-router-dom";

export const useMailServer = () => {
  const { show, setObject, setActive } = useAppContext();
  const navigate = useNavigate();

  const sendEntryEmail = async (profile, entry, exists, settings) => {
    var rentry = exists === true ? "(Update)" : "";
    var couple = entry.coupleEntry === "true" ? "(couple)" : "";
    var showDetails = { ...entry };
    var templateParams = {
      from_name:
        profile.name.value +
        " " +
        profile.surname.value +
        " " +
        profile.age.value +
        " " +
        couple,

      to_name: settings.name,
      reply_to: profile.email.value,
      message: "",
      cc: settings.showEmail,
      update: rentry,
      //Profile
      name: profile.name.value,
      surname: profile.surname.value,
      cellphone: profile.cellphone,
      email: profile.email.value,
      // twitter: profile.twitter,
      // facebook: profile.facebook,
      // instagram: profile.instagram,
      gender: profile.gender.value,
      age: profile.age.value,
      fullAddress: profile.fullAddress.value,
      // province: profile.province.value,
      SexualOrientation: profile.SexualOrientation.value,
      SexualPreference: profile.SexualPreference.value,
      IDNo: profile.IDNo.value,
      DOB: profile.DOB.value,
    };

    templateParams = { ...templateParams, ...showDetails };

    emailjs
      .send(
        settings.serviceID,
        settings.templateID,
        templateParams,
        settings.publicKey
      )
      .then(
        (result) => {
          console.log(result.text);
          return result;
        },
        (error) => {
          console.log(error.text);
          return error;
        }
      );
  };

  const processEmail = (profile, showEntry, exists) => {
    try {
      setActive(true);
      sendEntryEmail(profile, showEntry, exists, setObject).then((result) => {
        console.log(result);
        setActive(false);
        navigate("/thankyou/" + show);
      });
    } catch (error) {
      console.log(error.text);
    }
  };

  return { processEmail };
};
