import { useEffect, useReducer, useState } from "react";
import {
  projectFirestore,
  projectStorage,
  timestamp,
} from "../firebase/config";
import emailjs from "@emailjs/browser";
import { dashObjectModel } from "../components/models";
import { useMailServer } from "./useMailServer";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./useAppContext";

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
};

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case "IS_PENDING":
      return { isPending: true, document: null, success: false, error: null };
    case "ADDED_DOCUMENT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        document: null,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const useFirestore = (collection) => {
  const navigate = useNavigate();
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);
  const { setActive } = useAppContext();
  const { processEmail } = useMailServer();
  const [dashObject, setDashObject] = useState(dashObjectModel);
  // //Collection ref
  // const ref = projectFirestore.collection(collection);
  //
  // //Only dispatch is not cancelled
  // const dispatchIfNotCancelled = (action) => {
  //   if (!isCancelled) {
  //     dispatch(action);
  //   }
  // };
  //
  // //dd
  // const addDocument = async (doc) => {
  //   dispatch({ type: "IS_PENDING" });
  //   try {
  //     const createdAt = timestamp.fromDate(new Date());
  //     const addedDocument = await ref.add({ ...doc, createdAt });
  //     dispatchIfNotCancelled({
  //       type: "ADDED_DOCUMENT",
  //       payload: addedDocument,
  //     });
  //   } catch (err) {
  //     dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
  //   }
  // };
  //
  // const deleteDocument = (show, cellnumber) => {
  //   projectFirestore
  //     .collection("Shows")
  //     .doc(show)
  //     .collection("Entries")
  //     .doc(cellnumber)
  //     .delete()
  //     .then(() => {
  //       console.log("Document successfully deleted!");
  //     })
  //     .catch((error) => {
  //       console.error("Error removing document: ", error);
  //     });
  // };

  const createDocumentFirestore = (
    e,
    exists,
    profile,
    showEntry,
    settings,
    user,
    show
  ) => {
    profile.cellphone = user.phoneNumber;
    profile.uid = user.uid;
    showEntry.uid = user.uid;
    // setCellphone(user.phoneNumber);
    console.log("Submitting Entry");
    console.log(exists);
    e.preventDefault();

    const prof = profile;

    const entry = showEntry;

    console.log(entry);
    console.log("Entry being created and created");
    const CreatedAt = timestamp.fromDate(new Date());
    //addDocument(entry);

    try {
      if (exists === "false") {
        setActive(true);
        console.log("Creating New");

        console.log(prof);
        console.log(entry);

        projectFirestore
          .collection("Profiles")
          .doc(user.phoneNumber)
          .set({ ...prof, CreatedAt });

        projectFirestore
          .collection("Shows")
          .doc(show)
          .collection("Entries")
          .doc(user.phoneNumber)
          .set({ ...entry, CreatedAt })
          .then(() => {
            setActive(false);
            processEmail(profile, showEntry, exists);
            // sendEntryEmail(profile, entry, exists, settings);
            navigate("/thankyou/" + show);
            window.scrollTo(0, 0);
          });

        // alert("Thank you for your entry");
      } else {
        setActive(true);
        console.log("Updating");

        console.log(prof);
        console.log(entry);

        projectFirestore
          .collection("Profiles")
          .doc(user.phoneNumber)
          .update(prof);

        projectFirestore
          .collection("Shows")
          .doc(show)
          .collection("Entries")
          .doc(user.phoneNumber)
          .update(entry)
          .then((result) => {
            setActive(false);
            processEmail(profile, showEntry, exists);
            // sendEntryEmail(profile, entry, exists, settings);
            navigate("/thankyou/" + show);
            window.scrollTo(0, 0);
          });

        // alert("Thank you for your entry");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getDashCounts = async (show) => {
    const query = projectFirestore
      .collection("Profiles")
      .where("gender", "==", "Male");

    const observer = await query.onSnapshot(
      (querySnapshot) => {
        dashObject.male = querySnapshot.size;
        setDashObject({ ...dashObject, male: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query2 = projectFirestore
      .collection("Profiles")
      .where("gender", "==", "Female");

    const observer2 = await query2.onSnapshot(
      (querySnapshot) => {
        dashObject.female = querySnapshot.size;
        setDashObject({ ...dashObject, female: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query3 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .onSnapshot((snap) => {
        dashObject.total = snap.size;
      });

    var DateRightNow = Date.now();
    var Begin7Days = Date.now() - 604800000;
    var DateObject = new Date(DateRightNow);
    var BeginObject = new Date(Begin7Days);
    var Begin24 = Date.now() - 86400000;
    var Begin24Object = new Date(Begin24);

    var OnlyToday = DateObject.setHours("00", "00", "00", "00");
    var OnlyTodayObj = new Date(OnlyToday);

    var OnlyYesterday = Begin24Object.setHours("00", "00", "00", "00");
    var OnlyYesterObj = new Date(OnlyYesterday);

    console.log("This is 7 Days Ago" + BeginObject);
    console.log("This is today midnight" + DateObject);
    console.log("This is yesterday midnight" + Begin24Object);
    // console.log(OnlyTodayObj);

    var RightNow = Date.now();
    var NowObject = new Date(RightNow);
    console.log("This is right now " + NowObject);
    console.log("This is today Midnight " + DateObject);

    const query4 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .where("CreatedAt", "<=", NowObject)
      .where("CreatedAt", ">=", BeginObject);

    const observer4 = await query4.onSnapshot(
      (querySnapshot) => {
        dashObject.sevendays = querySnapshot.size;
        setDashObject({ ...dashObject, sevendays: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query5 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .where("CreatedAt", "<=", DateObject)
      .where("CreatedAt", ">=", Begin24Object);

    const observer5 = await query5.onSnapshot(
      (querySnapshot) => {
        dashObject.yesterday = querySnapshot.size;
        setDashObject({ ...dashObject, yesterday: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query6 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .where("CreatedAt", ">=", DateObject)
      .where("CreatedAt", "<=", NowObject);

    const observer6 = await query6.onSnapshot(
      (querySnapshot) => {
        dashObject.today = querySnapshot.size;
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query7 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .where("coupleEntry", "==", "true");

    const observer7 = await query7.onSnapshot(
      (querySnapshot) => {
        dashObject.couples = querySnapshot.size;
        setDashObject({ ...dashObject, couples: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query8 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .where("coupleEntry", "==", "false");

    const observer8 = await query8.onSnapshot(
      (querySnapshot) => {
        dashObject.single = querySnapshot.size;
        setDashObject({ ...dashObject, single: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    const query10 = projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries")
      .where("gender", "==", "male");

    const observer10 = await query10.onSnapshot(
      (querySnapshot) => {
        dashObject.male = querySnapshot.size;
        setDashObject({ ...dashObject, male: querySnapshot.size });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    // function observer9() {
    //   let today = new Date();
    //   let sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    //   let dailyTotals = {};
    //   projectFirestore
    //     .collection("Shows")
    //     .doc(show)
    //     .collection("Entries")
    //     .where("created_at", ">=", sevenDaysAgo)
    //     .where("created_at", "<=", today)
    //     .orderBy("created_at")
    //     .select("created_at")
    //     .groupBy("created_at", "day")
    //     .get()
    //     .then(function (querySnapshot) {
    //       querySnapshot.forEach(function (doc) {
    //         let day = doc.id;
    //         let count = doc.size;
    //         dailyTotals[day] = count;
    //       });
    //       console.log(dailyTotals);
    //     });
    //   setDashObject({ ...dashObject, dashGraph: dashObject });
    //   console.log(dashObject);
    //   // return dashObject;
    // }

    // observer();
    // observer2();
    // observer4();
    // observer5();
    // observer6();
    // observer7();
    // observer8();
    //observer9();

    // return dashObject;
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return {
    deleteDocument,
    response,
    createDocumentFirestore,
    getDashCounts,
    dashObject,
  };
};

export const getSettings = async (show) => {
  let settings = [];

  const results = await projectFirestore.collection("Settings").doc(show).get();
  settings = results.data();
  console.log({ settings });
  return settings;
};

export const getAllShows = async () => {
  let shows = [];
  const results = await projectFirestore.collection("Settings").get();
  shows = results.docs.map((doc) => doc.data());
  console.log({ shows });
  return shows;
};

export const getProfile = async (mobileNumber) => {
  let profile = {};
  const results = await projectFirestore
    .collection("Profiles")
    .doc(mobileNumber)
    .get();
  profile = results.data();
  console.log({ profile });
  return profile;
};

export const getEntry = async (show, mobileNumber) => {
  let entry = {};
  const results = await projectFirestore
    .collection("Shows")
    .doc(show)
    .collection("Entries")
    .doc(mobileNumber)
    .get();
  entry = results.data();
  console.log({ entry });
  return entry;
};

export const picUpload = async (event, show, name, surname, mobileNumber) => {
  const uploadedFile = event.target.files[0];
  if (!uploadedFile) return;

  const storage = projectStorage;
  const storageRef = storage.ref();
  const downloadURL = "";
  try {
    // await storageRef.child(uploadedFile.name).put(uploadedFile);

    var refObject = await storageRef
      .child("EntriesMedia/" + show + "/" + name + surname + mobileNumber)
      .put(uploadedFile)
      .then(async (response) => {
        response.ref.getDownloadURL().then((link) => {
          const downloadURL = link;
          console.log(downloadURL);
        });
      });
    // alert("Successfully uploaded video!");
  } catch (error) {
    console.log("error", error);
    alert(error.message);
  }

  return downloadURL;
};

export const createDocumentFirestore = (
  e,
  exists,
  profile,
  showEntry,
  settings,
  user,
  show
) => {
  profile.cellphone = user.phoneNumber;
  profile.uid = user.uid;
  showEntry.uid = user.uid;
  // setCellphone(user.phoneNumber);
  console.log("Submitting Entry");
  console.log(exists);
  e.preventDefault();

  const prof = profile;

  const entry = showEntry;

  console.log(entry);
  console.log("Entry being created and created");
  const CreatedAt = timestamp.fromDate(new Date());
  //addDocument(entry);

  try {
    if (exists === "false") {
      // setActive(true);
      console.log("Creating New");

      console.log(prof);
      console.log(entry);

      projectFirestore
        .collection("Profiles")
        .doc(user.phoneNumber)
        .set({ ...prof, CreatedAt });

      projectFirestore
        .collection("Shows")
        .doc(show)
        .collection("Entries")
        .doc(user.phoneNumber)
        .set({ ...entry, CreatedAt })
        .then(() => {
          // setActive(false);
          sendEntryEmail(profile, entry, exists, settings);
          // navigate("/thankyou/" + show);
          window.scrollTo(0, 0);
        });

      // alert("Thank you for your entry");
    } else {
      // setActive(true);
      console.log("Updating");

      console.log(prof);
      console.log(entry);

      projectFirestore
        .collection("Profiles")
        .doc(user.phoneNumber)
        .update(prof);

      projectFirestore
        .collection("Shows")
        .doc(show)
        .collection("Entries")
        .doc(user.phoneNumber)
        .update(entry)
        .then((result) => {
          // setActive(false);
          sendEntryEmail(profile, entry, exists, settings);
          // navigate("/thankyou/" + show);
          window.scrollTo(0, 0);
        });

      // alert("Thank you for your entry");
    }
  } catch (err) {
    console.log(err.message);
  }
};

export const videoUpload = async (event, show, name, surname, mobileNumber) => {
  const uploadedFile = event.target.files[0];
  if (!uploadedFile) return;

  const storage = projectStorage;
  const storageRef = storage.ref();

  try {
    // await storageRef.child(uploadedFile.name).put(uploadedFile);

    var refObject = await storageRef
      .child("EntriesMedia/" + show + "/" + name + surname + mobileNumber)
      .put(uploadedFile)
      .then(async (response) => {
        let downloadURL = await response.ref.getDownloadURL();
        console.log(downloadURL);
        return downloadURL;
      });
    // alert("Successfully uploaded video!");
  } catch (error) {
    console.log("error", error);
    alert(error.message);
  }
};

export const sendEntryEmail = async (profile, entry, exists, settings) => {
  var rentry = exists === "true" ? "(Update)" : "";
  var showDetails = { ...entry };
  var templateParams = {
    from_name:
      rentry +
      profile.name +
      " " +
      profile.surname +
      " " +
      profile.gender +
      profile.age,
    to_name: settings.name,
    reply_to: profile.email,
    message: "",
    cc: settings.showEmail,
    update: rentry,
    //Profile
    name: profile.name,
    surname: profile.surname,
    cellphone: profile.cellphone,
    email: profile.email,
    twitter: profile.twitter,
    facebook: profile.facebook,
    instagram: profile.instagram,
    gender: profile.gender,
    age: profile.age,
    city: profile.city,
    province: profile.province,

    //Show

    // height: entry.height,
    // occupation: entry.occupation,
    // company: entry.company,
    // fulladdress: entry.fulladdress,
    // shootaddress: entry.shootaddress,
    // shootprovince: entry.shootprovince,
    // shootcity: entry.shootcity,
    //
    // criminalrecord: entry.criminalrecord,
    // lifestory: entry.lifestory,
    // singlewhy: entry.singlewhy,
    // lastrelationshipdate: entry.lastrelationshipdate,
    // wishtosharewhat: entry.wishtosharewhat,
    // partner: entry.partner,
    // shooling: entry.schooling,
    // alive: entry.alive,
    // difficultexperiance: entry.difficultexperiance,
    // peopleyouattract: entry.peopleyouattract,
    // commonreasonsbreakup: entry.commonreasonsbreakup,
    // dealbreaker: entry.dealbreakers.dealbreaker,
    // idealphysic: entry.dealbreakers.idealphysic,
    // employed: entry.dealbreakers.employed,
    // religion: entry.dealbreakers.religion,
    // car: entry.dealbreakers.car,
    // partnerkids: entry.partnerkids,
    // partnerkidsnumber: entry.partnerkidsnumber,
    // kids: entry.kids,
    // kidsnumber: entry.kidsnumber,
    // breakup: entry.breakup,
    // relationshipstatusx: entry.relationshipstatusx,
    // dealbreakers: entry.dealbreakers,
    // date: entry.date,
    // pic: entry.pic,
    // video: entry.video,
  };

  templateParams = { ...templateParams, ...showDetails };

  emailjs
    .send(
      // "service_5qmae9w",
      // "template_stejekl",
      // templateParams,
      // "ObYVC8fuZSx8NJ9g-"
      settings.serviceID,
      settings.templateID,
      templateParams,
      settings.publicKey
    )
    .then(
      (result) => {
        console.log(result.text);
        return result;
      },
      (error) => {
        console.log(error.text);
        return error;
      }
    );
};

export const deleteDocument = (show, cellnumber) => {
  projectFirestore
    .collection("Shows")
    .doc(show)
    .collection("Entries")
    .doc(cellnumber)
    .delete()
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });
};
