import React, { useContext, useState, useEffect } from "react";
import {
  useNavigate,
  Navigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import logo from "../images/dmf.png";
import PhoneInput from "react-phone-input-2";
import "bootstrap/dist/css/bootstrap.css";
import "react-phone-input-2/lib/bootstrap.css";
import { getSettings } from "../hooks/useFirestore";

import {
  Form,
  Alert,
  FormGroup,
  ButtonGroup,
  Button,
  Container,
  FormLabel,
  FormControl,
  FormText,
} from "react-bootstrap";
import {
  projectAuth,
  projectFirestore,
  recaptchaVerifierCreate,
} from "../firebase/config";
import { useAuthContext } from "../hooks/useAuthContext";
import "../stylesheets/style.css";
import { useAppContext } from "../hooks/useAppContext";
import { Input, TextField } from "@mui/material";

export function LogIn() {
  // const color = "#05a1c9";

  //const { show } = useParams();
  const { show, cellNumber, setObject } = useAppContext();
  //let settings = getSettings(show);

  const [cellno, setCellno] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("");
  const [OTP, setOTP] = useState("");
  const [isActive, setActive] = useState(false);
  const [flag, setflag] = useState(false);
  const [confirmObj, setConfirmObj] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    //Check Open Entry
    const todayDate = new Date();
    const settingsDate = new Date(setObject.closeDate);

    if (todayDate > settingsDate) {
      navigate("/entriesclosed/" + show);
      return;
    }
    document.body.style.background = setObject.color;
    window.scrollTo(0, 0);
    // setLogo(setObject.showLogo);
  }, []);

  const { dispatch } = useAuthContext();

  projectAuth.useDeviceLanguage();

  function recaptchaRender(number) {
    // /** @type {firebase.auth.RecaptchaVerifier} */

    window.recaptchaVerifier = new recaptchaVerifierCreate(
      "recaptcha-container"
    );

    const recaptchaVerifier = window.recaptchaVerifier;

    recaptchaVerifier.render();

    return projectAuth.signInWithPhoneNumber(number, recaptchaVerifier);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (email === "" || email === undefined) {
      return setError("Please enter a valid email");
    }

    try {
      projectAuth
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in
          let user = userCredential.user;
          // .
          dispatch({ type: "LOGIN", payload: userCredential.user });
          console.log(userCredential);
          if (userCredential.user.email) {
            navigate("/");
          }
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorMessage + " " + errorCode);
          setError(error.message);
        });
    } catch (err) {
      setError(err.message);
    }

    console.log();
  };

  return (
    <Container>
      <div className="p-4 .container-fluid">
        <img
          style={{ padding: "50px", width: "500px" }}
          // src={"../images/" + show + ".jpeg"}
          src={setObject.showLogo}
          className="img-fluid rounded mx-auto d-block"
          alt=""
        />
        <br />
        {error && <Alert variant="danger">{error}</Alert>}
        <Form
          onSubmit={handleSubmit}
          style={{ display: !flag ? "block" : "none" }}
        >
          <FormGroup className="mb-3 col text-center">
            <TextField
              value={email}
              id={"email"}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              label={"Email"}
            ></TextField>

            {/*<FormLabel>EMail</FormLabel>*/}
            {/*<Input*/}
            {/*  value={email}*/}
            {/*  id={"email"}*/}
            {/*  onChange={(e) => {*/}
            {/*    setEmail(e.target.value);*/}
            {/*  }}*/}
            {/*></Input>*/}
          </FormGroup>
          <FormGroup className="mb-3 col text-center">
            <TextField
              value={password}
              type={"password"}
              id={"password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              label={"Password"}
            ></TextField>

            {/*<FormLabel>Password</FormLabel>*/}
            {/*<Input*/}
            {/*  value={password}*/}
            {/*  id={"password"}*/}
            {/*  onChange={(e) => {*/}
            {/*    setPassword(e.target.value);*/}
            {/*  }}*/}
            {/*></Input>*/}
          </FormGroup>

          <FormGroup className="mb-3">
            <div className="col text-center">
              <Button
                className="text-center"
                variant="primary"
                value="Submit"
                type="submit"
              >
                Login
              </Button>
            </div>
          </FormGroup>
        </Form>
      </div>
    </Container>
  );
}
