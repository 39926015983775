export let dashObjectModel = {
  male: "0",
  female: "0",
  sevendays: "0",
  yesterday: "0",
  today: "0",
  total: "0",
  single: "0",
  couples: "0",
  dashGraph: "",
  maleweek: "",
  manmonth: "",
  femaleweek: "",
  femalemonth: "",
};
