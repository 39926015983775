// const showFields = [
//     //Show
//     { name: "ideal" },
//     { name: "describe" },
//     { name: "rate" },
//     { name: "dealbreakers" },
//     { name: "date" },
//     { name: "pic" },
//     { name: "video" },
// ];
//

// const profileFields = [
//     //profile
//     { name: "name" },
//     { name: "surname" },
//     { name: "cellphone" },
//     { name: "email" },
//     { name: "twitter" },
//     { name: "facebook" },
//     { name: "instagram" },
//     { name: "gender" },
//     { name: "age" },
//     { name: "city" },
//     { name: "province" },
// ];

export let entrantProfile = {
  uid: "",
  //Profile
  name: "",
  surname: "",
  cellphone: "",
  email: "",
  twitter: "",
  facebook: "",
  instagram: "",
  gender: "",
  age: "",
  city: "",
  province: "",
  fullAddress: "",
};

export let dmfEntry = {
  uid: "",
  // ideal: "",
  // describe: "",
  // rate: "",
  dealbreakers: "",
  date: "",

  signature: "",
  pic: "",
  video: "",
  height: "",
  occupation: "",
  company: "",
  fulladdress: "",
  shootaddress: "",
  shootprovince: "",
  shootcity: "",
  criminalrecord: false,
  crime: "",
  lifestory: "",
  singlewhy: "",
  lastrelationshipdate: "",
  wishtosharewhat: "",
  partner: "",
  schooling: "",
  alive: "",
  difficultexperiance: "",
  peopleyouattract: "",
  commonreasonsbreakup: "",
  dealbreaker: "",
  idealphysic: "",
  employed: false,
  religion: "",
  car: false,
  partnerkids: false,
  partnerkidsnumber: "",
  kids: false,
  kidsnumber: "",
  breakup: "",
  relationshipstatusx: "",
};

export let listColumns = ["name", "surname", "gender", "kids", "religion"];
