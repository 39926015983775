export let entrantProfile = {
  uid: "",
  //Profile
  name: "",
  surname: "",
  cellphone: "",
  email: "",
  twitter: "",
  facebook: "",
  instagram: "",
  gender: "",
  age: 0,
  city: "",
  province: "",
  fullAddress: "",
};

export let pastorEntry = {
  uid: "",

  pic: "",
  video: "",

  dodate: "",
  firstdate: "",
  malebestfriend: "",
  sharingpassword: "",
  lovemarriage: "",
  dealbreaker: "",
  readymarriage: "",
  looksbrains: "",
  friendsx: "",
  spontaneous: "",

  elders: "",
  church: "",
  familyfriends: "",
  introvertextrovert: "",
  misunderstood: "",
  oneperson: "",
  confrontational: "",
};

export let wifeEntry = {
  uid: "",

  pic: "",
  video: "",

  vaccinationstatus: "",
  aboutyourself: "",
  childhoodmemory: "",
  sharemeal: "",
  christianity: "",
  fiveyears: "",

  viewsromantic: "",
  romanticexperience: "",
  skilllearn: "",
  single: "",
  qualities: "",
  warninglabel: "",
  daringlove: "",
};

export let listColumns = [
  "name",
  "surname",
  "gender",
  "date",
  "kids",
  "religion",
  "car",
  "employed",
];
