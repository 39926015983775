import React, { useEffect, useContext } from "react";
import { Badge } from "react-bootstrap";
import logo from "../images/dmf.png";

import { projectFirestore } from "../firebase/config";
//import { useParams } from "react-router-dom";
import AppContextProvider, { AppContext } from "../context/AppContext";
import { useAppContext } from "../hooks/useAppContext";
import {
  CBadge,
  CButton,
  CCallout,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
  CCardTitle,
  CContainer,
} from "@coreui/react";

export function EntriesClosed() {
  //const color = "";
  //const { show } = useParams();
  //let settings = [];
  const { setObject } = useAppContext();
  // useEffect(() => {
  //   //Get Settings
  //   projectFirestore
  //     .collection("Settings")
  //     .doc(show)
  //     .get()
  //     .then((results) => {
  //       if (results.exists) {
  //         console.log({ results });
  //         settings = results.data();
  //         console.log({ settings });
  //
  //         document.body.style.background = settings.color;
  //       } else {
  //         console.log("No Settings");
  //       }
  //     });
  // }, []);

  return (
    <div>
      <img
        style={{ padding: "20px", width: "800px" }}
        // src={"../images/" + show + ".jpeg"}
        src={setObject.showLogo}
        className="img-fluid rounded mx-auto d-block"
        alt=""
      />
      <CContainer>
        <CCard className="text-center">
          <CCardHeader>Entries Closed</CCardHeader>{" "}
          <CCardBody>
            <CCardTitle>{setObject.showName}</CCardTitle>{" "}
            <CCallout color="danger">
              <p>Unfortunately entries are closed.</p>
              <p>Please check when entries will open again</p>
            </CCallout>
            <CCardText>{setObject.showDescription}</CCardText>
          </CCardBody>{" "}
          <CCardFooter className="text-medium-emphasis">
            Entries closed on the {setObject.closeDate}
          </CCardFooter>
        </CCard>
      </CContainer>
    </div>
  );
}
