import React, { createContext, useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSettings } from "../hooks/useFirestore";
import LoadingOverlay from "react-loading-overlay-ts";
export const AppContext = createContext();
const AppContextProvider = (props) => {
  //const [variableOne, setVariableOne] = useState('somethingRandom`)
  let settings = [];
  //const [settingsState, setSettings] = useState([]);
  const [setObject, setSetObject] = useState();
  const system = window.location.host.split(".")[0];
  const Url = window.location.host;
  const { show, cellNumber } = useParams();
  const [isActive, setActive] = useState(true);
  const [isInit, setInit] = useState(true);
  const fetchSettings = useCallback(
    async (show) => {
      if (setObject == undefined) {
        setActive(true);
        settings = await getSettings(show);
        //document.body.style.background = settings.color;
        await setSetObject({ ...settings });
        setActive(false);
        setInit(false);
      }
      console.log(setObject);
    },
    [setObject]
  );

  useEffect(() => {
    fetchSettings(show);
  }, []);

  return (
    <div>
      <LoadingOverlay active={isActive} spinner text="Loading">
        {!isInit && (
          <AppContext.Provider
            value={{
              system,
              Url,
              show,
              cellNumber,
              setObject,
              setActive,
            }}
          >
            {props.children}
          </AppContext.Provider>
        )}
      </LoadingOverlay>
      )}
      {/*{isActive && isInit && (*/}
      {/*  <LoadingOverlay active={isActive} spinner text="">*/}
      {/*    <div>*/}
      {/*      <h1 id={"loading"}>*/}
      {/*        <span className="let1">l</span>*/}
      {/*        <span className="let2">o</span>*/}
      {/*        <span className="let3">a</span>*/}
      {/*        <span className="let4">d</span>*/}
      {/*        <span className="let5">i</span>*/}
      {/*        <span className="let6">n</span>*/}
      {/*        <span className="let7">g</span>*/}
      {/*      </h1>*/}
      {/*    </div>*/}
      {/*  </LoadingOverlay>*/}
      {/*)}*/}
      {/*{isActive && !isInit && (*/}
      {/*  <LoadingOverlay active={isActive} spinner text="">*/}
      {/*    <div>*/}
      {/*      <h1 id={"loading"}>*/}
      {/*        <span className="let1">l</span>*/}
      {/*        <span className="let2">o</span>*/}
      {/*        <span className="let3">a</span>*/}
      {/*        <span className="let4">d</span>*/}
      {/*        <span className="let5">i</span>*/}
      {/*        <span className="let6">n</span>*/}
      {/*        <span className="let7">g</span>*/}
      {/*      </h1>*/}
      {/*    </div>*/}
      {/*    <AppContext.Provider*/}
      {/*      value={{*/}
      {/*        system,*/}
      {/*        Url,*/}
      {/*        show,*/}
      {/*        cellNumber,*/}
      {/*        setObject,*/}
      {/*        setActive,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {props.children}*/}
      {/*    </AppContext.Provider>*/}
      {/*  </LoadingOverlay>*/}
      {/*)}*/}
      {/*{!isActive && (*/}
      {/*  <AppContext.Provider*/}
      {/*    value={{*/}
      {/*      system,*/}
      {/*      Url,*/}
      {/*      show,*/}
      {/*      cellNumber,*/}
      {/*      setObject,*/}
      {/*      setActive,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {props.children}*/}
      {/*  </AppContext.Provider>*/}
      {/*)}*/}
    </div>
  );
};
export default AppContextProvider;
