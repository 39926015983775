import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../stylesheets/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  createDocumentFirestore,
  getEntry,
  getProfile,
  getSettings,
  picUpload,
  sendEntryEmail,
  videoUpload,
} from "../../hooks/useFirestore";
import { Alert, Col, Form, Row } from "react-bootstrap";
import "react-rating";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  Divider,
  InputLabel,
  MenuItem,
  Select,
  FormControl as FormControlM,
  TextField,
  Button,
  Grid,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import { Facebook, Instagram, Twitter } from "react-bootstrap-icons";
import { usePlacesWidget } from "react-google-autocomplete";
import { DateBox, NumberBox } from "devextreme-react";
import { opwEntry, entrantProfile } from "./models";
import { projectStorage } from "../../firebase/config";
import { Delete } from "@mui/icons-material";

export function EntryForm() {
  const { show, cellNumber } = useParams();
  let settings = [];
  let currentProfile = {};
  let currentEntry = {};

  const [isActive, setActive] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuthContext();
  const [exists, setExists] = useState("false");

  const [profile, setProfile] = useState(entrantProfile);
  const [showEntry, setShowEntry] = useState(opwEntry);

  const [setObject, setSetObject] = useState();

  const [showPic, setEntrantPic] = useState("../images/" + show + ".jpeg");
  const [showVid, setEntrantVid] = useState("../images/" + show + ".jpeg");

  const form = useRef();
  const navigate = useNavigate();

  const handleImage = async (event) => {
    setActive(true);

    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const storage = projectStorage;
    const storageRef = storage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);

      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name +
            profile.surname +
            profile.cellphone
        )
        .put(uploadedFile)
        .then(async (response) => {
          response.ref.getDownloadURL().then((link) => {
            showEntry.pic = link;
            setEntrantPic(link);
            console.log(link);
            setActive(false);
          });
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }
  };

  const handleVideo = async (event) => {
    setActive(true);

    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const storage = projectStorage;
    const storageRef = storage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);

      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name +
            profile.surname +
            profile.cellphone
        )
        .put(uploadedFile)
        .then(async (response) => {
          let downloadURL = await response.ref.getDownloadURL();
          showEntry.video = downloadURL;
          setEntrantVid(downloadURL);
          console.log(downloadURL);
          setActive(false);
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }

    setActive(false);
  };

  let mailSend = undefined;

  const sendEmail = async () => {
    try {
      sendEntryEmail(profile, showEntry, exists, setObject).then((result) => {
        console.log(result);
        navigate("/thankyou/" + show);
      });
    } catch (error) {
      console.log(error.text);
    }

    // if (mailSend === undefined) {
    //
    // } else {
    //   if (mailSend.result) {
    //     navigate("/thankyou/" + { show });
    //   } else if (mailSend.error);
    //   {
    //     console.log(mailSend.error.text);
    //   }
    // }
  };

  const handleDelete = () => {
    setOpenClose(true);
  };

  const handleSubmit = async (e) => {
    try {
      createDocumentFirestore(
        e,
        exists,
        profile,
        showEntry,
        settings,
        user,
        show
      );
      sendEmail(profile, showEntry, exists, settings);
      // navigate("/thankyou/" + show);
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchSettings = useCallback(
    async (show) => {
      if (setObject == undefined) {
        settings = await getSettings(show);
        document.body.style.background = settings.color;
        await setSetObject({ ...settings });
      }
      console.log(setObject);
    },
    [setObject]
  );

  const fetchProfile = useCallback(async (userNumber) => {
    currentProfile = await getProfile(userNumber);
    if (currentProfile !== undefined) {
      // setProfile({ ...currentProfile });

      profile.uid = currentProfile.uid;
      profile.name = currentProfile.name;
      profile.surname = currentProfile.surname;
      profile.cellphone = currentProfile.cellphone;
      profile.email = currentProfile.email;
      profile.twitter = currentProfile.twitter;
      profile.facebook = currentProfile.facebook;
      profile.instagram = currentProfile.instagram;
      profile.gender = currentProfile.gender;
      profile.age = currentProfile.age;
      profile.city = currentProfile.city;
      profile.province = currentProfile.province;
      profile.fullAddress = currentProfile.fullAddress;

      console.log(profile);
      setActive(false);
    } else {
      setActive(false);
    }
  }, []);

  const { ref: addRef } = usePlacesWidget({
    apiKey: "AIzaSyBEEUwF_2FTgUM6E7Dku28uJctPimqWCMw",
    onPlaceSelected: (place) => {
      console.log(place);
      setProfile({
        ...profile,
        fullAddress: place.formatted_address,
        city: place.address_components[3].long_name,
        province: place.address_components[5].long_name,
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  const { ref: addBrideRef } = usePlacesWidget({
    apiKey: "AIzaSyBEEUwF_2FTgUM6E7Dku28uJctPimqWCMw",
    onPlaceSelected: (place) => {
      console.log(place);
      setShowEntry({ ...showEntry, brideaddress: place.formatted_address });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  const { ref: addGroomRef } = usePlacesWidget({
    apiKey: "AIzaSyBEEUwF_2FTgUM6E7Dku28uJctPimqWCMw",
    onPlaceSelected: (place) => {
      console.log(place);
      setShowEntry({ ...showEntry, groomaddress: place.formatted_address });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  const fetchEntry = async (show, userNumber) => {
    currentEntry = await getEntry(show, userNumber);
    if (currentEntry !== undefined) {
      // setShowEntry({ ...currentEntry });

      showEntry.bridename = currentEntry.bridename;
      showEntry.bridearea = currentEntry.bridearea;
      showEntry.brideage = currentEntry.brideage;
      showEntry.brideaddress = currentEntry.brideaddress;
      showEntry.bridecontact = currentEntry.bridecontact;
      showEntry.bridehomelanguage = currentEntry.bridehomelanguage;
      showEntry.bridechildren = currentEntry.bridechildren;
      showEntry.bridechildrenage = currentEntry.bridechildrenage;
      showEntry.groomname = currentEntry.groomname;
      showEntry.groomarea = currentEntry.groomarea;
      showEntry.groomage = currentEntry.groomage;
      showEntry.groomaddress = currentEntry.groomaddress;
      showEntry.groomhomelanguage = currentEntry.groomhomelanguage;
      showEntry.groomchildren = currentEntry.groomchildren;
      showEntry.groomchildrenage = currentEntry.groomchildrenage;
      showEntry.groomcontact = currentEntry.groomcontact;
      showEntry.weddingtype = currentEntry.weddingtype;
      showEntry.themecolours = currentEntry.themecolours;
      showEntry.weddingdate = currentEntry.weddingdate;
      showEntry.weddingvanue = currentEntry.weddingvanue;
      showEntry.wishlist = currentEntry.wishlist;
      showEntry.meet = currentEntry.meet;
      showEntry.challenges = currentEntry.challenges;
      showEntry.proposaldate = currentEntry.proposaldate;
      showEntry.proposal = currentEntry.proposal;
      showEntry.day = currentEntry.day;
      showEntry.partner = currentEntry.partner;
      showEntry.whyopw = currentEntry.whyopw;
      showEntry.bridesmaids = currentEntry.bridesmaids;
      showEntry.groomsmen = currentEntry.groomsmen;
      showEntry.choicesbridesmaids = currentEntry.choicesbridesmaids;
      showEntry.choicesgroomsmen = currentEntry.choicesgroomsmen;

      // showEntry.video = currentEntry.video;
      // showEntry.pic = currentEntry.pic;

      setEntrantPic(currentEntry.pic);
      setEntrantVid(currentEntry.video);

      if (currentEntry.video) {
        showEntry.video = currentEntry.video;
      }

      if (currentEntry.pic) {
        showEntry.pic = currentEntry.pic;
      }

      console.log(showEntry);
      setExists(true);
    }
  };

  useEffect(() => {
    //console.log(user);

    setActive(true);
    fetchSettings(show);
    fetchProfile(cellNumber);
    fetchEntry(show, cellNumber);
  }, []);

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Busy Processing, Please Wait..."
    >
      <div
        className="testbox"
        // style={{ "box-shadow": "0 20px 0 #a82877" }}
        style={{ margin: "2%" }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        <fieldset disabled={false}>
          <Form
            ref={form}
            id="enterDMF"
            name="theform"
            onSubmit={handleSubmit}
            disabled
            // style={{ "box-shadow": "0 20px 0 #a82877" }}
          >
            {/* Names */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="ename"
                  label="Name"
                  variant="outlined"
                  value={profile.name}
                  onChange={(e) => {
                    // profile.name = e.target.value;
                    setProfile({ ...profile, name: e.target.value });
                  }}
                  className={"form-control"}
                />
              </Col>
              <Col>
                <TextField
                  id="esurname"
                  label="Surname"
                  variant="outlined"
                  value={profile.surname}
                  onChange={(e) => {
                    setProfile({ ...profile, surname: e.target.value });
                  }}
                  className={"form-control"}
                />
              </Col>
            </Row>
            {/*Contacts*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="ecellphone"
                  label="Cellphone"
                  variant="outlined"
                  className={"form-control"}
                  value={user.phoneNumber}
                  onChange={(e) => {
                    setProfile({ ...profile, cellphone: e.target.value });
                  }}
                  disabled
                />
              </Col>
              <Col>
                <TextField
                  id="email"
                  label="E-Mail"
                  variant="outlined"
                  className={"form-control"}
                  value={profile.email}
                  onChange={(e) => {
                    setProfile({ ...profile, email: e.target.value });
                  }}
                />
              </Col>
            </Row>
            {/*Age and gender*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <NumberBox
                  id="age"
                  min={18}
                  showSpinButtons={true}
                  label="Age"
                  placeholder="Enter your age"
                  variant="outlined"
                  className={"form-control"}
                  value={profile.age}
                  onValueChanged={(e) => {
                    setProfile({ ...profile, age: e.value });
                  }}
                />
              </Col>
              <Col>
                <FormControlM className={"form-control"}>
                  <InputLabel id="genderSelect">Gender</InputLabel>
                  <Select
                    className={"form-control"}
                    labelId="genderSelect"
                    label="Gender"
                    value={profile.gender}
                    onChange={(e) => {
                      setProfile({ ...profile, gender: e.target.value });
                    }}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControlM>
              </Col>
            </Row>
            {/*Social Media*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="twitter"
                  label="Twitter"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Twitter />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={profile.twitter}
                  onChange={(e) => {
                    setProfile({ ...profile, twitter: e.target.value });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="instagram"
                  label="Instagram"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Instagram />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={profile.instagram}
                  onChange={(e) => {
                    setProfile({ ...profile, instagram: e.target.value });
                  }}
                />
              </Col>

              <Col>
                <TextField
                  id="facebook"
                  label="Facebook"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Facebook />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={profile.facebook}
                  onChange={(e) => {
                    setProfile({ ...profile, facebook: e.target.value });
                  }}
                />
              </Col>
            </Row>
            {/*Address*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <FormControlM variant="outlined" className={"form-control"}>
                  <TextField
                    inputRef={addRef}
                    label="Full Address"
                    variant="outlined"
                    className={"form-control"}
                    value={profile.fullAddress}
                    onChange={(e) => {
                      setProfile({ ...profile, fullAddress: e.target.value });
                    }}
                  />
                </FormControlM>
              </Col>
            </Row>
            {/*<Row style={{ margin: "2%" }}>*/}
            {/*  <Col>*/}
            {/*    <TextField*/}
            {/*      id="ecity"*/}
            {/*      label="City"*/}
            {/*      variant="outlined"*/}
            {/*      className={"form-control"}*/}
            {/*      value={profile.city}*/}
            {/*      onChange={(e) => {*/}
            {/*        setProfile({ ...profile, city: e.target.value });*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*  <Col>*/}
            {/*    <TextField*/}
            {/*      id="eprovince"*/}
            {/*      label="Province"*/}
            {/*      variant="outlined"*/}
            {/*      className={"form-control"}*/}
            {/*      value={profile.province}*/}
            {/*      onChange={(e) => {*/}
            {/*        setProfile({ ...profile, province: e.target.value });*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <Divider variant="fullWidth">
              {" "}
              <Chip label="Wedding Details" />
            </Divider>
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="bname"
                  label="Bride Name"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridename}
                  onChange={(e) => {
                    setShowEntry({ ...showEntry, bridename: e.target.value });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="eprovince"
                  label="Groom Name"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomname}
                  onChange={(e) => {
                    setShowEntry({ ...showEntry, groomname: e.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="bname"
                  label="Bride Area"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridearea}
                  onChange={(e) => {
                    setShowEntry({ ...showEntry, bridearea: e.target.value });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="barea"
                  label="Groom Area"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomarea}
                  onChange={(e) => {
                    setShowEntry({ ...showEntry, groomarea: e.target.value });
                  }}
                />
              </Col>
            </Row>
            {/*age*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                {" "}
                <NumberBox
                  id="bage"
                  min={18}
                  showSpinButtons={true}
                  label="Bride Age"
                  placeholder="Enter Bride age"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.brideage}
                  onValueChanged={(e) => {
                    setShowEntry({ ...showEntry, brideage: e.value });
                  }}
                />{" "}
              </Col>
              <Col>
                {" "}
                <NumberBox
                  id="gage"
                  min={18}
                  showSpinButtons={true}
                  label="Groom Age"
                  placeholder="Enter Groom age"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomage}
                  onValueChanged={(e) => {
                    setShowEntry({ ...showEntry, groomage: e.value });
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  ref={addBrideRef}
                  id="baddress"
                  label="Bride Address"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.brideaddress}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      brideaddress: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="gaddress"
                  ref={addGroomRef}
                  label="Groom Address"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomaddress}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      groomaddress: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="bcontact"
                  label="Bride Contact Number"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridecontact}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      bridecontact: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="gcontact"
                  label="Groom Contact Number"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomcontact}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      groomcontact: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            {/*  home language */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="Blang"
                  label="Bride Home Language"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridehomelanguage}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      bridehomelanguage: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="gland"
                  label="Groom Home Language"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomhomelanguage}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      groomhomelanguage: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            {/*Theme Colours and Wedding Type*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="tcolors"
                  label="Theme Colours"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.themecolours}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      themecolours: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <FormControlM className={"form-control"}>
                  <InputLabel id="edateSelect">Wedding Type</InputLabel>
                  <Select
                    className={"form-control"}
                    labelId="wtselect"
                    label="Wedding Type"
                    value={showEntry.weddingtype}
                    defaultValue={"Other"}
                    onChange={(e) => {
                      setShowEntry({
                        ...showEntry,
                        weddingtype: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Traditional">Traditional</MenuItem>
                    <MenuItem value="White">White</MenuItem>
                    <MenuItem value="Traditional & White">
                      Traditional & White
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControlM>
              </Col>
            </Row>
            {/* Wedding date & vanue */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <InputLabel id="Wedding Date">Wedding Date</InputLabel>
                <input
                  value={showEntry.weddingdate}
                  variant="outlined"
                  className="form-control"
                  type="date"
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      weddingdate: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="vanue"
                  label="Vanue"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.weddingvanue}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      weddingvanue: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            {/* Children */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="bchildren"
                  label="Bride's Children"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridechildren}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      bridechildren: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="gchildren"
                  label="Groom's Children"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomchildren}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      groomchildren: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            {/*  children age*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="bchildrenage"
                  label="Grooms's Children Age"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridechildrenage}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      bridechildrenage: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="gchildrenage"
                  label="Groom's Children's Age"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomchildrenage}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      groomchildrenage: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Your wish list?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.wishlist}
                onChange={(e) => {
                  setShowEntry({ ...showEntry, wishlist: e.target.value });
                }}
              ></TextField>
            </Row>
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={5}
                placeholder="How did you meet? We would like the whole story, how you met, when you met, where you met, what was going on in your minds when it all happened, the WHOLE story."
                label="How did you meet??"
                className={"form-control"}
                variant="outlined"
                value={showEntry.meet}
                onChange={(e) => {
                  setShowEntry({ ...showEntry, meet: e.target.value });
                }}
              ></TextField>
            </Row>
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={5}
                label="Challenges"
                placeholder="What challenges have you guys had to go through in your relationship?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.challenges}
                onChange={(e) => {
                  setShowEntry({ ...showEntry, challenges: e.target.value });
                }}
              ></TextField>
            </Row>

            {/* Proposal & date */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <InputLabel id="shoolingSelect">
                  When did he ask you to marry him?
                </InputLabel>
                <input
                  value={showEntry.proposaldate}
                  variant="outlined"
                  className="form-control"
                  type="date"
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      proposaldate: e.target.value,
                    });
                  }}
                />
              </Col>

              <Col>
                <TextField
                  multiline
                  rows={4}
                  label="The Proposal"
                  placeholder="Tell us what happened at that moment? (the proposal)"
                  className={"form-control"}
                  variant="outlined"
                  value={showEntry.proposal}
                  onChange={(e) => {
                    setShowEntry({ ...showEntry, proposal: e.target.value });
                  }}
                ></TextField>
              </Col>
            </Row>
            {/*Why that day? */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="day"
                  label="What is behind that day?"
                  variant="outlined"
                  placeholder="What is behind choosing the day you getting married on?"
                  className={"form-control"}
                  value={showEntry.day}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      day: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            {/*Why him? */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="partner"
                  label="Why him?"
                  variant="outlined"
                  placeholder="Why are you choosing to marry your partner?"
                  className={"form-control"}
                  value={showEntry.partner}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      partner: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            {/*Why OPW? */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="whyopw"
                  label="Why do want Our Perfect Wedding to come?"
                  variant="outlined"
                  placeholder="Why do you want Our Perfect Wedding to come to your wedding?"
                  className={"form-control"}
                  value={showEntry.whyopw}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      whyopw: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            {/*How many maids and men*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                {" "}
                <NumberBox
                  id="gmen"
                  showSpinButtons={true}
                  label="Groomsmen"
                  placeholder="How many groomsmen do you have"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.groomsmen}
                  onValueChanged={(e) => {
                    setShowEntry({ ...showEntry, groomsmen: e.value });
                  }}
                />{" "}
              </Col>
              <Col>
                {" "}
                <TextField
                  id="gchoices"
                  label="Groomsmen choices?"
                  placeholder="How did you make your choices?"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.choicesgroomsmen}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      choicesgroomsmen: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            {/*How many maids and men*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                {" "}
                <NumberBox
                  id="maids"
                  showSpinButtons={true}
                  label="Bridesmaids"
                  placeholder="How many bridesmaids do you have"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.bridesmaids}
                  onValueChanged={(e) => {
                    setShowEntry({ ...showEntry, bridesmaids: e.value });
                  }}
                />{" "}
              </Col>
              <Col>
                {" "}
                <TextField
                  id="maidschoices"
                  label="Bridesmaids choices?"
                  placeholder="How did you make your choices?"
                  variant="outlined"
                  className={"form-control"}
                  value={showEntry.choicesbridesmaids}
                  onChange={(e) => {
                    setShowEntry({
                      ...showEntry,
                      choicesbridesmaids: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2%" }}>
              <Col>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Upload a pic of you together</Form.Label>
                  <Form.Control
                    className={"form-control"}
                    type="file"
                    onChange={handleImage}
                    accept="image/*"
                  />
                </Form.Group>
              </Col>
              <Col>
                <img
                  style={{ padding: "20px" }}
                  src={showPic}
                  className="img-fluid rounded mx-auto d-block"
                  alt=""
                />
              </Col>
            </Row>
            <Row style={{ margin: "2%" }}>
              <Col>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    Upload a 30 sec video introducing yourselves
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleVideo}
                    accept="video/*"
                  />
                </Form.Group>
              </Col>
              <Col>
                <video
                  style={{ padding: "20px" }}
                  src={showVid}
                  className="img-fluid rounded mx-auto d-block"
                  alt=""
                  controls
                />
              </Col>
            </Row>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                startIcon={<Delete />}
                style={{ margin: "2%" }}
                variant="outlined"
                color="error"
                size="large"
                onClick={handleDelete}
              >
                Delete
              </Button>
              <Button
                style={{ margin: "2%" }}
                variant="contained"
                value="Submit"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Form>
        </fieldset>
      </div>
    </LoadingOverlay>
  );
}
