import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { RecaptchaVerifier } from "firebase/auth";

const firebaseApp = firebase.initializeApp({
  // apiKey: "AIzaSyDMfbO7l3J3b3GRShuPtFndQjtXh5Sxx_g",
  // authDomain: "entrywiz-connect.firebaseapp.com",
  // projectId: "entrywiz-connect",
  // storageBucket: "entrywiz-connect.appspot.com",
  // messagingSenderId: "74066517516",
  // appId: "1:74066517516:web:626e05b45c985024ee1829",
  // measurementId: "G-1V7S8R43DE",

  apiKey: "AIzaSyAe3hFOUMAWvtPsUdKkYeofkg_Gscn5pUA",
  authDomain: "brightfire-4ab62.firebaseapp.com",
  projectId: "brightfire-4ab62",
  storageBucket: "brightfire-4ab62.appspot.com",
  messagingSenderId: "231836030299",
  appId: "1:231836030299:web:7261ec799892a16b62f67e",
  measurementId: "G-CLDF3G0M5J",

  // credential: credential.cert(serviceAccount),
});

// const appCheck = firebase.appCheck();
// // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// // key is the counterpart to the secret key you set in the Firebase console.
// appCheck.activate(
//   "6LcPFwkkAAAAAPHxl72tpXccG640cTp-RZrWRxz2",
//
//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   true
// );
//Services
const projectFirestore = firebase.firestore();
const projectStorage = firebase.storage();
const projectAuth = firebase.auth();
const recaptchaVerifierCreate = firebase.auth.RecaptchaVerifier;
//const loggedInUser = firebase.auth().currentUser;

//timestamp
const timestamp = firebase.firestore.Timestamp;

export {
  projectFirestore,
  projectAuth,
  recaptchaVerifierCreate,
  timestamp,
  projectStorage,
};
