import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../stylesheets/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  createDocumentFirestore,
  getEntry,
  getProfile,
  getSettings,
  picUpload,
  sendEntryEmail,
  videoUpload,
} from "../../hooks/useFirestore";
import { Alert, Col, Form, Row } from "react-bootstrap";
import "react-rating";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  Divider,
  InputLabel,
  MenuItem,
  Select,
  FormControl as FormControlM,
  TextField,
  Button,
  Grid,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import { Facebook, Instagram, Twitter } from "react-bootstrap-icons";
import { usePlacesWidget } from "react-google-autocomplete";

import { DateBox, NumberBox } from "devextreme-react";
import { entrantProfile, wifeEntry } from "./models";
import { projectStorage } from "../../firebase/config";
import { Delete } from "@mui/icons-material";

export function EntryForm() {
  const { show, cellNumber } = useParams();
  let settings = [];
  let currentProfile = {};
  let currentEntry = {};

  const [isActive, setActive] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuthContext();
  const [exists, setExists] = useState("false");

  const [profile, setProfile] = useState(entrantProfile);
  const [showEntry, setShowEntry] = useState(wifeEntry);

  const [setObject, setSetObject] = useState();

  const [showPic, setEntrantPic] = useState("../images/" + show + ".jpeg");
  const [showVid, setEntrantVid] = useState("../images/" + show + ".jpeg");

  const form = useRef();
  const navigate = useNavigate();

  const handleImage = async (event) => {
    setActive(true);

    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const storage = projectStorage;
    const storageRef = storage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);

      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name +
            profile.surname +
            profile.cellphone
        )
        .put(uploadedFile)
        .then(async (response) => {
          response.ref.getDownloadURL().then((link) => {
            showEntry.pic = link;
            setEntrantPic(link);
            console.log(link);
            setActive(false);
          });
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }
  };

  const handleVideo = async (event) => {
    setActive(true);

    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const storage = projectStorage;
    const storageRef = storage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);

      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name +
            profile.surname +
            profile.cellphone
        )
        .put(uploadedFile)
        .then(async (response) => {
          let downloadURL = await response.ref.getDownloadURL();
          showEntry.video = downloadURL;
          setEntrantVid(downloadURL);
          console.log(downloadURL);
          setActive(false);
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }

    setActive(false);
  };

  const sendEmail = async () => {
    try {
      sendEntryEmail(profile, showEntry, exists, setObject).then((result) => {
        console.log(result);
        navigate("/thankyou/" + show);
      });
    } catch (error) {
      console.log(error.text);
    }
  };

  const handleDelete = () => {
    setOpenClose(true);
  };

  const handleSubmit = (e) => {
    try {
      createDocumentFirestore(
        e,
        exists,
        profile,
        showEntry,
        settings,
        user,
        show
      );
      sendEmail(profile, showEntry, exists, settings);
      navigate("/thankyou/" + show);
    } catch (err) {
      console.log(err.message);
    }
  };

  // const fetchSettings = useCallback(async (show) => {
  //   settings = await getSettings(show);
  //   document.body.style.background = settings.color;
  // }, []);

  const fetchSettings = useCallback(
    async (show) => {
      if (setObject == undefined) {
        settings = await getSettings(show);
        document.body.style.background = settings.color;
        await setSetObject({ ...settings });
      }
      console.log(setObject);
    },
    [setObject]
  );

  const fetchProfile = useCallback(async (userNumber) => {
    currentProfile = await getProfile(userNumber);
    if (currentProfile !== undefined) {
      // setProfile({ ...currentProfile });

      profile.uid = currentProfile.uid;
      profile.name = currentProfile.name;
      profile.surname = currentProfile.surname;
      profile.cellphone = currentProfile.cellphone;
      profile.email = currentProfile.email;
      profile.twitter = currentProfile.twitter;
      profile.facebook = currentProfile.facebook;
      profile.instagram = currentProfile.instagram;
      profile.gender = currentProfile.gender;
      profile.age = currentProfile.age;
      profile.city = currentProfile.city;
      profile.province = currentProfile.province;
      profile.fullAddress = currentProfile.fullAddress;

      console.log(profile);
      setActive(false);
    } else {
      setActive(false);
    }
  }, []);

  const fetchEntry = async (show, userNumber) => {
    currentEntry = await getEntry(show, userNumber);
    if (currentEntry !== undefined) {
      // setShowEntry({ ...currentEntry });

      showEntry.uid = currentEntry.uid;

      showEntry.vaccinationstatus = currentEntry.vaccinationstatus;
      showEntry.aboutyourself = currentEntry.aboutyourself;
      showEntry.childhoodmemory = currentEntry.childhoodmemory;
      showEntry.sharemeal = currentEntry.sharemeal;
      showEntry.christianity = currentEntry.christianity;
      showEntry.fiveyears = currentEntry.fiveyears;
      showEntry.viewsromantic = currentEntry.viewsromantic;
      showEntry.romanticexperience = currentEntry.romanticexperience;
      showEntry.skilllearn = currentEntry.skilllearn;
      showEntry.single = currentEntry.single;
      showEntry.qualities = currentEntry.qualities;
      showEntry.warninglabel = currentEntry.warninglabel;
      showEntry.daringlove = currentEntry.daringlove;

      // showEntry.video = currentEntry.video;
      // showEntry.pic = currentEntry.pic;
      setEntrantPic(currentEntry.pic);
      setEntrantVid(currentEntry.video);

      if (currentEntry.video) {
        showEntry.video = currentEntry.video;
      }

      if (currentEntry.pic) {
        showEntry.pic = currentEntry.pic;
      }

      console.log(showEntry);
      setExists(true);
    }
  };

  useEffect(() => {
    //console.log(user);
    setActive(true);
    fetchSettings(show);
    fetchProfile(cellNumber);
    fetchEntry(show, cellNumber);
  }, []);

  const { ref: addRef } = usePlacesWidget({
    apiKey: "AIzaSyBEEUwF_2FTgUM6E7Dku28uJctPimqWCMw",
    onPlaceSelected: (place) => {
      console.log(place);

      setProfile({
        ...profile,
        fullAddress: place.formatted_address,
        city: place.address_components[3].long_name,
        province: place.address_components[5].long_name,
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  const { ref: shootAddRef } = usePlacesWidget({
    apiKey: "AIzaSyBEEUwF_2FTgUM6E7Dku28uJctPimqWCMw",
    onPlaceSelected: (place) => {
      console.log(place);
      setShowEntry({
        ...showEntry,
        shootaddress: place.formatted_address,
        shootcity: place.address_components[3].long_name,
        shootprovince: place.address_components[5].long_name,
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Busy Processing, Please Wait..."
    >
      <div
        className="testbox"
        // style={{ "box-shadow": "0 20px 0 #a82877" }}
        style={{ margin: "2%" }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        <fieldset disabled={false}>
          <Form
            ref={form}
            id="enterDMF"
            name="theform"
            onSubmit={handleSubmit}
            disabled
            // style={{ "box-shadow": "0 20px 0 #a82877" }}
          >
            {/* Names */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="ename"
                  label="Name"
                  variant="outlined"
                  value={profile.name}
                  onChange={(e) => {
                    // profile.name = e.target.value;
                    setProfile({ ...profile, name: e.target.value });
                  }}
                  className={"form-control"}
                />
              </Col>
              <Col>
                <TextField
                  id="esurname"
                  label="Surname"
                  variant="outlined"
                  value={profile.surname}
                  onChange={(e) => {
                    setProfile({ ...profile, surname: e.target.value });
                  }}
                  className={"form-control"}
                />
              </Col>
            </Row>
            {/*Contacts*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="ecellphone"
                  label="Cellphone"
                  variant="outlined"
                  className={"form-control"}
                  value={user.phoneNumber}
                  onChange={(e) => {
                    setProfile({ ...profile, cellphone: e.target.value });
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="email"
                  label="E-Mail"
                  variant="outlined"
                  className={"form-control"}
                  value={profile.email}
                  onChange={(e) => {
                    setProfile({ ...profile, email: e.target.value });
                  }}
                />
              </Col>
            </Row>
            {/*Age and gender*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <NumberBox
                  id="age"
                  min={18}
                  showSpinButtons={true}
                  label="Age"
                  placeholder="Enter your age"
                  variant="outlined"
                  className={"form-control"}
                  value={profile.age}
                  onValueChanged={(e) => {
                    setProfile({ ...profile, age: e.value });
                  }}
                />
              </Col>
              <Col>
                <FormControlM className={"form-control"}>
                  <InputLabel id="genderSelect">Gender</InputLabel>
                  <Select
                    className={"form-control"}
                    labelId="genderSelect"
                    label="Gender"
                    value={profile.gender}
                    onChange={(e) => {
                      setProfile({ ...profile, gender: e.target.value });
                    }}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControlM>
              </Col>
            </Row>
            {/*Social Media*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="twitter"
                  label="Twitter"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Twitter />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={profile.twitter}
                  onChange={(e) => {
                    setProfile({ ...profile, twitter: e.target.value });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="instagram"
                  label="Instagram"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Instagram />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={profile.instagram}
                  onChange={(e) => {
                    setProfile({ ...profile, instagram: e.target.value });
                  }}
                />
              </Col>

              <Col>
                <TextField
                  id="facebook"
                  label="Facebook"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Facebook />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={profile.facebook}
                  onChange={(e) => {
                    setProfile({ ...profile, facebook: e.target.value });
                  }}
                />
              </Col>
            </Row>
            {/*Address*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <FormControlM variant="outlined" className={"form-control"}>
                  <TextField
                    inputRef={addRef}
                    label="Full Address"
                    variant="outlined"
                    className={"form-control"}
                    value={profile.fullAddress}
                    onChange={(e) => {
                      setProfile({ ...profile, fullAddress: e.target.value });
                    }}
                  />
                </FormControlM>
              </Col>
            </Row>
            {/*<Row style={{ margin: "2%" }}>*/}
            {/*  <Col>*/}
            {/*    <TextField*/}
            {/*      readonly*/}
            {/*      id="ecity"*/}
            {/*      label="City"*/}
            {/*      variant="outlined"*/}
            {/*      className={"form-control"}*/}
            {/*      value={profile.city}*/}
            {/*      onChange={(e) => {*/}
            {/*        setProfile({ ...profile, city: e.target.value });*/}
            {/*      }}*/}
            {/*      InputProps={{*/}
            {/*        readOnly: true,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*  <Col>*/}
            {/*    <TextField*/}
            {/*      id="eprovince"*/}
            {/*      label="Province"*/}
            {/*      variant="outlined"*/}
            {/*      className={"form-control"}*/}
            {/*      value={profile.province}*/}
            {/*      onChange={(e) => {*/}
            {/*        setProfile({ ...profile, province: e.target.value });*/}
            {/*      }}*/}
            {/*      InputProps={{*/}
            {/*        readOnly: true,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*</Row>*/}

            <Divider variant="fullWidth">
              {" "}
              <Chip label="Pastor Wants A Wife" />
            </Divider>
            <Row>
              <Col>
                <InputLabel id="genderSelect">Vaccination Status</InputLabel>
                <Select
                  className={"form-control"}
                  labelId="genderSelect"
                  label="Gender"
                  value={showEntry.vaccinationstatus}
                  onChange={(e) => {
                    setShowEntry({
                      ...profile,
                      vaccinationstatus: e.target.value,
                    });
                  }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </Col>
            </Row>

            {/* Tell Us About Yourself */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Tell Us About Yourself?"
                placeholder="Who are you, tell us about yourself? How would your friends describe you?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.aboutyourself}
                onChange={(e) => {
                  setShowEntry({ ...showEntry, aboutyourself: e.target.value });
                }}
              ></TextField>
            </Row>

            {/* Favourite childhood memory? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Favourite childhood memory?"
                placeholder="What is your favourite childhood memory?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.childhoodmemory}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    childhoodmemory: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* If you could share a meal with any four individuals? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="If you could share a meal with any four individuals?"
                placeholder="If you could share a meal with any four individuals, living or dead, who would they be?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.sharemeal}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    sharemeal: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* What is your relationship with Christianity */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="What is your relationship with Christianity?"
                placeholder="What is your relationship with Christianity- what lead you to the path of leadership?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.christianity}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    christianity: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* Where do you see yourself in five years? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Where do you see yourself in five years?"
                placeholder="Where do you see yourself in five years?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.fiveyears}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    fiveyears: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            <Divider variant="fullWidth">
              {" "}
              <Chip label="Relationship History" />
            </Divider>

            {/* View on relationships? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Your view on romantic relationships/marriage?"
                placeholder="What’s your view on romantic relationships/marriage?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.viewsromantic}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    viewsromantic: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* View on relationships? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Experience in romantic relationships?"
                placeholder="What has been your experience in romantic relationships?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.romanticexperience}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    romanticexperience: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* What’s Something You Want to Learn or Wish You Were Better At? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="What’s Something You Want to Learn?"
                placeholder="What’s Something You Want to Learn or Wish You Were Better At?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.skilllearn}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    skilllearn: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* In your opinion, what is the reason you are single and looking?  */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Reason you are single and looking?"
                placeholder="In your opinion, what is the reason you are single and looking?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.singlewhy}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    singlewhy: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* What qualities are you looking for in a partner that would complement some of your qualities. */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="What qualities are you looking for in a partner?"
                placeholder="What qualities are you looking for in a partner that would complement some of your qualities?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.qualities}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    qualities: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* If you had a warning label, what would yours say?  */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="If you had a warning label, what would yours say?"
                placeholder="If you had a warning label, what would yours say?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.warninglabel}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    warninglabel: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* What's the most daring thing you’ve done in the name of love?  */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="What's the most daring thing you’ve done in the name of love?"
                placeholder="What's the most daring thing you’ve done in the name of love?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.daringlove}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    daringlove: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Upload a pic of yourself</Form.Label>
                  <Form.Control
                    className={"form-control"}
                    type="file"
                    onChange={handleImage}
                    accept="image/*"
                  />
                </Form.Group>
              </Col>
              <Col>
                <img
                  style={{ padding: "20px" }}
                  src={showPic}
                  className="img-fluid rounded mx-auto d-block"
                  alt=""
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    Upload a 30 sec video introducing yourself
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleVideo}
                    accept="video/*"
                  />
                </Form.Group>
              </Col>
              <Col>
                <video
                  style={{ padding: "20px" }}
                  src={showVid}
                  className="img-fluid rounded mx-auto d-block"
                  alt=""
                  controls
                />
              </Col>
            </Row>

            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                startIcon={<Delete />}
                style={{ margin: "2%" }}
                variant="outlined"
                color="error"
                size="large"
                onClick={handleDelete}
              >
                Delete
              </Button>
              <Button
                style={{ margin: "2%" }}
                variant="contained"
                value="Submit"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Form>
        </fieldset>
      </div>
    </LoadingOverlay>
  );
}
