import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import SignatureCanvas from "react-signature-canvas";
import "../../stylesheets/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore, getEntry, getProfile } from "../../hooks/useFirestore";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import "react-rating";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
} from "react-component-export-image";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  Checkbox,
  FormGroup,
  Box,
  FormControlLabel,
  ToggleButton,
  Input,
} from "@mui/material";
import { Facebook, Instagram, Twitter } from "react-bootstrap-icons";
import { dmfEntry, entrantProfile } from "./models";
import { projectStorage } from "../../firebase/config";
import DeleteDialog from "../alerts";
import { useAppContext } from "../../hooks/useAppContext";
import { useAddressAuto } from "../../hooks/useAddressAutoComplete";
import { useMediaUpload } from "../../hooks/useMediaUpload";
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CFormCheck,
  CRow,
} from "@coreui/react";
import { useMailServer } from "../../hooks/useMailServer";

export function EntryForm() {
  let currentProfile = {};
  let currentEntry = {};

  const { show, cellNumber, setObject, settings } = useAppContext();
  const { createDocumentFirestore } = useFirestore();
  const [isActive, setActive] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [error, setError] = useState("");
  const [terms, setTerms] = useState({
    checked: false,
  });
  const { user } = useAuthContext();
  const [exists, setExists] = useState("false");
  const [profile, setProfile] = useState(entrantProfile);
  const [showEntry, setShowEntry] = useState(dmfEntry);

  const [userPic, setUserPic] = useState();
  const [userPic2, setUserPic2] = useState();
  const [userPic3, setUserPic3] = useState();
  const [showVid, setEntrantVid] = useState(setObject.showLogo);
  const form = useRef();
  const navigate = useNavigate();

  useAddressAuto();
  const { processEmail } = useMailServer();
  const [activeKey, setActiveKey] = useState(1);
  const sigCanvas = useRef();
  const [signatureURL, setSignatureURL] = useState(null);
  // const { step, Buttons, formValues } = NavButtons();
  const formRef = useRef();
  //Navigation for form
  const [step, setStep] = useState({
    activeStep: 1,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      createDocumentFirestore(
        e,
        exists,
        profile,
        showEntry,
        settings,
        user,
        show
      );
      // sendEmail(profile, showEntry, exists, settings);

      // processEmail(profile, showEntry, exists);
      // navigate("/thankyou/" + show);
      // window.scrollTo(0, 0);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleChangeEntry = (e) => {
    const { name, value } = e.target;
    setShowEntry({
      ...showEntry,
      [name]: {
        ...showEntry[name],
        value,
        error: false,
      },
    });
  };

  const handleChangeProfile = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: {
        ...profile[name],
        value,
        error: false,
      },
    });
  };

  const handleToList = () => {
    navigate("/" + show + "/list/" + show);
  };

  const handleDelete = () => {
    setOpenClose(true);
  };

  const fetchProfile = useCallback(async (userNumber) => {
    currentProfile = await getProfile(userNumber);
    if (currentProfile !== undefined) {
      // setProfile({ ...currentProfile });

      profile.uid = currentProfile.uid;
      profile.name.value = currentProfile.name.value;
      profile.surname.value = currentProfile.surname.value;
      profile.cellphone.value = currentProfile.cellphone.value;
      profile.email.value = currentProfile.email.value;
      profile.gender.value = currentProfile.gender.value;
      profile.age.value = currentProfile.age.value;
      // profile.city.value = currentProfile.city;
      // profile.province.value = currentProfile.province;
      profile.fullAddress.value = currentProfile.fullAddress.value;
      profile.DOB.value = currentProfile.DOB.value;
      profile.IDNo.value = currentProfile.IDNo.value;
      profile.SexualOrientation.value = currentProfile.SexualOrientation.value;
      profile.SexualPreference.value = currentProfile.SexualPreference.value;

      if (
        profile.cellphone.value === "" ||
        profile.cellphone.value === undefined
      ) {
        profile.cellphone.value = cellNumber;
      }

      console.log(profile);
      setActive(false);
    } else {
      setActive(false);
    }
  }, []);

  const fetchEntry = async (show, userNumber) => {
    currentEntry = await getEntry(show, userNumber);
    if (currentEntry !== undefined) {
      // setShowEntry({ ...currentEntry });

      showEntry.uid = currentEntry.uid;
      showEntry.dealbreakers.value = currentEntry.dealbreakers.value;
      showEntry.occupation.value = currentEntry.occupation.value;

      showEntry.describeYourself.value = currentEntry.describeYourself.value;
      showEntry.relationshipStatus.value =
        currentEntry.relationshipStatus.value;
      if (currentEntry.singlewhy != undefined) {
        showEntry.singlewhy.value = currentEntry.singlewhy.value;
      }
      showEntry.relationshipsInterestedIn.value =
        currentEntry.relationshipsInterestedIn.value;
      showEntry.idealMatch.value = currentEntry.idealMatch.value;
      showEntry.qualitiesMatch.value = currentEntry.qualitiesMatch.value;

      showEntry.signature = currentEntry.signature.value;
      showEntry.pic = currentEntry.pic;
      showEntry.pic2 = currentEntry.pic2;
      showEntry.pic3 = currentEntry.pic3;
      showEntry.coupleEntry = currentEntry.coupleEntry;
      if (currentEntry.pic) {
        showEntry.pic = currentEntry.pic;
        setUserPic(currentEntry.pic);
      }

      if (currentEntry.pic2) {
        showEntry.pic2 = currentEntry.pic2;
        setUserPic2(currentEntry.pic2);
      }

      if (currentEntry.pic3) {
        showEntry.pic3 = currentEntry.pic3;
        setUserPic3(currentEntry.pic3);
      }

      if (currentEntry.signature) {
        showEntry.signature = currentEntry.signature;
        setSignatureURL(currentEntry.signature);
      }

      console.log(showEntry);
      setExists(true);
    }
  };

  useEffect(() => {
    //console.log(user);
    setActive(true);
    fetchProfile(cellNumber);
    fetchEntry(show, cellNumber);

    //profile.cellphone.value = profile.cellphone.value;
  }, []);

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref}>
      <div>
        <h1>Personal Profile</h1>
        {/* Names */}
        <div>
          <TextField
            required
            sx={{ m: 2, width: "40%" }}
            id="ename"
            label="Name"
            name="name"
            variant="outlined"
            value={profile.name.value}
            onChange={handleChangeProfile}
            error={profile.name.error}
            InputProps={{
              readOnly: true,
            }}
            // helperText={profile.name.errorMessage}
          ></TextField>
          <TextField
            required
            sx={{ m: 2, width: "40%" }}
            id="esurname"
            label="Surname"
            name="surname"
            variant="outlined"
            value={profile.surname.value}
            onChange={handleChangeProfile}
            className={"form-control"}
            error={profile.surname.error}
            InputProps={{
              readOnly: true,
            }}
            // helperText={profile.surname.errorMessage}
          />
        </div>
        {/*Contacts*/}
        <div>
          <TextField
            sx={{ m: 2, width: "40%" }}
            id="ecellphone"
            label="Cellphone"
            name="cellphone"
            variant="outlined"
            className={"form-control"}
            value={profile.cellphone.value}
            onChange={handleChangeProfile}
            InputProps={{
              readOnly: true,
            }}
            // error={profile.cellphone.error}
          />
          <TextField
            sx={{ m: 2, width: "40%" }}
            id="email"
            label="E-Mail"
            variant="outlined"
            name="email"
            className={"form-control"}
            value={profile.email.value}
            onChange={handleChangeProfile}
            required
            error={profile.email.error}
            // helperText={profile.email.errorMessage}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        {/*Age and gender*/}
        <div>
          <TextField
            sx={{ m: 2, width: "40%" }}
            type={"number"}
            id="age"
            name="age"
            InputProps={{ inputProps: { min: 21, max: 35 } }}
            label="Age"
            placeholder="Enter your age"
            variant="outlined"
            value={profile.age.value}
            onChange={handleChangeProfile}
            requried
            error={profile.age.error}
            disabled
            // helperText={profile.age.errorMessage}
          />
          <FormControl sx={{ m: 2, width: "40%" }}>
            <InputLabel id="genderSelect">Gender</InputLabel>
            <Select
              name="gender"
              variant="outlined"
              label="genderSelect"
              value={profile.gender.value}
              onChange={handleChangeProfile}
              required
              error={profile.gender.error}
              disabled
              // helperText={profile.gender.errorMessage}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Non-Binary">Non-Binary</MenuItem>
            </Select>
          </FormControl>
        </div>
        {/*Address*/}
        <div>
          <TextField
            sx={{ m: 2, width: "83%" }}
            // inputRef={materialRef}
            label="Full Address"
            variant="outlined"
            name="fullAddress"
            className={"form-control"}
            value={profile.fullAddress.value}
            onChange={handleChangeProfile}
            error={profile.fullAddress.error}
            InputProps={{
              readOnly: true,
            }}
            // helperText={profile.fullAddress.errorMessage}
            // onBlur={(e) => {
            //   // profile.fullAddress = e.target.value;
            //   setProfile({
            //     ...profile,
            //     fullAddress: address,
            //   });
            // }}
          />
        </div>
        {/* DOB & IDNo */}
        <div>
          <TextField
            sx={{ m: 2, width: "40%" }}
            type={"date"}
            id="DOB"
            name="DOB"
            label="Date Of Birth"
            variant="outlined"
            value={profile.DOB.value}
            onChange={handleChangeProfile}
            className={"form-control"}
            error={profile.DOB.error}
            InputProps={{
              readOnly: true,
            }}
            // helperText={profile.DOB.errorMessage}
          />

          <TextField
            sx={{ m: 2, width: "40%" }}
            id="idNo"
            name="IDNo"
            inputProps={{
              maxLength: 13,
            }}
            label="ID Number"
            variant="outlined"
            value={profile.IDNo.value}
            onChange={handleChangeProfile}
            className={"form-control"}
            error={profile.IDNo.error}
            InputProps={{
              readOnly: true,
            }}
            // helperText={profile.IDNo.errorMessage}
          />
        </div>
        {/*Orientation and Preference*/}
        <div>
          <FormControl sx={{ m: 2, width: "40%" }}>
            <InputLabel id="orientationSelect">Sexual Orientation</InputLabel>
            <Select
              name="SexualOrientation"
              variant="outlined"
              label="orientationSelect"
              value={profile.SexualOrientation.value}
              onChange={handleChangeProfile}
              error={profile.SexualOrientation.error}
              disabled
              // helperText={profile.SexualOrientation.errorMessage}
            >
              <MenuItem value="Asexual">Asexual</MenuItem>
              <MenuItem value="Bisexual">Bisexual</MenuItem>
              <MenuItem value="Heterosexual">Heterosexual(Straight)</MenuItem>
              <MenuItem value="Homosexual(Gay)">Homosexual(Gay)</MenuItem>
              <MenuItem value="Homosexual(Lesbian)">
                Homosexual(Lesbian)
              </MenuItem>
              <MenuItem value="Pansexual">Pansexual</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 2, width: "40%" }}>
            <InputLabel id="preferenceSelect">Sexual Preference</InputLabel>
            <Select
              name="SexualPreference"
              variant="outlined"
              label="preferenceSelect"
              value={profile.SexualPreference.value}
              onChange={handleChangeProfile}
              error={profile.SexualPreference.error}
              disabled
              // helperText={profile.SexualPreference.errorMessage}
            >
              <MenuItem value="Asexual">Asexual</MenuItem>
              <MenuItem value="Bisexual">Bisexual</MenuItem>
              <MenuItem value="Heterosexual">Heterosexual(Straight)</MenuItem>
              <MenuItem value="Homosexual(Gay)">Homosexual(Gay)</MenuItem>
              <MenuItem value="Homosexual(Lesbian)">
                Homosexual(Lesbian)
              </MenuItem>
              <MenuItem value="Pansexual">Pansexual</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div>
        <h1>Entry</h1>

        {/* Occupation?  SingleWhy?"*/}
        <div>
          <TextField
            required
            sx={{ m: 2, width: "40%" }}
            multiline
            rows={4}
            label="Occupation"
            name="occupation"
            placeholder="Occupation"
            variant="outlined"
            className={"form-control"}
            value={showEntry.occupation.value}
            onChange={handleChangeEntry}
            error={showEntry.occupation.error}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            required
            sx={{ m: 2, width: "40%" }}
            multiline
            rows={4}
            label="Why You Single"
            name="singlewhy"
            placeholder="Reason You Are Single?"
            variant="outlined"
            className={"form-control"}
            value={showEntry.singlewhy.value}
            onChange={handleChangeEntry}
            error={showEntry.singlewhy.error}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        {/*Describe Yourself and Ideal Match*/}
        <div>
          <TextField
            required
            sx={{ m: 2, width: "40%" }}
            multiline
            rows={4}
            name="describeYourself"
            placeholder="Briefly Describe Yourself?"
            label="Describe Yourself"
            className={"form-control"}
            variant="outlined"
            value={showEntry.describeYourself.value}
            onChange={handleChangeEntry}
            error={showEntry.describeYourself.error}
            InputProps={{
              readOnly: true,
            }}
          ></TextField>
          <TextField
            required
            sx={{ m: 2, width: "40%" }}
            multiline
            rows={4}
            name="idealMatch"
            placeholder="Describe Your Ideal Match?"
            label="Ideal Match?"
            className={"form-control"}
            variant="outlined"
            value={showEntry.idealMatch.value}
            onChange={handleChangeEntry}
            error={showEntry.idealMatch.error}
            InputProps={{
              readOnly: true,
            }}
          ></TextField>
        </div>
        {/*RelationshipsInterested and Status*/}
        <div>
          <TextField
            required
            sx={{ m: 2, width: "40%" }}
            multiline
            rows={4}
            name="relationshipsInterestedIn"
            placeholder="What Kinda Relationships Are You Interested In??"
            label="Relationships Interested In?"
            className={"form-control"}
            variant="outlined"
            value={showEntry.relationshipsInterestedIn.value}
            onChange={handleChangeEntry}
            error={showEntry.relationshipsInterestedIn.error}
            InputProps={{
              readOnly: true,
            }}
          ></TextField>
          <TextField
            required
            sx={{ m: 2, width: "40%" }}
            multiline
            rows={4}
            name="relationshipStatus"
            placeholder="Describe Your Current Relationship Status?"
            label="Relationship Status"
            className={"form-control"}
            variant="outlined"
            value={showEntry.relationshipStatus.value}
            onChange={handleChangeEntry}
            error={showEntry.relationshipStatus.error}
            InputProps={{
              readOnly: true,
            }}
          ></TextField>
        </div>

        {/*qualities and dealbreakers*/}
        <div>
          <TextField
            required
            sx={{ m: 2, width: "40%" }}
            multiline
            rows={5}
            name="qualitiesMatch"
            placeholder="List 5 Qualities You Seek In A Match?"
            label="Qualities You Seek?"
            className={"form-control"}
            variant="outlined"
            value={showEntry.qualitiesMatch.value}
            onChange={handleChangeEntry}
            error={showEntry.qualitiesMatch.error}
            InputProps={{
              readOnly: true,
            }}
          ></TextField>
          <TextField
            required
            sx={{ m: 2, width: "40%" }}
            multiline
            rows={5}
            name="dealbreakers"
            placeholder="List 5 Pet Peeves/ Turn Offs or Dealbreakers?"
            label="Dealbreakers:"
            className={"form-control"}
            variant="outlined"
            value={showEntry.dealbreakers.value}
            onChange={handleChangeEntry}
            error={showEntry.dealbreakers.error}
            InputProps={{
              readOnly: true,
            }}
          ></TextField>
        </div>
      </div>

      <div>
        <h1>INSERT 2/3X PICS</h1>
        {/*Media Uploads*/}
        <CRow>
          <CCol>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Picture</Form.Label>
              <img
                style={{ padding: "20px" }}
                src={userPic}
                className="img-fluid rounded mx-auto d-block"
                alt=""
              />
            </Form.Group>
          </CCol>

          <CCol>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Picture 2</Form.Label>
              <img
                style={{ padding: "20px" }}
                src={userPic2}
                className="img-fluid rounded mx-auto d-block"
                alt=""
              />
            </Form.Group>
          </CCol>

          <CCol>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Picture 3</Form.Label>
              <img
                style={{ padding: "20px" }}
                src={userPic3}
                className="img-fluid rounded mx-auto d-block"
                alt=""
              />
            </Form.Group>
          </CCol>
        </CRow>
      </div>

      <div>
        <CContainer className="text-center">
          <FormGroup></FormGroup>
        </CContainer>
      </div>
    </div>
  ));

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Busy Processing, Please Wait..."
    >
      <Container>
        {error && <Alert varimaant="danger">{error}</Alert>}
        <DeleteDialog show={show} cellNumber={cellNumber} open={openClose} />
        <fieldset disabled={false}>
          <br />
          <form
            id="enterDMF"
            name="theform"
            onSubmit={handleSubmit}
            // disabled
            component="form"
            className="testbox text-center"
            style={{ padding: 20 }}
            autoComplete="off"
            noValidate
          >
            <ComponentToPrint ref={formRef}></ComponentToPrint>
            <div>
              <CButton
                style={{ width: "15%", margin: 3 }}
                color="danger"
                variant="outline"
                onClick={handleToList}
              >
                <CIcon icon={icon.cilList} size="xxl" /> List
              </CButton>
              <CButton
                style={{ width: "15%", margin: 3 }}
                color="danger"
                variant="outline"
                onClick={() => {
                  setActive(true);
                  exportComponentAsJPEG(formRef, {
                    fileName: profile.name.value + " " + profile.surname.value,
                  }).then(() => {
                    setActive(false);
                  });
                }}
              >
                <CIcon icon={icon.cilSave} size="xxl" /> Export
              </CButton>
            </div>
          </form>
        </fieldset>
      </Container>
    </LoadingOverlay>
  );
}
