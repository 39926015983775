export let entrantProfile = {
  uid: "",
  //Profile
  name: { value: "", error: false, errorMessage: "You must enter a name" },
  surname: {
    value: "",
    error: false,
    errorMessage: "You must enter a surname",
  },
  cellphone: {
    value: "",
    error: false,
    errorMessage: "You must enter a cellphone",
  },

  email: { value: "", error: false, errorMessage: "You must enter an email" },
  gender: { value: "", error: false, errorMessage: "You must select a gender" },
  age: { value: "", error: false, errorMessage: "You must enter an age" },
  // city: { value: "", error: false, errorMessage: "You must enter a city" },
  // province: { value: "", error: false, errorMessage: "You must enter a city" },
  fullAddress: {
    value: "",
    error: false,
    errorMessage: "You must enter an address",
  },
  DOB: { value: "", error: false, errorMessage: "You must enter a name" },
  IDNo: { value: "", error: false, errorMessage: "You must enter a ID Number" },
  SexualOrientation: {
    value: "",
    error: false,
    errorMessage: "You must select an orientation",
  },
  SexualPreference: {
    value: "",
    error: false,
    errorMessage: "You must select a preference",
  },
};

export let dmfEntry = {
  uid: "",

  coupleEntry: "false",
  occupation: {
    value: "",
    error: false,
    errorMessage: "You must select an occupation",
  },
  singlewhy: {
    value: "",
    error: false,
    errorMessage: "You must say why you are single",
  },
  describeYourself: {
    value: "",
    error: false,
    errorMessage: "You must describe yourself",
  },
  idealMatch: { value: "", error: false, errorMessage: "Required field" },
  relationshipsInterestedIn: {
    value: "",
    error: false,
    errorMessage: "Required field",
  },
  relationshipStatus: {
    value: "",
    error: false,
    errorMessage: "Required field",
  },
  qualitiesMatch: { value: "", error: false, errorMessage: "Required field" },
  dealbreakers: { value: "", error: false, errorMessage: "Required field" },
  // availability: { value: "", error: false, errorMessage: "Required field" },
  signature: {
    value: "No Signature",
    error: false,
    errorMessage: "Required field",
  },
  pic: { value: "No Picture", error: false, errorMessage: "Required field" },
  pic2: { value: "No Picture", error: false, errorMessage: "Required field" },
  pic3: { value: "No Picture", error: false, errorMessage: "Required field" },
};

export let dmfEntryCouple = {
  uid: "",

  yearsTogether: { value: "", error: false, errorMessage: "Required field" },
  polyamoryStatus: { value: "", error: false, errorMessage: "Required field" },
  peopleInRelationship: {
    value: "",
    error: false,
    errorMessage: "Required field",
  },
  occupation: { value: "", error: false, errorMessage: "Required field" },
  describeYourself: { value: "", error: false, errorMessage: "Required field" },
  idealMatch: { value: "", error: false, errorMessage: "Required field" },
  relationshipsInterestedIn: {
    value: "",
    error: false,
    errorMessage: "Required field",
  },
  relationshipStatus: {
    value: "",
    error: false,
    errorMessage: "Required field",
  },
  qualitiesMatch: { value: "", error: false, errorMessage: "Required field" },
  dealbreakers: { value: "", error: false, errorMessage: "Required field" },

  signature: {
    value: "No Signature",
    error: false,
    errorMessage: "Required field",
  },
  pic: { value: "No Picture", error: false, errorMessage: "Required field" },
  pic2: { value: "No Picture", error: false, errorMessage: "Required field" },
  pic3: { value: "No Picture", error: false, errorMessage: "Required field" },
  coupleEntry: "true",
  partner: {
    name: { value: "", error: false },
    surname: { value: "", error: false },
    cellphone: { value: "", error: false },
    email: { value: "", error: false },
    gender: { value: "", error: false },
    age: { value: "", error: false },
    // city: { value: "", error: false },
    // province: { value: "", error: false },
    fullAddress: { value: "", error: false },
    DOB: { value: "", error: false },
    IDNo: { value: "", error: false },
    SexualOrientation: { value: "", error: false },
    SexualPreference: { value: "", error: false },
    occupation: { value: "", error: false },
  },
};

export let listColumns = [
  "name",
  "surname",
  "gender",
  "SexualOrientation",
  "SexualPreference",
  "cellphone",
  "Email",
  "CreatedAt",
];
