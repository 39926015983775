import { Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
  CCardTitle,
  CContainer,
} from "@coreui/react";
import { getAllShows } from "../../hooks/useFirestore";
import { useEffect, useState, React } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useAppContext } from "../../hooks/useAppContext";
import { useAuthContext } from "../../hooks/useAuthContext";

export function MainPage() {
  const [list, setList] = useState();
  const [itemData, setItemData] = useState([]);
  const [isActive, setActive] = useState(true);
  const { show, cellNumber, setObject } = useAppContext();
  const { authIsReady, user } = useAuthContext();
  const navigate = useNavigate();
  let cn = "";
  if (user) {
    cn = user.phoneNumber;
  }
  const handleRegister = () => {
    navigate("/login/" + show);
  };
  const handleSingle = () => {
    navigate("/" + show + "/entry/single/" + show + "/" + cn);
  };
  const handleCouple = () => {
    navigate("/" + show + "/entry/couple/" + show + "/" + cn);
  };
  //
  useEffect(() => {
    // const shows = await getAllShows();
    setActive(false);
    // setList(...shows);
    // let data = [];
    //
    // setItemData(...data);
  }, []);

  return (
    <div>
      {isActive && (
        <LoadingOverlay active={isActive} spinner text="">
          <div>
            <h1 id={"loading"}>
              <span className="let1">l</span>
              <span className="let2">o</span>
              <span className="let3">a</span>
              <span className="let4">d</span>
              <span className="let5">i</span>
              <span className="let6">n</span>
              <span className="let7">g</span>
            </h1>
          </div>
        </LoadingOverlay>
      )}

      {!isActive && (
        <Container>
          {/*<Row className="justify-content-center">*/}
          {/*  <Col md="auto">*/}
          {/*    <Image*/}
          {/*      src={logo}*/}
          {/*      className="Wiz-App-logo img-fluid rounded"*/}
          {/*      alt="logo"*/}
          {/*      style={{ padding: "20px", width: "800px" }}*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <div>
            {/* Names */}
            <Row style={{ margin: "2%" }}>
              <img
                style={{ padding: "20px", width: "800px" }}
                // src={"../images/" + show + ".jpeg"}
                src={setObject.showLogo}
                className="img-fluid rounded mx-auto d-block"
                alt=""
              />
              <CContainer>
                <CCard className="text-center">
                  <CCardHeader>{setObject.showName}</CCardHeader>{" "}
                  <CCardBody>
                    <CCardTitle>Welcome</CCardTitle>{" "}
                    <CCardText>{setObject.showDescription}</CCardText>
                    {!user ? (
                      <div>
                        <CButton
                          style={{ resize: "none", width: "100", margin: 4 }}
                          onClick={handleRegister}
                        >
                          Enter
                        </CButton>
                      </div>
                    ) : (
                      <div>
                        <CButton
                          style={{ resize: "none", width: "100", margin: 4 }}
                          onClick={handleSingle}
                        >
                          Single
                        </CButton>
                        <CButton
                          style={{ resize: "none", width: "100", margin: 4 }}
                          onClick={handleCouple}
                        >
                          Couple
                        </CButton>
                      </div>
                    )}
                  </CCardBody>
                  <CCardFooter className="text-medium-emphasis">
                    Entries open until {setObject.closeDate}
                  </CCardFooter>
                </CCard>
              </CContainer>
            </Row>
          </div>
        </Container>
      )}
    </div>
  );
}
