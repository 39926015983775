import React, { useEffect, useCallback, useState } from "react";
import { Badge } from "react-bootstrap";
import logo from "../images/dmf.png";
import "./dmf/showStyle.css";
import { projectFirestore } from "../firebase/config";
import { useParams } from "react-router-dom";
import { Card, CardContent, Paper } from "@mui/material";
import { getSettings } from "../hooks/useFirestore";

export function DeleteConfirmation() {
  const color = "#49a7c2";
  const { show } = useParams();
  let settings = [];

  const [setObject, setSetObject] = useState();
  const fetchSettings = useCallback(
    async (show) => {
      if (setObject == undefined) {
        settings = await getSettings(show);
        document.body.style.background = settings.color;
        await setSetObject({ ...settings });
      }
      console.log(setObject);
    },
    [setObject]
  );

  useEffect(() => {
    //Get Settings
    fetchSettings();
  }, []);

  return (
    <div>
      <img
        style={{ padding: "20px", width: "880px", height: "auto" }}
        src={"../images/" + show + ".png"}
        className="img-fluid rounded mx-auto d-block"
        alt="EntryWiz"
      />
      <div className="row">
        <h1 className=" col text-center">
          <Paper elevation={3}>
            <h1 style={{ margin: "2%", fontWeight: "bold" }}>Thank You</h1>
          </Paper>
          <Card>
            <CardContent>
              <p>
                Your details have been deleted and will be removed from our
                servers.
              </p>
            </CardContent>
          </Card>
        </h1>
      </div>
    </div>
  );
}
