import React, { useCallback, useEffect, useState } from "react";
import { useCollection } from "../../hooks/useCollection";
import { DataGrid } from "devextreme-react";
import { projectFirestore } from "../../firebase/config";
import { Column, HeaderFilter } from "devextreme-react/gantt";
import { Export } from "devextreme-react/chart";
import { Pager, Paging } from "devextreme-react/data-grid";
import { useAppContext } from "../../hooks/useAppContext";

export function EntryList() {
  const { show } = useAppContext();
  const [documents, setDocuments] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const [mergedSets, setMergedSets] = useState([]);
  const { setObject } = useAppContext();

  const combineProfileEntries = useCallback(async () => {
    if (profiles !== null && documents !== null) {
      const mapSets = new Map();
      profiles.forEach((item) => mapSets.set(item.uid, item));
      documents.forEach((item) =>
        mapSets.set(item.uid, { ...mapSets.get(item.uid), ...item })
      );
      setMergedSets(Array.from(mapSets.values()));
    }
  }, [documents, profiles]);

  const getAllProfiles = async () => {
    let entry = {};
    const ref = await projectFirestore.collection("Profiles");

    const unsubscibe = ref.onSnapshot(
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        ///update state
        setProfiles(results);

        // setError(null);
      },
      (error) => {
        console.log(error);
        // setError("could not fetch the data");
      }
    );
    //unsubscribe on amount
    return () => unsubscibe();
  };

  const getAllEntries = async () => {
    let entry = {};
    const ref = await projectFirestore
      .collection("Shows")
      .doc(show)
      .collection("Entries");
    const unsubscibe = ref.onSnapshot(
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        ///update state
        setDocuments(results);
        // setError(null);
      },
      (error) => {
        console.log(error);
        // setError("could not fetch the data");
      }
    );

    //unsubscribe on amount
    return () => unsubscibe();
  };

  useEffect(() => {
    //console.log(user);

    if (profiles == null) {
      getAllProfiles();
      getAllEntries();
    }
    combineProfileEntries();
  }, [combineProfileEntries]);

  const renderGridCell = (data) => {
    return (
      <a
        href={"/dmf/entry/" + show + "/" + data.text}
        target="_blank"
        rel="noopener noreferrer"
      >
        check
      </a>
    );
  };

  const allowedPageSizes = [10, 20, 50];

  return (
    <div className=" container">
      {/*<h1>2021 Entries Loading</h1>*/}
      {/*/!* <div>entryList</div> *!/*/}
      {/*{error && <p>{error}</p>}*/}
      {/*{documents && <TransactionList transactions={documents} />}*/}
      <img
        style={{ padding: "20px", width: "800px" }}
        // src={"/../images/" + show + ".jpeg"}
        src={setObject.showLogo}
        className="img-fluid rounded mx-auto d-block"
        alt=""
      />
      <DataGrid dataSource={mergedSets} showBorders={true}>
        <HeaderFilter visible={true} />

        <Column dataField="name" />
        <Column dataField="surname" />
        <Column dataField="gender" />
        <Column dataField="cellphone" />
        <Column dataField="email" />
        <Column dataField="religion" />
        <Column
          dataField="cellphone"
          caption="entry"
          cellRender={renderGridCell}
        />
        <Export enabled={true} allowExportSelectedData={true} />
        <Paging defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={allowedPageSizes}
        />
      </DataGrid>
    </div>
  );
}
