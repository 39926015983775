import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../stylesheets/style.css";
import "bootstrap/dist/css/bootstrap.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  createDocumentFirestore,
  getEntry,
  getProfile,
  getSettings,
  picUpload,
  sendEntryEmail,
  videoUpload,
} from "../../hooks/useFirestore";
import { Alert, Col, Form, Row } from "react-bootstrap";
import "react-rating";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  Divider,
  InputLabel,
  MenuItem,
  Select,
  FormControl as FormControlM,
  TextField,
  Button,
  Grid,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import { Facebook, Instagram, Twitter } from "react-bootstrap-icons";
import { usePlacesWidget } from "react-google-autocomplete";

import { DateBox, NumberBox } from "devextreme-react";
import { entrantProfile, pastorEntry } from "./models";
import { projectStorage } from "../../firebase/config";
import { Delete } from "@mui/icons-material";

export function EntryForm() {
  const { show, cellNumber } = useParams();
  let settings = [];
  let currentProfile = {};
  let currentEntry = {};

  const [isActive, setActive] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuthContext();
  const [exists, setExists] = useState("false");

  const [profile, setProfile] = useState(entrantProfile);
  const [showEntry, setShowEntry] = useState(pastorEntry);

  const [setObject, setSetObject] = useState();

  const [showPic, setEntrantPic] = useState("../images/" + show + ".jpeg");
  const [showVid, setEntrantVid] = useState("../images/" + show + ".jpeg");

  const form = useRef();
  const navigate = useNavigate();

  const handleImage = async (event) => {
    setActive(true);

    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const storage = projectStorage;
    const storageRef = storage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);

      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name +
            profile.surname +
            profile.cellphone
        )
        .put(uploadedFile)
        .then(async (response) => {
          response.ref.getDownloadURL().then((link) => {
            showEntry.pic = link;
            setEntrantPic(link);
            console.log(link);
            setActive(false);
          });
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }
  };

  const handleVideo = async (event) => {
    setActive(true);

    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    const storage = projectStorage;
    const storageRef = storage.ref();

    try {
      // await storageRef.child(uploadedFile.name).put(uploadedFile);

      var refObject = await storageRef
        .child(
          "EntriesMedia/" +
            show +
            "/" +
            profile.name +
            profile.surname +
            profile.cellphone
        )
        .put(uploadedFile)
        .then(async (response) => {
          let downloadURL = await response.ref.getDownloadURL();
          showEntry.video = downloadURL;
          setEntrantVid(downloadURL);
          console.log(downloadURL);
          setActive(false);
        });
      // alert("Successfully uploaded video!");
    } catch (error) {
      console.log("error", error);
      alert(error.message);
      setActive(false);
    }

    setActive(false);
  };

  const sendEmail = async () => {
    try {
      sendEntryEmail(profile, showEntry, exists, setObject).then((result) => {
        console.log(result);
        navigate("/thankyou/" + show);
      });
    } catch (error) {
      console.log(error.text);
    }
  };

  const handleDelete = () => {
    setOpenClose(true);
  };

  const handleSubmit = (e) => {
    try {
      createDocumentFirestore(
        e,
        exists,
        profile,
        showEntry,
        settings,
        user,
        show
      );
      sendEmail(profile, showEntry, exists, settings);
      navigate("/thankyou/" + show);
    } catch (err) {
      console.log(err.message);
    }
  };

  // const fetchSettings = useCallback(async (show) => {
  //   settings = await getSettings(show);
  //   document.body.style.background = settings.color;
  // }, []);

  const fetchSettings = useCallback(
    async (show) => {
      if (setObject == undefined) {
        settings = await getSettings(show);
        document.body.style.background = settings.color;
        await setSetObject({ ...settings });
      }
      console.log(setObject);
    },
    [setObject]
  );

  const fetchProfile = useCallback(async (userNumber) => {
    currentProfile = await getProfile(userNumber);
    if (currentProfile !== undefined) {
      // setProfile({ ...currentProfile });

      profile.uid = currentProfile.uid;
      profile.name = currentProfile.name;
      profile.surname = currentProfile.surname;
      profile.cellphone = currentProfile.cellphone;
      profile.email = currentProfile.email;
      profile.twitter = currentProfile.twitter;
      profile.facebook = currentProfile.facebook;
      profile.instagram = currentProfile.instagram;
      profile.gender = currentProfile.gender;
      profile.age = currentProfile.age;
      profile.city = currentProfile.city;
      profile.province = currentProfile.province;
      profile.fullAddress = currentProfile.fullAddress;

      console.log(profile);
      setActive(false);
    } else {
      setActive(false);
    }
  }, []);

  const fetchEntry = async (show, userNumber) => {
    currentEntry = await getEntry(show, userNumber);
    if (currentEntry !== undefined) {
      // setShowEntry({ ...currentEntry });

      showEntry.uid = currentEntry.uid;
      showEntry.dodate = currentEntry.dodate;
      showEntry.firstdate = currentEntry.firstdate;
      showEntry.malebestfriend = currentEntry.malebestfriend;
      showEntry.sharingpassword = currentEntry.sharingpassword;
      showEntry.lovemarriage = currentEntry.lovemarriage;
      showEntry.dealbreaker = currentEntry.dealbreaker;
      showEntry.readymarriage = currentEntry.readymarriage;
      showEntry.looksbrains = currentEntry.looksbrains;
      showEntry.friendsx = currentEntry.friendsx;
      showEntry.spontaneous = currentEntry.spontaneous;

      showEntry.elders = currentEntry.elders;
      showEntry.church = currentEntry.church;
      showEntry.familyfriends = currentEntry.familyfriends;
      showEntry.introvertextrovert = currentEntry.introvertextrovert;
      showEntry.misunderstood = currentEntry.misunderstood;
      showEntry.oneperson = currentEntry.oneperson;
      showEntry.confrontational = currentEntry.confrontational;
      // showEntry.video = currentEntry.video;
      // showEntry.pic = currentEntry.pic;

      setEntrantPic(currentEntry.pic);
      setEntrantVid(currentEntry.video);

      if (currentEntry.video) {
        showEntry.video = currentEntry.video;
      }

      if (currentEntry.pic) {
        showEntry.pic = currentEntry.pic;
      }

      console.log(showEntry);
      setExists(true);
    }
  };

  useEffect(() => {
    //console.log(user);
    setActive(true);
    fetchSettings(show);
    fetchProfile(cellNumber);
    fetchEntry(show, cellNumber);
  }, []);

  const { ref: addRef } = usePlacesWidget({
    apiKey: "AIzaSyBEEUwF_2FTgUM6E7Dku28uJctPimqWCMw",
    onPlaceSelected: (place) => {
      console.log(place);

      setProfile({
        ...profile,
        fullAddress: place.formatted_address,
        city: place.address_components[3].long_name,
        province: place.address_components[5].long_name,
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  const { ref: shootAddRef } = usePlacesWidget({
    apiKey: "AIzaSyBEEUwF_2FTgUM6E7Dku28uJctPimqWCMw",
    onPlaceSelected: (place) => {
      console.log(place);
      setShowEntry({
        ...showEntry,
        shootaddress: place.formatted_address,
        shootcity: place.address_components[3].long_name,
        shootprovince: place.address_components[5].long_name,
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "za" },
    },
  });

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Busy Processing, Please Wait..."
    >
      <div
        className="testbox"
        // style={{ "box-shadow": "0 20px 0 #a82877" }}
        style={{ margin: "2%" }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        <fieldset disabled={false}>
          <Form
            ref={form}
            id="enterDMF"
            name="theform"
            onSubmit={handleSubmit}
            disabled
            // style={{ "box-shadow": "0 20px 0 #a82877" }}
          >
            {/* Names */}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="ename"
                  label="Name"
                  variant="outlined"
                  value={profile.name}
                  onChange={(e) => {
                    // profile.name = e.target.value;
                    setProfile({ ...profile, name: e.target.value });
                  }}
                  className={"form-control"}
                />
              </Col>
              <Col>
                <TextField
                  id="esurname"
                  label="Surname"
                  variant="outlined"
                  value={profile.surname}
                  onChange={(e) => {
                    setProfile({ ...profile, surname: e.target.value });
                  }}
                  className={"form-control"}
                />
              </Col>
            </Row>
            {/*Contacts*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="ecellphone"
                  label="Cellphone"
                  variant="outlined"
                  className={"form-control"}
                  value={user.phoneNumber}
                  onChange={(e) => {
                    setProfile({ ...profile, cellphone: e.target.value });
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="email"
                  label="E-Mail"
                  variant="outlined"
                  className={"form-control"}
                  value={profile.email}
                  onChange={(e) => {
                    setProfile({ ...profile, email: e.target.value });
                  }}
                />
              </Col>
            </Row>
            {/*Age and gender*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <NumberBox
                  id="age"
                  min={18}
                  showSpinButtons={true}
                  label="Age"
                  placeholder="Enter your age"
                  variant="outlined"
                  className={"form-control"}
                  value={profile.age}
                  onValueChanged={(e) => {
                    setProfile({ ...profile, age: e.value });
                  }}
                />
              </Col>
              <Col>
                <FormControlM className={"form-control"}>
                  <InputLabel id="genderSelect">Gender</InputLabel>
                  <Select
                    className={"form-control"}
                    labelId="genderSelect"
                    label="Gender"
                    value={profile.gender}
                    onChange={(e) => {
                      setProfile({ ...profile, gender: e.target.value });
                    }}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControlM>
              </Col>
            </Row>
            {/*Social Media*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <TextField
                  id="twitter"
                  label="Twitter"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Twitter />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={profile.twitter}
                  onChange={(e) => {
                    setProfile({ ...profile, twitter: e.target.value });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  id="instagram"
                  label="Instagram"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Instagram />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={profile.instagram}
                  onChange={(e) => {
                    setProfile({ ...profile, instagram: e.target.value });
                  }}
                />
              </Col>

              <Col>
                <TextField
                  id="facebook"
                  label="Facebook"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Facebook />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={profile.facebook}
                  onChange={(e) => {
                    setProfile({ ...profile, facebook: e.target.value });
                  }}
                />
              </Col>
            </Row>
            {/*Address*/}
            <Row style={{ margin: "2%" }}>
              <Col>
                <FormControlM variant="outlined" className={"form-control"}>
                  <TextField
                    inputRef={addRef}
                    label="Full Address"
                    variant="outlined"
                    className={"form-control"}
                    value={profile.fullAddress}
                    onChange={(e) => {
                      setProfile({ ...profile, fullAddress: e.target.value });
                    }}
                  />
                </FormControlM>
              </Col>
            </Row>
            {/*<Row style={{ margin: "2%" }}>*/}

            {/*  <Col>*/}
            {/*    <TextField*/}
            {/*      readonly*/}
            {/*      id="ecity"*/}
            {/*      label="City"*/}
            {/*      variant="outlined"*/}
            {/*      className={"form-control"}*/}
            {/*      value={profile.city}*/}
            {/*      onChange={(e) => {*/}
            {/*        setProfile({ ...profile, city: e.target.value });*/}
            {/*      }}*/}
            {/*      InputProps={{*/}
            {/*        readOnly: true,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*  <Col>*/}
            {/*    <TextField*/}
            {/*      id="eprovince"*/}
            {/*      label="Province"*/}
            {/*      variant="outlined"*/}
            {/*      className={"form-control"}*/}
            {/*      value={profile.province}*/}
            {/*      onChange={(e) => {*/}
            {/*        setProfile({ ...profile, province: e.target.value });*/}
            {/*      }}*/}
            {/*      InputProps={{*/}
            {/*        readOnly: true,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*</Row>*/}

            <Divider variant="fullWidth">
              {" "}
              <Chip label="Entry Pastor" />
            </Divider>

            {/* What do you normally do on a date?  */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="What do you normally do on a date?"
                placeholder="What do you normally do on a date?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.dodate}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    dodate: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* "How would you plan a first date with a lady?"*/}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="How would you plan a first date?"
                placeholder="How would you plan a first date with a lady?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.firstdate}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    firstdate: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* "If your potential wife had a male best friend, how would you feel?"*/}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="If your potential wife had a male best friend?"
                placeholder="If your potential wife had a male best friend, how would you feel?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.malebestfriend}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    malebestfriend: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* What is the one thing you learnt from your parents about love and marriage? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="One thing you learnt from your parents about love and marriage?"
                placeholder="What is the one thing you learnt from your parents about love and marriage?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.lovemarriage}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    lovemarriage: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* What is your relationship deal breaker for you? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="What is a deal breaker for you?"
                placeholder="What is your relationship deal breaker for you?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.dealbreaker}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    dealbreaker: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* When do you think a person is ready for marriage in your own opinion? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="When do you think a person is ready for marriage?"
                placeholder="When do you think a person is ready for marriage in your own opinion?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.readymarriage}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    readymarriage: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/*/!* What is more important to you when you are looking for someone; looks or brains? *!/*/}
            {/*<Row style={{ margin: "2%" }}>*/}
            {/*  <TextField*/}
            {/*    multiline*/}
            {/*    rows={4}*/}
            {/*    label="Looks or brains?"*/}
            {/*    placeholder="What is more important to you when you are looking for someone; looks or brains?"*/}
            {/*    className={"form-control"}*/}
            {/*    variant="outlined"*/}
            {/*    value={showEntry.readymarriage}*/}
            {/*    onChange={(e) => {*/}
            {/*      setShowEntry({*/}
            {/*        ...showEntry,*/}
            {/*        readymarriage: e.target.value,*/}
            {/*      });*/}
            {/*    }}*/}
            {/*  ></TextField>*/}
            {/*</Row>*/}

            {/* What is more important to you when you are looking for someone; looks or brains? */}
            <Row style={{ margin: "2%" }}>
              <InputLabel id="looksbrainsSelect">Looks or brains?</InputLabel>
              <Select
                className={"form-control"}
                labelId="looksbrainsSelect"
                label="What is more important to you when you are looking for someone; looks or brains?"
                value={showEntry.looksbrains}
                onChange={(e) => {
                  setShowEntry({ ...showEntry, looksbrains: e.target.value });
                }}
              >
                <MenuItem value="Looks">Looks</MenuItem>
                <MenuItem value="Brains">Brains</MenuItem>
              </Select>
            </Row>

            {/* Do you usually stay friends with your exes? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Do you usually stay friends with your exes?"
                placeholder="Do you usually stay friends with your exes?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.friendsx}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    friendsx: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* What is the most spontaneous thing you have done on a date? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Most spontaneous thing you have done on a date?"
                placeholder="What is the most spontaneous thing you have done on a date?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.spontaneous}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    spontaneous: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            <Divider variant="fullWidth">
              {" "}
              <Chip label="Back Story" />
            </Divider>

            {/* How will the church elders feel about you being on the show? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Church elders"
                placeholder="How will the church elders feel about you being on the show?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.elders}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    elders: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* How will the church elders feel about you being on the show? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Church and your potential wife?"
                placeholder="How do you think the church would accept your potential wife?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.church}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    church: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* How would your family and friends describe you? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="Family and friends"
                placeholder="How would your family and friends describe you?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.familyfriends}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    familyfriends: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* Do you consider yourself as an introvert or extrovert? */}
            <Row style={{ margin: "2%" }}>
              <InputLabel id="introextro">Introvert or Extrovert?</InputLabel>
              <Select
                className={"form-control"}
                labelId="introextro"
                label="Do you consider yourself as an introvert or extrovert?"
                value={showEntry.introvertextrovert}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    introvertextrovert: e.target.value,
                  });
                }}
              >
                <MenuItem value="Introvert">Introvert</MenuItem>
                <MenuItem value="Extrovert">Extrovert</MenuItem>
              </Select>
            </Row>

            {/* What is the one thing that people misunderstand about you? */}
            <Row style={{ margin: "2%" }}>
              <TextField
                multiline
                rows={4}
                label="One thing that people misunderstand about you?"
                placeholder="What is the one thing that people misunderstand about you?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.misunderstood}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    misunderstood: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* Who is the one person you can talk to about anything?*/}
            <Row style={{ margin: "2%" }}>
              <TextField
                label="One person you can talk to about anything?"
                placeholder="Who is the one person you can talk to about anything?"
                className={"form-control"}
                variant="outlined"
                value={showEntry.oneperson}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    oneperson: e.target.value,
                  });
                }}
              ></TextField>
            </Row>

            {/* Are you confrontational? */}
            <Row style={{ margin: "2%" }}>
              {/*<TextField*/}
              {/*  multiline*/}
              {/*  rows={4}*/}
              {/*  label="Are you confrontational?"*/}
              {/*  placeholder="Are you confrontational?"*/}
              {/*  className={"form-control"}*/}
              {/*  variant="outlined"*/}
              {/*  value={showEntry.confrontational}*/}
              {/*  onChange={(e) => {*/}
              {/*    setShowEntry({*/}
              {/*      ...showEntry,*/}
              {/*      confrontational: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*></TextField>*/}

              <InputLabel id="conf">Are you confrontational?</InputLabel>
              <Select
                className={"form-control"}
                labelId="conf"
                label="Are you confrontational?"
                value={showEntry.confrontational}
                onChange={(e) => {
                  setShowEntry({
                    ...showEntry,
                    confrontational: e.target.value,
                  });
                }}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Upload a pic of yourself</Form.Label>
                  <Form.Control
                    className={"form-control"}
                    type="file"
                    onChange={handleImage}
                    accept="image/*"
                  />
                </Form.Group>
              </Col>
              <Col>
                <img
                  style={{ padding: "20px" }}
                  src={showPic}
                  className="img-fluid rounded mx-auto d-block"
                  alt=""
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    Upload a 30 sec video introducing yourself
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleVideo}
                    accept="video/*"
                  />
                </Form.Group>
              </Col>
              <Col>
                <video
                  style={{ padding: "20px" }}
                  src={showVid}
                  className="img-fluid rounded mx-auto d-block"
                  alt=""
                  controls
                />
              </Col>
            </Row>

            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                startIcon={<Delete />}
                style={{ margin: "2%" }}
                variant="outlined"
                color="error"
                size="large"
                onClick={handleDelete}
              >
                Delete
              </Button>
              <Button
                style={{ margin: "2%" }}
                variant="contained"
                value="Submit"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Form>
        </fieldset>
      </div>
    </LoadingOverlay>
  );
}
