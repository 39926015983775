import React from "react";

const showTermsAndConditions = () => {
  return (
    <div>
      <h1>Open Ended - Our Kind of Love</h1>
      <h2>Entry Terms & Conditions</h2>
      <p>
        These are the terms and conditions governing the entry phase for the
        show Open Ended. These rules govern the relationship between you and
        Brightfire Productions when you apply to enter. Successful participants
        will be moved through to the auditions phase where you will meet a team
        of producers.
      </p>
      <h3>1. Term</h3>
      <p>The Auditions comprise two distinct phases:</p>
      <ul>
        <li>Email entry submission phase; and</li>
        <li>Shortlist in person auditions phase</li>
      </ul>
      <h3>2. Eligibility Criteria</h3>
      <p>To apply to Audition, all</p>
      <ul>
        <li>Be between the ages of 21 and 35 years old</li>
        <li>Have no criminal record</li>
        <li>Be a legal resident of South Africa;</li>
        <li>
          Be looking for an open relationship or willing to be part of one
        </li>
      </ul>
      <h3>3. Audition Application Mechanic</h3>
      <p>Applications to Audition for the show shall be as follows:</p>
      <ul>
        <li>
          You must apply to enter through the Brightfire Pictures application
          portal
        </li>
        <li>
          Brightfire Pictures production team will send all parties expressing
          interest an official Audition entry form, by which you will tell us a
          little bit more about yourself. The entry form must be completed and
          submitted along with a 3-minute video of you
        </li>
        <li>
          At its discretion Brightfire Pictures will select a shortlist of
          contributors; Criteria for selection will be a subjective opinion
          linked to who best fits the editorial of the show. This will be
          determined by Brightfire Pictures and the broadcaster. Over and above
          this, the selected contributroes will also need to be cleared of
          background checks and overall health check.
        </li>
        <li>
          Shortlisted contributores will be invited to attend an in person
          audition (date/time to be advised by Brightfire Pictures);
        </li>
        <li>
          Successful contributors will ultimately be selected to appear in the
          Show.
        </li>
      </ul>
      <h3>4. Confidentiality</h3>
      <p>
        If you are selected to participate in the audition phase of the show,
        you will not disclose to anyone at all without the prior written consent
        of Brightfire Pictures, that you have been shortlisted to audition. Only
        Brightfire Pictures has the right to disclose this information. For the
        avoidance of doubt, you will not issue any press releases or make any
        public announcements (including but not limited to any social media
        platform or any blogs) relating to any details of the Show or the
        Auditions (including any details of other finalists). Given the nature
        of the Show, Brightfire Pictures may require you to keep strictly
        confidential any information pertaining to the Show unless it consents
        otherwise.
      </p>
      <h3>5 .Disqualification</h3>
      <p>
        Brightfire Pictures reserves the right to disqualify any participant
        from the Auditions or the Show.
      </p>
      <h3>6. Changes</h3>
      <p>
        Brightfire Pictures reserves the right to change any of the audition
        dates, times, or locations, any of the Eligibility Criteria, these terms
        and conditions, or any other element pertaining to the Auditions or the
        Show at any time without notice. Brightfire Pictures will not be liable
        for any such changes or for any errors in any materials or information
        associated with the Auditions or the Show.
      </p>
      <h3>7. Personal Information</h3>
      <p>
        Any personal information collected during the application phase,
        including the information in any entry form, will be used solely in
        connection with the Show and will not be disclosed except to the extent
        strictly necessary for the Show. Such disclosure may include disclosure
        to Brightfire Pictures affiliates and/or third parties.
      </p>
      <h3>8. No Liability</h3>
      <p>
        Brightfire Pictures, its affiliates, directors, employees, agents, and
        any sponsors of the Show disclaim all liability to the greatest extent
        permissible at law, for any harm, damage, claim, or loss incurred by any
        entrant, or concerning their property, caused, or suffered in connection
        with the Show, regardless of the cause thereof. Nothing in these terms
        and conditions shall restrict Brightfire Pictures from doing anything
        necessary to ensure that we (and/or our affiliates) comply with our
        legal or regulatory obligations and we may cancel or amend the Auditions
        (in whole or any part thereof), or production of the Show if to not do
        so would place us in breach of applicable law, health and safety
        regulations, guidelines or processes, or, in our reasonable opinion,
        impair the reputation of Brightfire Pictures, or any of their
        affiliates.
      </p>
      <h3>9. General</h3>
      <p>
        These terms and conditions shall be governed by the laws of South
        Africa. All Auditions entrants consent to the non-exclusive jurisdiction
        of the High Courts of South Africa (Gauteng Provincial Division,
        Pretoria / Gauteng Local Division, Johannesburg), in respect of all
        matters arising out of or in connection with the Auditions, the Show, or
        these terms and condition.
      </p>
      <p>
        If any provision of these terms and conditions is found to be invalid or
        unenforceable by any court of competent jurisdiction, then that
        provision shall be severed from these terms and conditions and shall not
        affect the validity or enforceability of any remaining provisions.
      </p>
      <p>
        It is not intended that any provision of these terms and conditions
        contravenes any provision of the Consumer Protection Act, 68 of 2008,
        and therefore all provisions of these terms and conditions must be
        treated as being qualified, if necessary, to ensure that the provisions
        of the Consumer Protection Act are complied with.
      </p>

      <p>
        Brightfire Pictures reserves the right to update these terms and
        conditions from time to time. These terms were last updated on 2nd of
        January 2023.
      </p>
    </div>
  );
};

export default showTermsAndConditions;
